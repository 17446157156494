import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { VisitColumns } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { visitList, visitMyList } from '../../../services/api';
import { Card, Link, Table } from '../../globals';

export default function VisitList({ visitFilter }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: visitFilter?.userId ?? user.i,
    isMyData: visitFilter?.isMyData ?? true,
    userRole: visitFilter?.userRole ?? user.u,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let request = filter.isMyData ? visitMyList : visitList;

    await request(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'with-header', 'header-information', 'header-colored', 'full-width', 'visit']}
      information={t('VisitInformation')}
      title={t('Visits')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      <Table ready={pageState.list} response={response} filter={filter} setFilter={setFilter} columns={VisitColumns({})} />
    </Card>
  );
}
