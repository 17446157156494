import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { TiFlowChildren } from 'react-icons/ti';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryTypes, DeliveryFlowColumns, DeliveryFlowInputs } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { deliveryFlowCreate, deliveryFlowList, deliveryFlowMultipleDeleteRestore, deliveryFlowUpdate, deliveryFlowUpdateActivity } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { updateResponse } from '../../utilies/stateManagement';

export default function DeliveryFlows() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [createFormRef] = Form.useForm();
  const [countryFormRef] = Form.useForm();

  const isAllCountry = user.checkAction(ActionType.DeliveryFlowAllCountry);

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisiblity, setPanelVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await deliveryFlowList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    setIsUpdate(false);
    setPanelVisibility(true);
    createFormRef.resetFields();
    createFormRef.setFieldsValue({ country: filter.country, isActive: true });
  };

  const onUpdateClick = (row) => {
    setIsUpdate(true);
    createFormRef.setFieldsValue(row);
    setPanelVisibility(true);
  };

  const onStepUpdateClick = (row) => {
    setPageState((x) => ({ ...x, update: false }));

    if (isAllCountry) {
      navigate(`/delivery/flow/update/${row.country}/${row.id}`);
    } else {
      navigate(`/delivery/flow/update/${row.id}`);
    }
  };

  const onActivityChange = async (val, row) => {
    setPageState((x) => ({ ...x, update: false }));
    setResponse(updateResponse(response, 'isActive', val, true, row));

    await deliveryFlowUpdateActivity({ id: row.id, isActive: val, country: row.country }, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setPageState((x) => ({ ...x, update: true }));
      setResponse(updateResponse(response, 'isActive', val, false, row));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await deliveryFlowMultipleDeleteRestore({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onCreateFormFinish = async (model) => {
    setPageState((x) => ({ ...x, create: false }));

    let request = model.id === undefined ? deliveryFlowCreate : deliveryFlowUpdate;

    await request(model, (status) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));

        if (filter.country !== model.country) {
          setFilter((x) => ({ ...x, country: model.country }));
          countryFormRef.setFieldsValue({ country: model.country });
        } else {
          list();
        }

        setPanelVisibility(false);
      }
      setPageState((x) => ({ ...x, create: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('DeliveryFlows')}
      icon={<TiFlowChildren />}
      subTitle={
        user.checkAction(ActionType.DeliveryFlowCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('DeliveryFlows'),
          breadcrumbName: t('DeliveryFlows'),
          icon: <TiFlowChildren />,
        },
      ]}
      templates={[isAllCountry ? 'default-1-filter-4' : 'default-0-filter-4']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          activityFilter={true}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.DeliveryFlowDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={DeliveryFlowColumns({
          isAllCountry,
          onUpdateClick: user.checkAction(ActionType.DeliveryFlowUpdate) && onUpdateClick,
          onStepUpdateClick: user.checkAction(ActionType.DeliveryFlowUpdate) && onStepUpdateClick,
          onActivityChange: user.checkAction(ActionType.DeliveryFlowUpdate) && onActivityChange,
        })}
      />

      <Drawer visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
        <Element
          key="user-status-form"
          ready={pageState.create}
          formRef={createFormRef}
          onFinish={onCreateFormFinish}
          inputs={DeliveryFlowInputs({ country: filter.country, isAllCountry, isUpdate })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
