import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function EvrysdiInputs({ onFieldChange, onDailyDoseMg, onDailyDoseMl }) {
  const { t } = useTranslation();

  return [
    {
      type: 'number',
      name: 'dailyDoseMg',
      label: t('DailyDoseMg'),
      placeholder: t('DailyDoseMgEmpty'),
      rules: [{ required: true, message: t('DailyDoseMgEmpty') }],
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 5 },
      onChange: onDailyDoseMg,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'dailyDoseMl',
      label: t('DailyDoseMl'),
      placeholder: t('DailyDoseMlEmpty'),
      rules: [{ required: true, message: t('DailyDoseMlEmpty') }],
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 6.6 },
      onChange: onDailyDoseMl,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'freeBottle',
      label: t('FreeBottle'),
      placeholder: t('FreeBottleEmpty'),
      rules: [{ required: true, message: t('FreeBottleEmpty') }],
      onChange: onFieldChange,
    },
    {
      type: 'number',
      name: 'paidBottle',
      label: t('PaidBottle'),
      placeholder: t('PaidBottleEmpty'),
      rules: [{ required: true, message: t('PaidBottleEmpty') }],
      onChange: onFieldChange,
    },
    {
      type: 'number',
      name: 'totalBottle',
      label: t('TotalBottle'),
      placeholder: t('TotalBottleEmpty'),
      rules: [{ required: true, message: t('TotalBottleEmpty') }],
      disabled: true,
    },
    {
      type: 'input',
      name: 'note',
      label: t('Note'),
      placeholder: t('NoteOptional'),
      rules: [{ min: 3, max: 500, message: format(t('LengthValid'), 1, 500) }],
    },
    {
      type: 'input',
      name: 'targetUserAddress',
      label: t('Address'),
      placeholder: t('AddressEmpty'),
      rules: [
        { required: true, message: t('AddressEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 1, 500) },
      ],
    },
  ];
}
