const NoticeTypes = [
  {
    value: 0,
    name: 'AskForHelpSupplyProblem',
    displayName: 'AskForHelpSupplyProblem',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      group: 0,
      template: 2
    }
  },
  {
    value: 1,
    name: 'AskForHelpQualityProblem',
    displayName: 'AskForHelpQualityProblem',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      group: 0,
      template: 2
    }
  },
  {
    value: 2,
    name: 'AskForHelpTechnicalProblem',
    displayName: 'AskForHelpTechnicalProblem',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      group: 0,
      template: 2
    }
  },
  {
    value: 3,
    name: 'AskForHelpMedicalInformation',
    displayName: 'AskForHelpMedicalInformation',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      group: 0,
      template: 2
    }
  },
  {
    value: 4,
    name: 'AdverseEvent',
    displayName: 'AdverseEvent',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      group: 1,
      template: 3
    }
  },
  {
    value: 5,
    name: 'MissedDose',
    displayName: 'MissedDose',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      group: 2,
      template: 4
    }
  }
];

export default NoticeTypes;