const PatientActivityTypes = [
  {
    value: 0,
    name: 'TreatmentStarted',
    displayName: 'TreatmentStarted',
    group: 'TreatmentActivity',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'DeliveryCompleted',
    displayName: 'DeliveryCompleted',
    group: 'DeliveryActivity',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'VasScoreEntered',
    displayName: 'VasScoreEntered',
    group: 'VasScoreActivity',
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  }
];

export default PatientActivityTypes;