import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHospitalUser, FaPlus } from 'react-icons/fa';
import { Button, Drawer, Element, Page, Table } from '../../components/globals';
import { ActionType } from '../../constants';
import VisitColumns from '../../constants/columns/VisitColumns';
import VisitInputs from '../../constants/inputs/VisitInputs';
import { useCacheUser } from '../../hooks/useCacheUser';
import { visitCreate, visitDelete, visitMyList, visitUpdate } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';

export default function Visits() {
  // Definitions
  const { t } = useTranslation();
  const [visitFormRef] = Form.useForm();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [isCreate, setIsCreate] = useState(null);
  const [panelVisiblity, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.i,
    isMyData: true,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await visitMyList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onUpdateClick = (row) => {
    row.date = moment(row.date);
    visitFormRef.setFieldsValue(row);
    setIsCreate(false);
    setPanelVisibility(true);
  };

  const onCreateClick = () => {
    setIsCreate(true);
    visitFormRef.resetFields();
    setPanelVisibility(true);
  };

  const onDeleteClick = async (row) => {
    setPageState((x) => ({ ...x, delete: false }));

    await visitDelete({ id: row.id, userId: user.i }, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('DeleteCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onVisitFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let request = isCreate ? visitCreate : visitUpdate;

    await request(model, (status) => {
      if (status) {
        list();
        setPanelVisibility(false);
        notifySuccess(t(isCreate ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
      }
      setPageState((x) => ({ ...x, form: true }));
    });
  };

  return (
    <Page
      title={t('Visits')}
      icon={<FaHospitalUser />}
      subTitle={
        user.checkAction(ActionType.VisitCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Visits'),
          breadcrumbName: t('Visits'),
          icon: <FaHospitalUser />,
        },
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={VisitColumns({
          onUpdateClick: user.checkAction(ActionType.VisitUpdate) && onUpdateClick,
          onDeleteClick: user.checkAction(ActionType.VisitDelete) && onDeleteClick,
        })}
      />

      <Drawer visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
        <Element
          key="visit-form"
          ready={pageState.form}
          formRef={visitFormRef}
          onFinish={onVisitFormFinish}
          inputs={VisitInputs()}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
