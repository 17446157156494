const DeliveryCalculateTypes = [
  {
    value: 0,
    name: 'Automatically',
    displayName: 'DeliveryCalculateAutomatically',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Manuel',
    displayName: 'DeliveryCalculateManuel',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  }
];

export default DeliveryCalculateTypes;