import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaUsersCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { format } from 'react-string-format';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import RoleActionUpdate from '../../components/pages/role/RoleActionUpdate';
import RoleDeliveryDrugUpdate from '../../components/pages/role/RoleDeliveryDrugUpdate';
import RoleDrugUpdate from '../../components/pages/role/RoleDrugUpdate';
import RoleFieldUpdate from '../../components/pages/role/RoleFieldUpdate';
import { ActionType, CountryTypes, RoleColumns, RoleInputs } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { roleCreate, roleList, roleLogout, roleMultipleDeleteRestore, roleUpdateActivity } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { updateResponse } from '../../utilies/stateManagement';

export default function Roles() {
  // Definitions
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [roleFormRef] = Form.useForm();
  const [countryFormRef] = Form.useForm();
  const [user] = useCacheUser();

  const isAllCountry = user.checkAction(ActionType.RoleAllCountry);

  const [response, setResponse] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [drugPanelVisibility, setDrugPanelVisibility] = useState(false);
  const [deliveryDrugPanelVisibility, setDeliveryDrugPanelVisibility] = useState(false);
  const [actionPanelVisibility, setActionPanelVisibility] = useState(false);
  const [fieldPanelVisibility, setFieldPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await roleList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    roleFormRef.resetFields();
    setPanelVisibility(true);
  };

  const onDrugUpdateClick = (row) => {
    setRowData(row);
    setDrugPanelVisibility(true);
  };

  const onDeliveryDrugUpdateClick = (row) => {
    setRowData(row);
    setDeliveryDrugPanelVisibility(true);
  };

  const onFieldUpdateClick = (row) => {
    setRowData(row);
    setFieldPanelVisibility(true);
  };

  const onActionUpdateClick = (row) => {
    setRowData(row);
    setActionPanelVisibility(true);
  };

  const onLogoutUserClick = async (row) => {
    await roleLogout({ country: filter.country, roleId: row.id }, (status, res) => {
      if (status && res?.data) {
        notifySuccess(t('UpdateCompleteSuccess'));
        setTimeout(() => navigate(window.location.pathname), 500);
      }
    });
  };

  const onActivityChange = async (val, row) => {
    setPageState((x) => ({ ...x, update: false }));
    setResponse(updateResponse(response, 'isActive', val, true, row));

    await roleUpdateActivity({ id: row.id, isActive: val, country: row.country }, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setResponse(updateResponse(response, 'isActive', val, false, row));
      setPageState((x) => ({ ...x, update: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await roleMultipleDeleteRestore({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();
        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onRoleFormFinish = async (model) => {
    setPageState((x) => ({ ...x, create: false }));

    await roleCreate(model, (status, res) => {
      if (status) {
        setPanelVisibility(false);
        notifySuccess(t('CreateCompleteSuccess'));

        if (filter.country !== model.country) {
          setFilter((x) => ({ ...x, country: model.country }));
          countryFormRef.setFieldsValue({ country: model.country });
        } else {
          list();
        }
      }
      setPageState((x) => ({ ...x, create: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('Roles')}
      icon={<FaUsersCog />}
      subTitle={
        user.checkAction(ActionType.RoleCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Roles'),
          breadcrumbName: t('Roles'),
          icon: <FaUsersCog />,
        },
      ]}
      templates={[isAllCountry ? 'default-1-filter-4' : 'default-0-filter-4']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            allowClear={false}
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
          />
        ),
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          activityFilter={true}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.RoleDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={user.checkAction(ActionType.RoleDelete) && selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={RoleColumns({
          isAllCountry,
          onActivityChange: user.checkAction(ActionType.RoleUpdate) && onActivityChange,
          onDrugUpdateClick: user.checkAction(ActionType.RoleUpdate) && onDrugUpdateClick,
          onDeliveryDrugUpdateClick: user.checkAction(ActionType.RoleUpdate) && onDeliveryDrugUpdateClick,
          onFieldUpdateClick: user.checkAction(ActionType.RoleUpdate) && onFieldUpdateClick,
          onActionUpdateClick: user.checkAction(ActionType.RoleUpdate) && onActionUpdateClick,
          onLogoutUserClick: user.checkAction(ActionType.RoleUpdate) && onLogoutUserClick,
        })}
      />

      <Drawer title={t('AddNew')} visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
        <Element
          key="role-form"
          ready={pageState.create}
          formRef={roleFormRef}
          onFinish={onRoleFormFinish}
          inputs={RoleInputs({ country: filter.country, isAllCountry, isUpdate: false })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer title={t('RoleDrugs')} visible={drugPanelVisibility} onClose={() => setDrugPanelVisibility(false)}>
        {rowData && drugPanelVisibility && <RoleDrugUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setDrugPanelVisibility(false)} />}
      </Drawer>

      <Drawer title={t('RoleDeliveryDrugs')} visible={deliveryDrugPanelVisibility} onClose={() => setDeliveryDrugPanelVisibility(false)}>
        {rowData && deliveryDrugPanelVisibility && (
          <RoleDeliveryDrugUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setDeliveryDrugPanelVisibility(false)} />
        )}
      </Drawer>

      <Drawer width="80%" title={t('RoleFields')} visible={fieldPanelVisibility} onClose={() => setFieldPanelVisibility(false)}>
        {rowData && fieldPanelVisibility && <RoleFieldUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setFieldPanelVisibility(false)} />}
      </Drawer>

      <Drawer width="80%" title={t('RoleActions')} visible={actionPanelVisibility} onClose={() => setActionPanelVisibility(false)}>
        {rowData && actionPanelVisibility && <RoleActionUpdate country={filter.country} roleId={rowData.id} onConfirm={() => setActionPanelVisibility(false)} />}
      </Drawer>
    </Page>
  );
}
