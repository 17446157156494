import { css } from 'styled-components';
import colors from '../constants/colors';

export const notification = css`
  .ant-notification {
    z-index: 99999999;
  }
  .ant-notification-notice {
    border-radius: 10px !important;

    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        margin-bottom: 0px;
      }
    }

    .ant-notification-notice-close {
      width: 30px;
      height: 30px;
      top: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 13px;
      border-radius: 5px;

      &:hover {
        background-color: ${colors.greyLightest};
      }
    }
  }
`;
