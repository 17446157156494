import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHandHoldingMedical, FaPlus } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { convertMgToMl } from '../../calculators/doseCalculator';
import { enspryngCalculator } from '../../calculators/enspryngCalculator';
import { Button, Card, Element, Page } from '../../components/globals';
import TreatmentDrug from '../../components/pages/treatment/TreatmentDrug';
import TreatmentUsage from '../../components/pages/treatment/TreatmentUsage';
import { CountryTypes, DrugType, DrugTypes, RoleFieldType, TreatmentDrugDefaultInputs, TreatmentUsageStatusType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { treatmentCreate, treatmentDetail, treatmentMyDetail, treatmentUpdate, userDetail, userMyDetail } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function TreatmentCreateUpdate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userRoleType, countryType, userId, id } = useParams();
  const [user] = useCacheUser();

  const isCreate = id === undefined;

  const [detailFormRef] = Form.useForm();
  const [usageFormRef] = Form.useForm();
  const [drugFormRef] = Form.useForm();

  const [formData, setFormData] = useState(null);

  const [usageVisibility, setUsageVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    load: true,
    detail: true,
    form: true,
  });

  const [filter] = useState({
    id,
    isDeleted: false,
    includeUsages: true,
    userId: userId ?? user.i,
    country: parseInt(countryType ?? user.c),
    userRole: parseInt(userRoleType ?? user.u),
    isMyData: userRoleType === undefined,
  });

  const [userFilter] = useState({
    id: userId,
    includeFields: true,
    includeCity: true,
    country: parseInt(countryType ?? user.c),
    userRole: parseInt(userRoleType ?? user.u),
    isMyData: userId === undefined,
  });

  // Hooks
  useEffect(() => {
    load();
  }, [id]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, load: false }));

    if (!user.d) {
      notifyError(t('RoleDrugNotFoundException'));
      navigate(`/treatment/list`);
      return;
    }

    let request = userFilter.isMyData ? userMyDetail : userDetail;

    await request(userFilter, (status, res) => {
      if (status) {
        // Months
        let months = 0;
        let birthDate = res.data.fields.find((x) => x.fieldType === RoleFieldType.BirthDate);
        if (birthDate) {
          months = moment().diff(moment(birthDate.value), 'months', true);
        }

        // User Drugs
        let drugs = user.d.map((x) => DrugTypes.find((f) => f.value === x));
        setFormData((x) => ({ ...x, drugs, user: { ...res.data, months } }));

        if (id) detail();
      }

      setPageState((x) => ({ ...x, load: true }));
    });
  };

  const detail = async () => {
    setPageState((x) => ({ ...x, detail: false }));
    let request = filter.isMyData ? treatmentMyDetail : treatmentDetail;

    await request(filter, (status, res) => {
      if (status) {
        res.data.startDate = moment(res.data.startDate).add(user.t, 'minutes');
        detailFormRef.setFieldsValue(res.data);

        let previousUsages = res.data.usages.filter((x) => x.status !== TreatmentUsageStatusType.Pending);

        let usages = res.data.usages
          .filter((x) => x.status === TreatmentUsageStatusType.Pending)
          .map((x, i) => ({ ...x, row: i + previousUsages.length + 1, time: moment(x.time).add(user.t, 'minutes') }));

        usageFormRef.setFieldsValue({ usages });
        setUsageVisibility(true);

        res.data.drug = DrugTypes.find((x) => x.value === res.data.drug);
        res.data.country = CountryTypes.find((x) => x.value === filter.country);
        setFormData(res.data);
      }

      setPageState((x) => ({ ...x, detail: true }));
    });
  };

  // Events
  const onDrugChange = ({ value }) => {
    if (value === undefined) {
      setFormData((x) => ({ ...x, drug: null }));
      return;
    }

    let drugData = DrugTypes.find((x) => x.value === value);
    let countryData = CountryTypes.find((x) => x.value === filter.country);
    setFormData((x) => ({ ...x, country: countryData, drug: drugData }));
  };

  const onDurationChange = ({ value }) => {
    if (value === undefined) {
      setFormData((x) => ({ ...x, duration: null }));
      return;
    }

    setFormData((x) => ({ ...x, duration: value }));
  };

  const onValuesChange = () => {
    let general = detailFormRef.getFieldsValue();
    let drugForm = formData?.drug ? drugFormRef.getFieldsValue() : {};
    let usageForm = usageVisibility ? usageFormRef.getFieldsValue() : {};

    if (formData?.drug?.value === undefined) return;
    if (!general.startDate || !general.duration) return;

    let usages = [];
    switch (formData.drug.value) {
      default:
      case DrugType.Evrysdi:
        let nextDate = moment(general.startDate);
        let endDate = moment(general.startDate).add(general.duration, 'months');
        let row = usageForm?.usages?.length > 0 ? usageForm.usages[0].row : 1;

        while (nextDate < endDate) {
          usages.push({ time: moment(nextDate), row, dailyDoseMg: drugForm.dailyDoseMg, dailyDoseMl: drugForm.dailyDoseMl });
          nextDate.add(1, 'days');
          row++;
        }

        usageFormRef.setFieldsValue({ usages });
        setUsageVisibility(true);
        break;

      case DrugType.Enspryng:
        let result = enspryngCalculator(drugForm.status, general.startDate, general.duration, drugForm.stage, drugForm.lastCount, drugForm.lastStatus);
        if (result === null) return;

        usages = result.usages.map((x, i) => ({ time: x, row: i, dailyDoseMg: 120, dailyDoseMl: convertMgToMl(120) }));

        usageFormRef.setFieldsValue({ usages });
        setUsageVisibility(true);
        break;
    }
  };

  const onFinish = () => {
    Promise.allSettled([detailFormRef.validateFields(), usageFormRef.validateFields(), drugFormRef.validateFields()]).then(([detailRef, usageRef, drugFormRef]) => {
      if (detailRef.status === usageRef.status && detailRef.status === drugFormRef.status && usageRef.status === 'fulfilled') {
        let data = {
          id,
          usages: usageRef.value.usages,
          ...detailRef.value,
          ...drugFormRef.value,
          userId: filter.userId,
          country: filter.country,
          userRole: filter.userRole,
        };

        let request = isCreate ? treatmentCreate : treatmentUpdate;

        request(data, (status, res) => {
          if (status) {
            notifySuccess(t(isCreate ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
            navigate(`/treatment/list`);
          }
        });
      }
    });
  };

  return (
    <Page
      parent={t('Treatments')}
      title={t(isCreate ? 'AddNew' : 'Update')}
      icon={isCreate ? <FaPlus /> : <RiEdit2Fill />}
      routes={[
        {
          path: `/treatment/list`,
          name: t(`Treatments`),
          breadcrumbName: `Treatments`,
          icon: <FaHandHoldingMedical />,
        },
        {
          name: t(isCreate ? 'AddNew' : 'Update'),
          breadcrumbName: `TreatmentCreateUpdate`,
          icon: isCreate ? <FaPlus /> : <RiEdit2Fill />,
        },
      ]}
      extra={[
        <Button key="confirm" onClick={onFinish} templates={['colored']}>
          {t('Confirm')}
        </Button>,
      ]}
    >
      <Card ready={pageState.load} templates={['page']}>
        <Container>
          <Detail>
            <Element
              key="treatment-form"
              formRef={detailFormRef}
              inputs={TreatmentDrugDefaultInputs({ drugs: formData?.drugs, onDrugChange, onDurationChange })}
              onValuesChange={onValuesChange}
              columnSize={12}
            />

            {formData?.drug !== undefined && <TreatmentDrug formRef={drugFormRef} data={formData} onValuesChange={onValuesChange} />}
          </Detail>

          {formData?.drug !== undefined && usageVisibility && usageFormRef ? (
            <TreatmentUsage formRef={usageFormRef} data={formData} />
          ) : (
            <UsageMessage>{t('PleaseEnterTreatmentDetails')}</UsageMessage>
          )}
        </Container>
      </Card>
    </Page>
  );
}

const Container = styled.div`
  display: flex;
`;

const Detail = styled.div`
  width: 50%;
  margin-right: 12px;
`;

const UsageMessage = styled.div`
  width: 50%;
  text-align: center;
`;
