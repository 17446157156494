const DeliveryActionType = {
  RedirectDelivery: 0,
  ConfirmDelivery: 1,
  CompleteDelivery: 2,
  EnterDeliveryDate: 3,
  EnterVisitDate: 4,
  EnterPatientVitals: 5,
  ReceivePayment: 6,
  ApprovePayment: 7,
  RussianCargoService: 8
};

export default DeliveryActionType;