import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { DeliveryActionTypes, UserRoleTypes } from '../../constants';
import { groupByList } from '../../utilies/expression';

export default function DeliveryFlowStepInputs({ isUpdate, steps }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'deliveryFlowId',
    },
    {
      type: 'hidden',
      name: 'parentId',
    },
    {
      type: 'hidden',
      name: 'country',
    },
    {
      type: 'select',
      name: 'userRole',
      label: t('UserRole'),
      placeholder: t('SelectUserRole'),
      rules: [{ required: true, message: t('RoleEmpty') }],
      data: UserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      disabled: isUpdate,
    },
    {
      type: 'select',
      name: 'action',
      label: t('DeliveryFlowActionType'),
      placeholder: t('SelectDeliveryFlowActionType'),
      rules: [{ required: true, message: t('SelectDeliveryFlowActionTypeEmpty') }],
      groups: groupByList(DeliveryActionTypes, 'group').map((x) => ({ ...x, values: x.values.map((v) => ({ ...v, text: v.displayName })) })),
      listHeight: 512,
      disabled: isUpdate,
    },
    {
      type: 'input',
      name: 'nameKey',
      label: t('NameKey'),
      placeholder: t('NameKeyEmpty'),
      rules: [
        { required: true, message: t('NameKeyEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
    },
    {
      type: 'input',
      name: 'descriptionKey',
      label: t('DescriptionKey'),
      placeholder: t('DescriptionKeyEmpty'),
      rules: [
        { required: true, message: t('DescriptionKeyEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 1, 500) },
      ],
    },
    steps && {
      type: 'tree-select',
      name: 'redirectId',
      label: t('RedirectStep'),
      placeholder: t('SelectRedirectStep'),
      data: steps,
    },
  ];
}
