import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { RoleFieldColumns, RoleFieldGroupType, RoleFieldInputs, RoleFieldTypes } from '../../../constants';
import {
  roleFieldCreate,
  roleFieldList,
  roleFieldMultipleDeleteRestore,
  roleFieldUpdate,
  roleFieldUpdateActivity,
  roleFieldUpdateRequirement,
} from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { updateResponse } from '../../../utilies/stateManagement';
import { Button, Drawer, Element, Extra, Section, Table } from '../../globals';

export default function RoleFieldUpdate({ country, roleId }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [fieldType, setFieldType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ data: true, sync: undefined });

  const [inputs, setInputs] = useState(RoleFieldInputs());

  const [filter, setFilter] = useState({
    roleId,
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['row|asc'],
    country: country,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    await roleFieldList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });
  };

  const updateInputs = (data) => {
    setInputs(
      inputs.map((x) => {
        x.disabled = data.typeDisabled ? x.name === 'type' : false;
        x.onChange = x.name === 'type' ? onFieldTypeChange : null;

        if (data.typeValue !== undefined && x.name.includes('Value')) {
          x.visible = RoleFieldTypes.some((f) => f.value === data.typeValue && (f.data.group === RoleFieldGroupType.Input || f.data.group === RoleFieldGroupType.Number));
        } else {
          x.visible = true;
        }

        return x;
      })
    );
  };

  // Events
  const onFieldTypeChange = ({ value }) => {
    let result = RoleFieldTypes.find((x) => x.value === value);
    setFieldType(result);
    updateInputs({ typeValue: value });
  };

  const onCreateClick = () => {
    updateInputs({ typeDisabled: false });
    setPanelVisibility(true);
    setFieldType(null);
    formRef.resetFields();
    formRef.setFieldsValue({ roleId });
  };

  const onUpdateClick = (row) => {
    updateInputs({ typeDisabled: true, typeValue: row.type });
    setPanelVisibility(true);
    formRef.setFieldsValue(row);
  };

  const onRequirementChange = async (val, row) => {
    setPageState((x) => ({ ...x, sync: null }));
    setResponse(updateResponse(response, 'isRequired', val, true, row));

    await roleFieldUpdateRequirement({ id: row.id, isRequired: val, country }, (status, res) => {
      if (!status) val = !val;

      setResponse(updateResponse(response, 'isRequired', val, false, row));
      setPageState((x) => ({ ...x, sync: status }));
    });
  };

  const onActivityChange = async (val, row) => {
    setPageState((x) => ({ ...x, sync: null }));
    setResponse(updateResponse(response, 'isActive', val, true, row));

    await roleFieldUpdateActivity({ id: row.id, isActive: val, country }, (status, res) => {
      if (!status) val = !val;

      setResponse(updateResponse(response, 'isActive', val, false, row));
      setPageState((x) => ({ ...x, sync: status }));
    });
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    model.country = country;

    let request = model.id === undefined ? roleFieldCreate : roleFieldUpdate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setPanelVisibility(false);
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, sync: null, data: false }));

    await roleFieldMultipleDeleteRestore({ ids: selectedRowKeys, country }, (status, res) => {
      if (status) {
        list();
      }
      setPageState((x) => ({ ...x, sync: status, data: true }));
    });
  };

  // Render
  return (
    <Container>
      <Description>{t('RoleFieldDescription')}</Description>

      <Section
        headerLeft={[
          <Button key="add" ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-text-title', 'with-icon']}>
            {t('AddNew')}
          </Button>,
        ]}
        headerRight={[
          <Extra
            pageState={pageState}
            filter={filter}
            setFilter={setFilter}
            key="filter"
            activityFilter={true}
            selectedRowKeys={selectedRowKeys}
            onFilterChanged={() => setSelectedRowKeys([])}
            onDeleteClick={onDeleteClick}
          />,
        ]}
        templates={['role-fields']}
      >
        <Table
          ready={pageState.data}
          response={response}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onRowSelectChange={(values) => setSelectedRowKeys(values)}
          columns={RoleFieldColumns({ onUpdateClick, onRequirementChange, onActivityChange })}
        />

        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)} templates={['fields']}>
          {fieldType && fieldType.data.group === RoleFieldGroupType.Number && <Description>{t('RoleFieldTypeNumber')}</Description>}
          {fieldType && fieldType.data.group === RoleFieldGroupType.Input && <Description>{t('RoleFieldTypeInput')}</Description>}
          <Element ready={pageState.data} formRef={formRef} onFinish={onFinish} inputs={inputs} columnSize={24} submit={t('Confirm')} />
        </Drawer>
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
