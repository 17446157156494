import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { ImUserPlus } from 'react-icons/im';
import { format } from 'react-string-format';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryType, CountryTypes, DrugTypes, RoleFieldType, UserInviteColumns, UserInviteInputs, UserRoleType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { roleDrugListGlobal, userInviteCreate, userInviteList, userInviteMultipleDeleteRestore } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { getUserFieldData } from '../../utilies/userField';

export default function UserInvites() {
  // Definitions
  const { t } = useTranslation();

  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisiblity, setPanelVisibility] = useState(false);
  const [contactType, setContactType] = useState(0);
  const [userRoleType, setUserRoleType] = useState(null);
  const [diseases, setDiseases] = useState(null);

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    isExpired: 'null',
  });

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    update: true,
    delete: true,
  });

  const isAllCountry = user.checkAction(ActionType.UserInviteAllCountry);
  const userRoles = user.getUserRoles();

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await userInviteList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    formRef.resetFields();
    setContactType(0);
    setPanelVisibility(true);
  };

  const onContactTypeChange = ({ value }) => {
    setContactType(value);
  };

  const onUserRoleChange = async ({ value }) => {
    setUserRoleType(value);

    if (value === UserRoleType.Patient && diseases === null) {
      await roleDrugListGlobal({ country: filter.country, userRole: value }, (status, res) => {
        if (status) {
          let drugs = res.data.map((x) => DrugTypes.find((d) => d.value === x.drug));
          let result = getUserFieldData(RoleFieldType.DiseaseTypes, drugs);
          setDiseases(result);
        }
      });
    }
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await userInviteMultipleDeleteRestore({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    await userInviteCreate(model, (status) => {
      if (status) {
        setPanelVisibility(false);
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));

        if (filter.country !== model.country) {
          setFilter((x) => ({ ...x, country: model.country }));
        } else {
          list();
        }
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('UserInvites')}
      icon={<ImUserPlus />}
      subTitle={
        user.checkAction(ActionType.UserInviteCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('UserInvites'),
          breadcrumbName: t('UserInvites'),
          icon: <ImUserPlus />,
        },
      ]}
      templates={[isAllCountry ? 'default-2-filter-2' : 'default-1-filter-2']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        <Select
          key="parent-type-filter"
          data={[
            { value: 'null', text: t('All') },
            { value: true, text: t('Expired') },
            { value: false, text: t('NotExpired') },
          ]}
          onChange={(value) => setFilter((x) => ({ ...x, isExpired: value }))}
          placeholder={t('SelectExpireStatus')}
          defaultValue={filter.isExpired}
          value={filter.isExpired}
          templates={['filter']}
        />,
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.UserInviteDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={UserInviteColumns({
          isAllCountry,
        })}
      />

      <Drawer title={t('AddNew')} visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
        <Element
          key="form"
          ready={pageState.form}
          formRef={formRef}
          onFinish={onFinish}
          inputs={UserInviteInputs({
            country: filter.country,
            isAllCountry,
            language: user.l,
            userRoles,
            userRoleType,
            onUserRoleChange,
            diseases,
            contactType,
            onContactTypeChange,
          })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
