import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHandHoldingMedical, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { Button, Drawer, Element, Extra, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryTypes, TreatmentColumns, UserRoleActionType, UserRoleTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { treatmentDelete, treatmentList, treatmentMyList, treatmentSuspend, userList } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';

export default function Treatments() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const userRoles = user.getUserRoles();

  const [response, setResponse] = useState(null);
  const [users, setUsers] = useState([]);

  const [createPanelVisibility, setCreatePanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ list: true });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: userRoles.length === 0 ? user.i : null,
    userRole: userRoles[0],
    isMyData: userRoles.length === 0,
    includeUser: userRoles.length !== 0,
  });

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    userId: user.i,
    userRole: userRoles[0],
  });

  const isAllCountry = user.anyUserRoleAction(UserRoleActionType.AllCountry);

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let request = filter.isMyData ? treatmentMyList : treatmentList;

    await request(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  const getTemplates = () => {
    if (isAllCountry && userRoles.length > 0) return 'default-2-filter-2';
    if (isAllCountry || userRoles.length > 0) return 'default-1-filter-2';

    return 'default-0-filter-2';
  };

  // Events
  const onCreateClick = async () => {
    if (filter.isMyData) {
      navigate(`/treatment/create`);
      return;
    }

    await userList(userFilter, (status, res) => {
      if (status) {
        setUsers(res.data);
        setCreatePanelVisibility(true);
      }
    });
    setCreatePanelVisibility(true);
  };

  const onCreateFinish = (model) => {
    setPageState((x) => ({ ...x, create: false }));
    navigate(`/treatment/create/${filter.userRole}/${filter.country}/${model.userId}`);
  };

  const onUpdateClick = (row) => {
    if (row.userId === user.i) {
      navigate(`/treatment/update/${row.id}`);
    } else {
      navigate(`/treatment/update/${filter.userRole}/${filter.country}/${row.userId}/${row.id}`);
    }
  };

  const onSuspendClick = async (row) => {
    let model = { ...row, userId: user.i, userRole: row.user.userRole, country: row.user.country };
    await treatmentSuspend(model, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  const onDeleteClick = async (row) => {
    setPageState((x) => ({ ...x, delete: false }));

    await treatmentDelete({ id: row.id, userId: user.i, userRole: row.user.userRole, country: row.user.country }, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('DeleteCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('Treatments')}
      icon={<FaHandHoldingMedical />}
      subTitle={
        user.checkAction(ActionType.TreatmentCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Treatments'),
          breadcrumbName: t('Treatments'),
          icon: <FaHandHoldingMedical />,
        },
      ]}
      templates={getTemplates()}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            value={filter.country}
            templates={['filter']}
            allowClear={false}
          />
        ),
        userRoles.length > 0 && (
          <Select
            key="user-role-filter"
            data={UserRoleTypes.filter((x) => userRoles.some((u) => u === x.value)).map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={userRoles.length > 0 && ((userRole) => setFilter((x) => ({ ...x, userRole })))}
            placeholder={t('SelectUserRole')}
            templates={['filter']}
            defaultValue={filter.userRole}
            value={filter.userRole}
          />
        ),
        <Extra key="filter" pageState={pageState} filter={filter} setFilter={setFilter} />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={TreatmentColumns({
          isUserVisible: filter.includeUser,
          onUpdateClick: user.checkAction(ActionType.TreatmentUpdate) && onUpdateClick,
          onSuspendClick: user.checkAction(ActionType.TreatmentUpdate) && onSuspendClick,
          onDeleteClick: user.checkAction(ActionType.TreatmentDelete) && onDeleteClick,
        })}
      />

      <Drawer visible={createPanelVisibility} onClose={() => setCreatePanelVisibility(false)}>
        <Element
          key="users"
          onFinish={onCreateFinish}
          ready={pageState.create}
          inputs={[
            {
              type: 'select',
              name: 'userId',
              label: t('User'),
              placeholder: t('SelectUser'),
              rules: [{ required: true, message: t('UserEmpty') }],
              data: users.map((x) => ({ value: x.id, text: x.fullName })),
            },
          ]}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
