import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Conference from '../../../components/pages/conference/Conference';
import VasScore from '../../../components/pages/widget/VasScore';
import { ActionType, DrugType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { Card } from '../../globals';
import EnspryngCalculator from '../../globals/calculator/EnspryngCalculator';
import EvrysdiCalculator from '../../globals/calculator/EvrysdiCalculator';
import MyPatients from '../widget/MyPatients';
import ImageHeader from './ImageHeader';

export default function GlobalDashboard() {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  // Render
  return (
    <Container>
      <ImageHeader user={user} />
      {user?.checkAction(ActionType.HomeWidgetVasScore) && <VasScore />}
      {user?.checkAction(ActionType.ConferenceList) && <Conference />}
      {user?.checkAction(ActionType.HomeWidgetPatientActivities) && <MyPatients />}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  padding-top: 200px;

  > *:not(section) {
    float: left;
    margin-bottom: 24px;

    &:not(:nth-child(4n)) {
      margin-right: 16px;
    }

    @media only screen and (max-width: 1600px) {
      &:not(:nth-child(4n)) {
        margin-right: auto;
      }

      &:not(:nth-child(3n)) {
        margin-right: 16px;
      }
    }

    @media only screen and (max-width: 1200px) {
      &:not(:nth-child(3n)) {
        margin-right: auto;
      }
    }
  }
`;
