import { useTranslation } from 'react-i18next';

export default function EvrysdiInputs({ onDailyDoseMg, onDailyDoseMl }) {
  const { t } = useTranslation();

  return [
    {
      type: 'number',
      name: 'dailyDoseMg',
      label: t('DailyDoseMg'),
      placeholder: t('DailyDoseMgEmpty'),
      rules: [{ required: true, message: t('DailyDoseMgEmpty') }],
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 5 },
      onChange: onDailyDoseMg,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'dailyDoseMl',
      label: t('DailyDoseMl'),
      placeholder: t('DailyDoseMlEmpty'),
      rules: [{ required: true, message: t('DailyDoseMlEmpty') }],
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 6.6 },
      onChange: onDailyDoseMl,
      step: '0.1',
    },
  ];
}
