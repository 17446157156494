import moment from 'moment';
import { RoleFieldDiseaseTypes, RoleFieldGenderTypes, RoleFieldGroupType, RoleFieldTherapeuticAreaTypes, RoleFieldType, RoleFieldTypes } from '../constants';
import { getTranslate } from './localization';

export const getUserFieldFormData = (fields, timeOffset) => {
  let result = {};

  fields.forEach((item, i) => {
    let roleFieldType = RoleFieldTypes.find((x) => x.value === item.fieldType);

    switch (roleFieldType.data.group) {
      case RoleFieldGroupType.Date:
      case RoleFieldGroupType.DateTime:
        result[item.fieldId] = moment(item.value).add(timeOffset, 'minutes');
        break;

      case RoleFieldGroupType.Number:
      case RoleFieldGroupType.Select:
        result[item.fieldId] = parseInt(item.value);
        break;

      case RoleFieldGroupType.MultiSelect:
        result[item.fieldId] = item.value;
        break;

      default:
        result[item.fieldId] = item.value;
        break;
    }
  });

  return result;
};

export const getUserFieldData = (fieldType, drugs) => {
  switch (fieldType) {
    case RoleFieldType.GenderType:
      return RoleFieldGenderTypes.map((x) => ({ ...x, text: getTranslate(x.displayName) }));
    case RoleFieldType.DiseaseTypes:
      return RoleFieldDiseaseTypes.filter((f) => f.data.drugs.some((d) => drugs.some((e) => e.value === d))).map((x) => ({ ...x, text: getTranslate(x.displayName) }));
    case RoleFieldType.TherapeuticAreaType:
      return RoleFieldTherapeuticAreaTypes.map((x) => ({ ...x, text: getTranslate(x.displayName) }));
    default:
      return null;
  }
};

export const setFieldData = (fields) => {
  return Object.keys(fields).map((x) => ({ fieldId: x, value: fields[x] }));
};
