import { QuestionCircleOutlined } from '@ant-design/icons';
import { Rate } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight, FaSmile } from 'react-icons/fa';
import { MdSick } from 'react-icons/md';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { vasScoreCreate, vasScoreDetail, vasScoreMyDetail, vasScoreUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Button, Card, Link } from '../../globals';

export default function VasScore({ country, userRole, id, load }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();

  const points = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [response, setResponse] = useState(null);
  const [pageState, setPageState] = useState({ detail: false, form: true });
  const [isUpdate, setIsUpdate] = useState(false);

  const [filter] = useState({
    userId: id,
    isMyData: id === undefined,
    country: country,
    userRole: userRole,
  });

  // Hooks
  useEffect(() => {
    detail();
  }, []);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, detail: false }));

    let request = filter.isMyData ? vasScoreMyDetail : vasScoreDetail;

    await request(filter, (status, res) => {
      if (status) {
        setResponse(res.data);
      }

      setPageState((x) => ({ ...x, detail: true }));
    });
  };

  // Events
  const onPointChange = async (value) => {
    setPageState((x) => ({ ...x, detail: false }));

    let request = response ? vasScoreUpdate : vasScoreCreate;

    await request({ point: value }, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
        setIsUpdate(false);
        setResponse((x) => ({ ...x, point: value }));
        if (load) {
          load();
        }
      }
      setPageState((x) => ({ ...x, detail: true }));
    });
  };

  // Render
  return (
    <Card
      ready={pageState.detail}
      templates={['widget', 'vas-score', 'with-header', 'header-information', 'header-colored']}
      information={t('VasScoreInformation')}
      title={t('YourVasScore')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      {response && !isUpdate ? (
        <Container>
          <VasScorePoint point={response.point}>{response.point}</VasScorePoint>

          <VasScoreTitle>{t('YourVasScoreToday')}</VasScoreTitle>
          <VasScoreDescription>{t('ThanksForSharingYourMood')}</VasScoreDescription>

          {filter.isMyData && (
            <Button onClick={() => setIsUpdate(true)} templates={['primary']}>
              {t('Update')}
            </Button>
          )}
        </Container>
      ) : (
        <Container>
          <HowAreYouTodayIcon />
          <HowAreYouTodayTitle>{t('HowAreYouTodayTitle')}</HowAreYouTodayTitle>
          <VasScoreRate
            onChange={onPointChange}
            count={points.length}
            defaultValue={0}
            character={({ index }) => {
              switch (index) {
                case 0:
                  return <MdSick />;
                case 9:
                  return <FaSmile />;
                default:
                  return index + 1;
              }
            }}
          />
        </Container>
      )}
    </Card>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const HowAreYouTodayIcon = styled(QuestionCircleOutlined)`
  font-size: 100px;
  margin: 30px 0px;
  color: ${(x) => x.theme.colors.mediumSpringGreen};
`;

const HowAreYouTodayTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0px;
`;

const VasScoreRate = styled(Rate)`
  color: transparent;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;

  .ant-rate-star {
    margin: 0px;
    > div {
      padding: 0px 12px;
      border-radius: 10px;
      height: 60px;
      display: flex;
      align-items: center;
    }
  }

  div[aria-posinset='1'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score0};
    }
  }
  div[aria-posinset='2'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score1};
    }
  }
  div[aria-posinset='3'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score2};
    }
  }
  div[aria-posinset='4'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score3};
    }
  }
  div[aria-posinset='5'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score4};
    }
  }
  div[aria-posinset='6'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score5};
    }
  }
  div[aria-posinset='7'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score6};
    }
  }
  div[aria-posinset='8'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score7};
    }
  }
  div[aria-posinset='9'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score8};
    }
  }
  div[aria-posinset='10'] {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.score9};
    }
  }

  .ant-rate-star-full div[aria-posinset='1'] {
    background-color: ${(x) => x.theme.colors.score0};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='2'] {
    background-color: ${(x) => x.theme.colors.score1};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='3'] {
    background-color: ${(x) => x.theme.colors.score2};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='4'] {
    background-color: ${(x) => x.theme.colors.score3};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='5'] {
    background-color: ${(x) => x.theme.colors.score4};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='6'] {
    background-color: ${(x) => x.theme.colors.score5};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='7'] {
    background-color: ${(x) => x.theme.colors.score6};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='8'] {
    background-color: ${(x) => x.theme.colors.score7};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='9'] {
    background-color: ${(x) => x.theme.colors.score8};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
  .ant-rate-star-full div[aria-posinset='10'] {
    background-color: ${(x) => x.theme.colors.score9};
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: ${(x) => x.theme.colors.white};
    }
  }
`;

const VasScorePoint = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;

  font-size: 60px;
  margin: 30px 0px;
  color: ${(x) => x.theme.colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ point }) => {
    switch (point) {
      case 1:
        return css`
          background-color: ${(x) => x.theme.colors.score0};
        `;
      case 2:
        return css`
          background-color: ${(x) => x.theme.colors.score1};
        `;
      case 3:
        return css`
          background-color: ${(x) => x.theme.colors.score2};
        `;
      case 4:
        return css`
          background-color: ${(x) => x.theme.colors.score3};
        `;
      case 5:
        return css`
          background-color: ${(x) => x.theme.colors.score4};
        `;
      case 6:
        return css`
          background-color: ${(x) => x.theme.colors.score5};
        `;
      case 7:
        return css`
          background-color: ${(x) => x.theme.colors.score6};
        `;
      case 8:
        return css`
          background-color: ${(x) => x.theme.colors.score7};
        `;
      case 9:
        return css`
          background-color: ${(x) => x.theme.colors.score8};
        `;
      case 10:
        return css`
          background-color: ${(x) => x.theme.colors.score9};
        `;

      default:
        return css``;
    }
  }}
`;

const VasScoreTitle = styled.div`
  font-weight: bold;
  margin: 12px 0px;
  font-size: 20px;
`;

const VasScoreDescription = styled.div`
  margin: 12px 0px 32px 0px;
`;
