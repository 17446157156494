const TextlocalSmsTemplateTypes = [
  {
    value: 0,
    name: 'VerificationCode',
    displayName: 'TextlocalVerificationCode',
    group: null,
    shortName: '1707167049694875261',
    description: 'Your Salos verification PIN is: {Login.VerificationCode}\r\nSALOS',
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'DeliveryIMSRedirected',
    displayName: 'TextlocalDeliveryIMSRedirected',
    group: null,
    shortName: '1707166487638741722',
    description: 'Your product order {Delivery.Reference} has been received and is being processed\r\nSALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  },
  {
    value: 2,
    name: 'DeliveryRedirectedDoctor',
    displayName: 'TextlocalDeliveryRedirectedDoctor',
    group: null,
    shortName: '1707166556513041622',
    description: 'Dear {Doctor.FullName}\r\n New order {Delivery.Reference} has been created for {Patient.FullName} & order is out for delivery to you SALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  },
  {
    value: 3,
    name: 'DeliveryWillDeliveredWithPharmacist',
    displayName: 'TextlocalDeliveryWillDeliveredWithPharmacist',
    group: null,
    shortName: '1707166556516829589',
    description: 'Dear {Patient.FullName}\r\n Product order {Delivery.Reference} has been dispatched and is out for delivery to the delivery pharmacist SALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  },
  {
    value: 4,
    name: 'DeliveryWillDeliveredWithDoctor',
    displayName: 'TextlocalDeliveryWillDeliveredWithDoctor',
    group: null,
    shortName: '1707166556515355752',
    description: 'Dear {Patient.FullName} \r\n Product order {Delivery.Reference} has been dispatched and is out for delivery to your doctor SALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  },
  {
    value: 5,
    name: 'DeliveryWillDeliveredPatient',
    displayName: 'TextlocalDeliveryWillDeliveredPatient',
    group: null,
    shortName: '1707166556510774789',
    description: 'Your Patient {Patient.FullName} product order {Delivery.Reference} has been dispatched and is out for delivery to you SALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  },
  {
    value: 6,
    name: 'DeliveryWillDelivered',
    displayName: 'TextlocalDeliveryWillDelivered',
    group: null,
    shortName: '1707166556522427397',
    description: 'Dear {User.FullName}\r\n Your Product order delivery will be delivered on {Delivery.DeliveryDate}\r\n SALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  },
  {
    value: 7,
    name: 'DeliveryRejectedPharmacist',
    displayName: 'TextlocalDeliveryRejectedPharmacist',
    group: null,
    shortName: '1707166556525242932',
    description: 'Dear pharmacist\r\n Product order {Delivery.Reference} has been canceled and will be reinitiated\r\n SALOS',
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 8,
    name: 'DeliveryRejectedDoctor',
    displayName: 'TextlocalDeliveryRejectedDoctor',
    group: null,
    shortName: '1707166556524323812',
    description: 'Dear Dr\r\n Your Patient {Patient.FullName} product order {Delivery.Reference} has been canceled and will be reinitiated\r\n SALOS',
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 9,
    name: 'DeliveryRejectedPatient',
    displayName: 'TextlocalDeliveryRejectedPatient',
    group: null,
    shortName: '1707166556523359398',
    description: 'Dear {Patient.FullName}\r\n Your Order has been canceled & reinitiated. For any clarifications please feel free to contact TBT team\r\n SALOS',
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 10,
    name: 'DeliveryDoctorDelivered',
    displayName: 'TextlocalDeliveryDoctorDelivered',
    group: null,
    shortName: '1707166556527007051',
    description: 'Dear {Patient.FullName}\r\n Product order {Delivery.Reference} has been successfully delivered to your doctor\r\n SALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  },
  {
    value: 11,
    name: 'DeliveryCompleted',
    displayName: 'TextlocalDeliveryCompleted',
    group: null,
    shortName: '1707166556518507419',
    description: 'Dear {Patient.FullName}\r\n Product order {Delivery.Reference} has been successfully delivered to you \r\n SALOS',
    prompt: 'Delivery',
    order: 100,
    data: {}
  }
];

export default TextlocalSmsTemplateTypes;