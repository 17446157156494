import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryTypes, LanguageTypes, RoleFieldTherapeuticAreaTypes, UserRoleType, UserRoleTypes } from '../../constants';

export default function UserInviteInputs({ country, isAllCountry, language, userRoles, userRoleType, onUserRoleChange, diseases, contactType, onContactTypeChange }) {
  const { t } = useTranslation();

  return [
    {
      type: 'radio-group',
      name: 'contactType',
      optionType: 'button',
      label: t('InviteContactType'),
      onChange: onContactTypeChange,
      data: [
        { value: 0, label: t('Email') },
        { value: 1, label: t('Phone') },
      ],
      initialValue: 0,
      css: `.ant-radio-button-wrapper{width:100%}`,
    },
    !isAllCountry && {
      type: 'hidden',
      name: 'country',
      initialValue: country && parseInt(country),
    },
    isAllCountry && {
      type: 'select',
      name: 'country',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: country && parseInt(country),
    },
    {
      type: 'select',
      name: 'language',
      label: t('Language'),
      placeholder: t('SelectLanguageType'),
      rules: [{ required: true, message: t('LanguageTypeEmpty') }],
      data: LanguageTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: language,
    },
    userRoles && {
      type: 'select',
      name: 'userRole',
      label: t('UserRole'),
      placeholder: t('SelectUserRole'),
      rules: [{ required: true, message: t('UserRoleEmpty') }],
      data: UserRoleTypes.filter((x) => userRoles.some((u) => u === x.value)).map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onUserRoleChange,
    },
    diseases &&
      userRoleType === UserRoleType.Patient && {
        type: 'select',
        name: 'disease',
        label: t('Diseases'),
        placeholder: t('SelectDisease'),
        rules: [{ required: true, message: t('DiseaseTypesEmpty') }],
        data: diseases,
      },
    userRoleType === UserRoleType.Doctor && {
      type: 'select',
      name: 'therapeuticArea',
      label: t('TherapeuticAreaType'),
      placeholder: t('SelectTherapeuticArea'),
      rules: [{ required: true, message: t('TherapeuticAreaTypeEmpty') }],
      data: RoleFieldTherapeuticAreaTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    contactType === 0 && {
      type: 'input',
      name: 'email',
      label: t('Email'),
      placeholder: t('EmailEmpty'),
      rules: [
        { required: true, message: t('EmailEmpty') },
        {
          type: 'email',
          min: 3,
          max: 500,
          message: t('EmailInvalid'),
        },
      ],
    },
    contactType === 1 && {
      type: 'phone',
      name: 'phone',
      label: t('Phone'),
      placeholder: t('PhoneEmpty'),
      rules: [
        { required: true, message: t('PhoneEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
    },
    {
      type: 'date',
      name: 'expireTime',
      label: t('ExpireTime'),
      showTime: true,
      placeholder: t('SelectExpireTime'),
      rules: [{ required: true, message: 'ExpireTimeEmpty' }],
      disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
    },
  ];
}
