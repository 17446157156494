import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deliveryStepUpdate } from '../../../../services/api';
import { notifySuccess } from '../../../../utilies/notification';
import { Button } from '../../../globals';

export default function ConfirmDelivery({ next, country, delivery, step, complete }) {
  // Definitions
  const { t } = useTranslation();
  const [ready, setReady] = useState(true);

  const onSelect = async () => {
    let model = { ...next, currentStepId: step.id, country, deliveryId: delivery.id, targetUserId: delivery.targetUserId };

    setReady(false);
    await deliveryStepUpdate(model, (status, res) => {
      if (status) {
        complete();
        notifySuccess(t('UpdateCompleteSuccess'));
      }

      setReady(true);
    });
  };

  // Render
  return (
    step && (
      <Button ready={ready} onClick={() => onSelect()} templates={['colored']}>
        {t('ConfirmDelivery')}
      </Button>
    )
  );
}
