import { useEffect, useRef, useState } from 'react';

export const useTimer = (value) => {
  const intervalRef = useRef(null);
  const [seconds, setSeconds] = useState(value);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (intervalRef.current || isReady === false) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      return;
    }

    const interval = (intervalRef.current = setInterval(() => {
      setSeconds((x) => x - 1);
    }, 1000));

    return () => clearInterval(interval);
  }, [isReady]);

  const startTimer = () => {
    setIsReady(true);
  };

  const stopTimer = () => {
    setIsReady(false);
  };

  const resetTimer = (resetValue) => {
    setSeconds(resetValue ?? value);
  };

  return [seconds, startTimer, stopTimer, resetTimer];
};
