import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaPause, FaPlay, FaTrash } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, PopupConfirm } from '../../components/globals';
import { DrugTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function TreatmentColumns({ isUserVisible, onUpdateClick, onSuspendClick, onDeleteClick }) {
  const { t } = useTranslation();

  let columns = [
    isUserVisible && {
      locale: 'UserFullName',
      name: 'user',
      type: 'string',
      render: (value, row) => {
        return (
          <Tooltip placement="topLeft" title={row.user.fullName}>
            {row.user.fullName}
          </Tooltip>
        );
      },
    },
    {
      locale: 'Drug',
      name: 'drug',
      type: 'string',
      render: (value, row) => {
        let drug = DrugTypes.find((x) => x.value === value);
        return (
          <Tooltip placement="topLeft" title={t(drug.displayName)}>
            {t(drug.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'EndDate',
      name: 'endDate',
      type: 'date',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onSuspendClick && {
      locale: 'Suspend',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="suspend" onClick={() => onSuspendClick(row)} templates={['table']}>
          {row.suspendTime ? <FaPlay /> : <FaPause />}
        </Button>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
    onDeleteClick && {
      locale: 'Delete',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <PopupConfirm
          key="delete-confirm"
          title={t('AreYouSureWantToDelete')}
          onConfirm={() => onDeleteClick(row)}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="bottomRight"
        >
          <Button templates={['table']}>
            <FaTrash />
          </Button>
        </PopupConfirm>
      ),
    },
  ];

  return ColumnBase({ columns });
}
