import { Form } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiMedicines } from 'react-icons/gi';
import styled, { css } from 'styled-components';
import { evrysdiCalculator } from '../../../calculators/evrysdiCalculator';
import { CalculatorEvrysdiInputs } from '../../../constants';
import { Button, Element } from '../../globals';

export default function EvrysdiCalculator() {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [pageState, setPageState] = useState({ form: true });
  const [response, setResponse] = useState(null);

  // Events
  const onCalculateFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let months = moment().diff(model.birthDate, 'months', true);
    let result = evrysdiCalculator(model.weight, model.duration, months);

    setResponse(result);
    setPageState((x) => ({ ...x, form: true }));
  };

  // Render
  return (
    <Container>
      <FormContainer visible={response === null}>
        <Element key="dose-calculator-form" onFinish={onCalculateFinish} ready={pageState.form} formRef={formRef} inputs={CalculatorEvrysdiInputs()} columnSize={24} />

        <Button onClick={() => formRef.submit()} templates={['colored']} block={true}>
          {t('Calculate')}
        </Button>
      </FormContainer>

      {response && (
        <ResultContainer>
          <GiMedicines />
          <Title>{t('DailyDoseAmount')}</Title>
          <AmountResult>
            <Amount>
              {response.miligram}
              <span>mg</span>
            </Amount>
            <Divider></Divider>
            <Amount>
              {response.mililiter}
              <span>ml</span>
            </Amount>
          </AmountResult>
          <Title>{t('TotalBottleAmount')}</Title>
          <AmountResult>
            <Amount>{response.bottle}</Amount>
          </AmountResult>
          <Button onClick={() => setResponse(null)} templates={['colored', 'full-width', 'absolute-bottom']}>
            {t('CalculateAgain')}
          </Button>
        </ResultContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;

const FormContainer = styled.div`
  height: 100%;

  ${({ visible }) =>
    visible
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  flex-direction: column;
  justify-content: space-between;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  padding-bottom: 50px;

  svg {
    margin-top: 12px;
    font-size: 40px;
    color: ${(x) => x.theme.colors.mediumSpringGreen};
  }
`;

const Title = styled.div`
  font-weight: bold;
`;

const AmountResult = styled.div`
  display: flex;
  align-items: center;
`;

const Amount = styled.div`
  font-size: 50px;
  font-weight: bold;
  span {
    font-size: initial;
    font-weight: initial;
  }
`;

const Divider = styled.div`
  margin: 0px 8px;
  width: 24px;
  height: 1px;
  background-color: #000;
  transform: rotate(110deg);
  margin-top: 10px;
`;
