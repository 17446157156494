import { getTranslate } from '../../../utilies/localization';

export const DefaultEmailTemplate = {
  title: getTranslate('DefaultEmailTemplate'),
  description: getTranslate('DefaultEmailTemplateDescription'),
  content: `<table border="0" width="600" cellspacing="0" cellpadding="0" align="center" class="non-editable-area">
  <tbody>
  <tr>
  <td colspan="3" width="600" height="20">&nbsp;</td>
  </tr>
  <tr>
  <td colspan="3" width="600"><img style="display: block;" src="https://static.salos.partners/global/assets/mailing/ust.png" alt="" width="600" height="182" border="0"></td>
  </tr>
  <tr>
  <td style="background-color: #2a1a8e;" width="50">&nbsp;</td>
  <td style="background-color: #2a1a8e; font-size: 14px; font-family: Verdana, Geneva, Tahoma, sans-serif; color: #fff;" width="500" class="editable-area">
  <p>Text</p>
  </td>
  <td style="background-color: #2a1a8e;" width="50">&nbsp;</td>
  </tr>
  <tr>
  <td style="background-color: #2a1a8e; font-size: 14px; font-family: Verdana,Geneva,Tahoma,sans-serif; color: #fff;" colspan="3" align="center" width="600" height="50"><a style="color: #fff; text-align: center; text-decoration: none;" href="https://salos.partners"><strong>www.salos.partners</strong></a></td>
  </tr>
  <tr>
  <td colspan="3" width="600"><img style="display: block;" src="https://static.salos.partners/global/assets/mailing/alt.png" alt="" width="600" height="154"></td>
  </tr>
  <tr>
  <td colspan="3" width="600" height="20">&nbsp;</td>
  </tr>
  </tbody>
  </table>`,
};
