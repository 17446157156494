const ActionTypes = [
  {
    value: 0,
    name: 'RoleList',
    displayName: 'ActionTypeList',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 10,
    data: {}
  },
  {
    value: 1,
    name: 'RoleAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 20,
    data: {}
  },
  {
    value: 2,
    name: 'RoleCreate',
    displayName: 'ActionTypeCreate',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 30,
    data: {}
  },
  {
    value: 3,
    name: 'RoleUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 40,
    data: {}
  },
  {
    value: 4,
    name: 'RoleDelete',
    displayName: 'ActionTypeDelete',
    group: 'RoleManagement',
    shortName: null,
    description: null,
    prompt: 'RoleList',
    order: 50,
    data: {}
  },
  {
    value: 5,
    name: 'OwnerList',
    displayName: 'ActionTypeList',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 60,
    data: {
      userRole: 0,
      userRoleActionType: 0
    }
  },
  {
    value: 6,
    name: 'OwnerAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 70,
    data: {
      userRole: 0,
      userRoleActionType: 1
    }
  },
  {
    value: 7,
    name: 'OwnerAll',
    displayName: 'ActionTypeAll',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 80,
    data: {
      userRole: 0,
      userRoleActionType: 2
    }
  },
  {
    value: 8,
    name: 'OwnerCreate',
    displayName: 'ActionTypeCreate',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 100,
    data: {
      userRole: 0,
      userRoleActionType: 4
    }
  },
  {
    value: 9,
    name: 'OwnerUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 110,
    data: {
      userRole: 0,
      userRoleActionType: 5
    }
  },
  {
    value: 10,
    name: 'OwnerDelete',
    displayName: 'ActionTypeDelete',
    group: 'OwnerManagement',
    shortName: null,
    description: null,
    prompt: 'OwnerList',
    order: 120,
    data: {
      userRole: 0,
      userRoleActionType: 6
    }
  },
  {
    value: 11,
    name: 'AdministratorList',
    displayName: 'ActionTypeList',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 130,
    data: {
      userRole: 1,
      userRoleActionType: 0
    }
  },
  {
    value: 12,
    name: 'AdministratorAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 140,
    data: {
      userRole: 1,
      userRoleActionType: 1
    }
  },
  {
    value: 13,
    name: 'AdministratorAll',
    displayName: 'ActionTypeAll',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 150,
    data: {
      userRole: 1,
      userRoleActionType: 2
    }
  },
  {
    value: 14,
    name: 'AdministratorCreate',
    displayName: 'ActionTypeCreate',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 170,
    data: {
      userRole: 1,
      userRoleActionType: 4
    }
  },
  {
    value: 15,
    name: 'AdministratorUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 180,
    data: {
      userRole: 1,
      userRoleActionType: 5
    }
  },
  {
    value: 16,
    name: 'AdministratorDelete',
    displayName: 'ActionTypeDelete',
    group: 'AdministratorManagement',
    shortName: null,
    description: null,
    prompt: 'AdministratorList',
    order: 190,
    data: {
      userRole: 1,
      userRoleActionType: 6
    }
  },
  {
    value: 17,
    name: 'ManagerList',
    displayName: 'ActionTypeList',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      userRole: 2,
      userRoleActionType: 0
    }
  },
  {
    value: 18,
    name: 'ManagerAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 210,
    data: {
      userRole: 2,
      userRoleActionType: 1
    }
  },
  {
    value: 19,
    name: 'ManagerAll',
    displayName: 'ActionTypeAll',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 220,
    data: {
      userRole: 2,
      userRoleActionType: 2
    }
  },
  {
    value: 20,
    name: 'ManagerCreate',
    displayName: 'ActionTypeCreate',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 240,
    data: {
      userRole: 2,
      userRoleActionType: 4
    }
  },
  {
    value: 21,
    name: 'ManagerUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 250,
    data: {
      userRole: 2,
      userRoleActionType: 5
    }
  },
  {
    value: 22,
    name: 'ManagerDelete',
    displayName: 'ActionTypeDelete',
    group: 'ManagerManagement',
    shortName: null,
    description: null,
    prompt: 'ManagerList',
    order: 260,
    data: {
      userRole: 2,
      userRoleActionType: 6
    }
  },
  {
    value: 23,
    name: 'EditorList',
    displayName: 'ActionTypeList',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 270,
    data: {
      userRole: 3,
      userRoleActionType: 0
    }
  },
  {
    value: 24,
    name: 'EditorAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 280,
    data: {
      userRole: 3,
      userRoleActionType: 1
    }
  },
  {
    value: 25,
    name: 'EditorAll',
    displayName: 'ActionTypeAll',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 290,
    data: {
      userRole: 3,
      userRoleActionType: 2
    }
  },
  {
    value: 26,
    name: 'EditorCreate',
    displayName: 'ActionTypeCreate',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 310,
    data: {
      userRole: 3,
      userRoleActionType: 4
    }
  },
  {
    value: 27,
    name: 'EditorUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 320,
    data: {
      userRole: 3,
      userRoleActionType: 5
    }
  },
  {
    value: 28,
    name: 'EditorDelete',
    displayName: 'ActionTypeDelete',
    group: 'EditorManagement',
    shortName: null,
    description: null,
    prompt: 'EditorList',
    order: 330,
    data: {
      userRole: 3,
      userRoleActionType: 6
    }
  },
  {
    value: 29,
    name: 'PSPList',
    displayName: 'ActionTypeList',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 340,
    data: {
      userRole: 4,
      userRoleActionType: 0
    }
  },
  {
    value: 30,
    name: 'PSPAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 350,
    data: {
      userRole: 4,
      userRoleActionType: 1
    }
  },
  {
    value: 31,
    name: 'PSPAll',
    displayName: 'ActionTypeAll',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 360,
    data: {
      userRole: 4,
      userRoleActionType: 2
    }
  },
  {
    value: 32,
    name: 'PSPCreate',
    displayName: 'ActionTypeCreate',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 380,
    data: {
      userRole: 4,
      userRoleActionType: 4
    }
  },
  {
    value: 33,
    name: 'PSPUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 390,
    data: {
      userRole: 4,
      userRoleActionType: 5
    }
  },
  {
    value: 34,
    name: 'PSPDelete',
    displayName: 'ActionTypeDelete',
    group: 'PSPManagement',
    shortName: null,
    description: null,
    prompt: 'PSPList',
    order: 400,
    data: {
      userRole: 4,
      userRoleActionType: 6
    }
  },
  {
    value: 35,
    name: 'DoctorList',
    displayName: 'ActionTypeList',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 410,
    data: {
      userRole: 5,
      userRoleActionType: 0
    }
  },
  {
    value: 36,
    name: 'DoctorAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 420,
    data: {
      userRole: 5,
      userRoleActionType: 1
    }
  },
  {
    value: 37,
    name: 'DoctorAll',
    displayName: 'ActionTypeAll',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 430,
    data: {
      userRole: 5,
      userRoleActionType: 2
    }
  },
  {
    value: 38,
    name: 'DoctorCreate',
    displayName: 'ActionTypeCreate',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 450,
    data: {
      userRole: 5,
      userRoleActionType: 4
    }
  },
  {
    value: 39,
    name: 'DoctorUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 460,
    data: {
      userRole: 5,
      userRoleActionType: 5
    }
  },
  {
    value: 40,
    name: 'DoctorDelete',
    displayName: 'ActionTypeDelete',
    group: 'DoctorManagement',
    shortName: null,
    description: null,
    prompt: 'DoctorList',
    order: 470,
    data: {
      userRole: 5,
      userRoleActionType: 6
    }
  },
  {
    value: 41,
    name: 'NurseList',
    displayName: 'ActionTypeList',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 480,
    data: {
      userRole: 6,
      userRoleActionType: 0
    }
  },
  {
    value: 42,
    name: 'NurseAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 490,
    data: {
      userRole: 6,
      userRoleActionType: 1
    }
  },
  {
    value: 43,
    name: 'NurseAll',
    displayName: 'ActionTypeAll',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 500,
    data: {
      userRole: 6,
      userRoleActionType: 2
    }
  },
  {
    value: 44,
    name: 'NurseCreate',
    displayName: 'ActionTypeCreate',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 520,
    data: {
      userRole: 6,
      userRoleActionType: 4
    }
  },
  {
    value: 45,
    name: 'NurseUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 530,
    data: {
      userRole: 6,
      userRoleActionType: 5
    }
  },
  {
    value: 46,
    name: 'NurseDelete',
    displayName: 'ActionTypeDelete',
    group: 'NurseManagement',
    shortName: null,
    description: null,
    prompt: 'NurseList',
    order: 540,
    data: {
      userRole: 6,
      userRoleActionType: 6
    }
  },
  {
    value: 47,
    name: 'PharmacistList',
    displayName: 'ActionTypeList',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 550,
    data: {
      userRole: 7,
      userRoleActionType: 0
    }
  },
  {
    value: 48,
    name: 'PharmacistAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 560,
    data: {
      userRole: 7,
      userRoleActionType: 1
    }
  },
  {
    value: 49,
    name: 'PharmacistAll',
    displayName: 'ActionTypeAll',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 570,
    data: {
      userRole: 7,
      userRoleActionType: 2
    }
  },
  {
    value: 50,
    name: 'PharmacistCreate',
    displayName: 'ActionTypeCreate',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 590,
    data: {
      userRole: 7,
      userRoleActionType: 4
    }
  },
  {
    value: 51,
    name: 'PharmacistUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 600,
    data: {
      userRole: 7,
      userRoleActionType: 5
    }
  },
  {
    value: 52,
    name: 'PharmacistDelete',
    displayName: 'ActionTypeDelete',
    group: 'PharmacistManagement',
    shortName: null,
    description: null,
    prompt: 'PharmacistList',
    order: 610,
    data: {
      userRole: 7,
      userRoleActionType: 6
    }
  },
  {
    value: 53,
    name: 'WarehouseList',
    displayName: 'ActionTypeList',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 620,
    data: {
      userRole: 8,
      userRoleActionType: 0
    }
  },
  {
    value: 54,
    name: 'WarehouseAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 630,
    data: {
      userRole: 8,
      userRoleActionType: 1
    }
  },
  {
    value: 55,
    name: 'WarehouseAll',
    displayName: 'ActionTypeAll',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 640,
    data: {
      userRole: 8,
      userRoleActionType: 2
    }
  },
  {
    value: 56,
    name: 'WarehouseCreate',
    displayName: 'ActionTypeCreate',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 660,
    data: {
      userRole: 8,
      userRoleActionType: 4
    }
  },
  {
    value: 57,
    name: 'WarehouseUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 670,
    data: {
      userRole: 8,
      userRoleActionType: 5
    }
  },
  {
    value: 58,
    name: 'WarehouseDelete',
    displayName: 'ActionTypeDelete',
    group: 'WarehouseManagement',
    shortName: null,
    description: null,
    prompt: 'WarehouseList',
    order: 680,
    data: {
      userRole: 8,
      userRoleActionType: 6
    }
  },
  {
    value: 59,
    name: 'IMSList',
    displayName: 'ActionTypeList',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 690,
    data: {
      userRole: 9,
      userRoleActionType: 0
    }
  },
  {
    value: 60,
    name: 'IMSAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 700,
    data: {
      userRole: 9,
      userRoleActionType: 1
    }
  },
  {
    value: 61,
    name: 'IMSAll',
    displayName: 'ActionTypeAll',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 710,
    data: {
      userRole: 9,
      userRoleActionType: 2
    }
  },
  {
    value: 62,
    name: 'IMSCreate',
    displayName: 'ActionTypeCreate',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 730,
    data: {
      userRole: 9,
      userRoleActionType: 4
    }
  },
  {
    value: 63,
    name: 'IMSUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 740,
    data: {
      userRole: 9,
      userRoleActionType: 5
    }
  },
  {
    value: 64,
    name: 'IMSDelete',
    displayName: 'ActionTypeDelete',
    group: 'IMSManagement',
    shortName: null,
    description: null,
    prompt: 'IMSList',
    order: 750,
    data: {
      userRole: 9,
      userRoleActionType: 6
    }
  },
  {
    value: 65,
    name: 'PatientList',
    displayName: 'ActionTypeList',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 760,
    data: {
      userRole: 10,
      userRoleActionType: 0
    }
  },
  {
    value: 66,
    name: 'PatientAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 770,
    data: {
      userRole: 10,
      userRoleActionType: 1
    }
  },
  {
    value: 67,
    name: 'PatientAll',
    displayName: 'ActionTypeAll',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 780,
    data: {
      userRole: 10,
      userRoleActionType: 2
    }
  },
  {
    value: 68,
    name: 'PatientJourney',
    displayName: 'ActionTypeJourney',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 790,
    data: {
      userRole: 10,
      userRoleActionType: 3
    }
  },
  {
    value: 69,
    name: 'PatientCreate',
    displayName: 'ActionTypeCreate',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 800,
    data: {
      userRole: 10,
      userRoleActionType: 4
    }
  },
  {
    value: 70,
    name: 'PatientUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 810,
    data: {
      userRole: 10,
      userRoleActionType: 5
    }
  },
  {
    value: 71,
    name: 'PatientDelete',
    displayName: 'ActionTypeDelete',
    group: 'PatientManagement',
    shortName: null,
    description: null,
    prompt: 'PatientList',
    order: 820,
    data: {
      userRole: 10,
      userRoleActionType: 6
    }
  },
  {
    value: 72,
    name: 'CityList',
    displayName: 'ActionTypeList',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 830,
    data: {}
  },
  {
    value: 73,
    name: 'CityAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 840,
    data: {}
  },
  {
    value: 74,
    name: 'CityCreate',
    displayName: 'ActionTypeCreate',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 850,
    data: {}
  },
  {
    value: 75,
    name: 'CityUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 860,
    data: {}
  },
  {
    value: 76,
    name: 'CityDelete',
    displayName: 'ActionTypeDelete',
    group: 'CityManagement',
    shortName: null,
    description: null,
    prompt: 'CityList',
    order: 870,
    data: {}
  },
  {
    value: 77,
    name: 'LocalizationList',
    displayName: 'ActionTypeList',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 880,
    data: {}
  },
  {
    value: 78,
    name: 'LocalizationAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 890,
    data: {}
  },
  {
    value: 79,
    name: 'LocalizationExcelImportExport',
    displayName: 'ActionTypeImportExport',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 900,
    data: {}
  },
  {
    value: 80,
    name: 'LocalizationCreate',
    displayName: 'ActionTypeCreate',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 910,
    data: {}
  },
  {
    value: 81,
    name: 'LocalizationUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 920,
    data: {}
  },
  {
    value: 82,
    name: 'LocalizationDelete',
    displayName: 'ActionTypeDelete',
    group: 'LocalizationManagement',
    shortName: null,
    description: null,
    prompt: 'LocalizationList',
    order: 930,
    data: {}
  },
  {
    value: 83,
    name: 'TemplateList',
    displayName: 'ActionTypeList',
    group: 'TemplateManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 940,
    data: {}
  },
  {
    value: 84,
    name: 'TemplateCreate',
    displayName: 'ActionTypeCreate',
    group: 'TemplateManagement',
    shortName: null,
    description: null,
    prompt: 'TemplateList',
    order: 950,
    data: {}
  },
  {
    value: 85,
    name: 'TemplateUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'TemplateManagement',
    shortName: null,
    description: null,
    prompt: 'TemplateList',
    order: 960,
    data: {}
  },
  {
    value: 86,
    name: 'NoticeList',
    displayName: 'ActionTypeList',
    group: 'NoticeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 970,
    data: {}
  },
  {
    value: 87,
    name: 'NoticeAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'NoticeManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeList',
    order: 980,
    data: {}
  },
  {
    value: 88,
    name: 'NoticeContactList',
    displayName: 'ActionTypeList',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 990,
    data: {}
  },
  {
    value: 89,
    name: 'NoticeContactAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 1000,
    data: {}
  },
  {
    value: 90,
    name: 'NoticeContactCreate',
    displayName: 'ActionTypeCreate',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 1010,
    data: {}
  },
  {
    value: 91,
    name: 'NoticeContactUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 1020,
    data: {}
  },
  {
    value: 92,
    name: 'NoticeContactDelete',
    displayName: 'ActionTypeDelete',
    group: 'NoticeContactManagement',
    shortName: null,
    description: null,
    prompt: 'NoticeContactList',
    order: 1030,
    data: {}
  },
  {
    value: 93,
    name: 'ConferenceList',
    displayName: 'ActionTypeList',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1040,
    data: {}
  },
  {
    value: 94,
    name: 'ConferenceCreate',
    displayName: 'ActionTypeCreate',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 1050,
    data: {}
  },
  {
    value: 95,
    name: 'ConferenceUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 1060,
    data: {}
  },
  {
    value: 96,
    name: 'ConferenceDelete',
    displayName: 'ActionTypeDelete',
    group: 'ConferenceManagement',
    shortName: null,
    description: null,
    prompt: 'ConferenceList',
    order: 1070,
    data: {}
  },
  {
    value: 97,
    name: 'HomeWidgetVasScore',
    displayName: 'HomeWidgetVasScore',
    group: 'HomeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1080,
    data: {}
  },
  {
    value: 98,
    name: 'HomeWidgetDoseCalculator',
    displayName: 'HomeWidgetDoseCalculator',
    group: 'HomeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1090,
    data: {}
  },
  {
    value: 99,
    name: 'HomeWidgetPatientActivities',
    displayName: 'HomeWidgetPatientActivities',
    group: 'HomeManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1100,
    data: {}
  },
  {
    value: 100,
    name: 'JourneyPage',
    displayName: 'JourneyPage',
    group: 'PageManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1110,
    data: {}
  },
  {
    value: 101,
    name: 'ContentPage',
    displayName: 'ContentPage',
    group: 'PageManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1120,
    data: {}
  },
  {
    value: 102,
    name: 'DeliveryFlowList',
    displayName: 'ActionTypeList',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1130,
    data: {}
  },
  {
    value: 103,
    name: 'DeliveryFlowAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 1140,
    data: {}
  },
  {
    value: 104,
    name: 'DeliveryFlowCreate',
    displayName: 'ActionTypeCreate',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 1150,
    data: {}
  },
  {
    value: 105,
    name: 'DeliveryFlowUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 1160,
    data: {}
  },
  {
    value: 106,
    name: 'DeliveryFlowDelete',
    displayName: 'ActionTypeDelete',
    group: 'DeliveryFlowManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryFlowList',
    order: 1170,
    data: {}
  },
  {
    value: 107,
    name: 'DeliveryList',
    displayName: 'ActionTypeList',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1180,
    data: {}
  },
  {
    value: 108,
    name: 'DeliveryAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 1190,
    data: {}
  },
  {
    value: 109,
    name: 'DeliveryAll',
    displayName: 'ActionTypeAll',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 1200,
    data: {}
  },
  {
    value: 110,
    name: 'DeliveryUser',
    displayName: 'ActionTypeUser',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 1210,
    data: {}
  },
  {
    value: 111,
    name: 'DeliveryCreate',
    displayName: 'ActionTypeCreate',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 1220,
    data: {}
  },
  {
    value: 112,
    name: 'DeliveryUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 1230,
    data: {}
  },
  {
    value: 113,
    name: 'DeliveryReject',
    displayName: 'ActionTypeReject',
    group: 'DeliveryManagement',
    shortName: null,
    description: null,
    prompt: 'DeliveryList',
    order: 1240,
    data: {}
  },
  {
    value: 114,
    name: 'ContentList',
    displayName: 'ActionTypeList',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1250,
    data: {}
  },
  {
    value: 115,
    name: 'ContentAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 1260,
    data: {}
  },
  {
    value: 116,
    name: 'ContentCreate',
    displayName: 'ActionTypeCreate',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 1270,
    data: {}
  },
  {
    value: 117,
    name: 'ContentUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 1280,
    data: {}
  },
  {
    value: 118,
    name: 'ContentDelete',
    displayName: 'ActionTypeDelete',
    group: 'ContentManagement',
    shortName: null,
    description: null,
    prompt: 'ContentList',
    order: 1290,
    data: {}
  },
  {
    value: 119,
    name: 'StorageList',
    displayName: 'ActionTypeList',
    group: 'StorageManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1300,
    data: {}
  },
  {
    value: 120,
    name: 'StorageUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'StorageManagement',
    shortName: null,
    description: null,
    prompt: 'StorageList',
    order: 1310,
    data: {}
  },
  {
    value: 121,
    name: 'StorageDelete',
    displayName: 'ActionTypeDelete',
    group: 'StorageManagement',
    shortName: null,
    description: null,
    prompt: 'StorageList',
    order: 1320,
    data: {}
  },
  {
    value: 122,
    name: 'TreatmentList',
    displayName: 'ActionTypeList',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1330,
    data: {}
  },
  {
    value: 123,
    name: 'TreatmentCreate',
    displayName: 'ActionTypeCreate',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 1340,
    data: {}
  },
  {
    value: 124,
    name: 'TreatmentUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 1350,
    data: {}
  },
  {
    value: 125,
    name: 'TreatmentDelete',
    displayName: 'ActionTypeDelete',
    group: 'TreatmentManagement',
    shortName: null,
    description: null,
    prompt: 'TreatmentList',
    order: 1360,
    data: {}
  },
  {
    value: 126,
    name: 'UserInviteList',
    displayName: 'ActionTypeList',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1370,
    data: {}
  },
  {
    value: 127,
    name: 'UserInviteAllCountry',
    displayName: 'ActionTypeAllCountry',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 1380,
    data: {}
  },
  {
    value: 128,
    name: 'UserInviteCreate',
    displayName: 'ActionTypeCreate',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 1390,
    data: {}
  },
  {
    value: 129,
    name: 'UserInviteDelete',
    displayName: 'ActionTypeDelete',
    group: 'UserInviteManagement',
    shortName: null,
    description: null,
    prompt: 'UserInviteList',
    order: 1400,
    data: {}
  },
  {
    value: 130,
    name: 'VisitList',
    displayName: 'ActionTypeList',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1410,
    data: {}
  },
  {
    value: 131,
    name: 'VisitCreate',
    displayName: 'ActionTypeCreate',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 1420,
    data: {}
  },
  {
    value: 132,
    name: 'VisitUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 1430,
    data: {}
  },
  {
    value: 133,
    name: 'VisitDelete',
    displayName: 'ActionTypeDelete',
    group: 'VisitManagement',
    shortName: null,
    description: null,
    prompt: 'VisitList',
    order: 1440,
    data: {}
  },
  {
    value: 134,
    name: 'VitalList',
    displayName: 'ActionTypeList',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: null,
    order: 1450,
    data: {}
  },
  {
    value: 135,
    name: 'VitalCreate',
    displayName: 'ActionTypeCreate',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 1460,
    data: {}
  },
  {
    value: 136,
    name: 'VitalUpdate',
    displayName: 'ActionTypeUpdate',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 1470,
    data: {}
  },
  {
    value: 137,
    name: 'VitalDelete',
    displayName: 'ActionTypeDelete',
    group: 'VitalManagement',
    shortName: null,
    description: null,
    prompt: 'VitalList',
    order: 1480,
    data: {}
  }
];

export default ActionTypes;