import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdNotificationsActive, MdNotificationsNone } from 'react-icons/md';
import styled, { keyframes } from 'styled-components';
import { NotificationStatusType, NotificationType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { notificationCount, notificationList, notificationRead } from '../../../services/api';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Drawer } from '../../globals';
import NotificationConferenceJoinTemplate from '../../templates/notification/NotificationConferenceJoinTemplate';
import NotificationConferenceTemplate from '../../templates/notification/NotificationConferenceTemplate';
import NotificationDefaultTemplate from '../../templates/notification/NotificationDefaultTemplate';
import NotificationDeliveryStepChangeTemplate from '../../templates/notification/NotificationDeliveryStepChangeTemplate';

export default function NotificationBase() {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [panelVisibility, setPanelVisibility] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [total, setTotal] = useState(0);
  const [state, dispatch] = useContext(ReduceContext);

  // Hooks
  useEffect(() => {
    count();
  }, []);

  useEffect(() => {
    if (state?.notification?.list) {
      dispatch({ ...state, notification: { list: false } });
      count();

      if (panelVisibility) {
        load();
      }
    }
  }, [state?.notification?.list]);

  useEffect(() => {
    let readIds = [];
    let pendings = notifications.filter((x) => x.status === NotificationStatusType.Pending);

    if (pendings?.length > 0) {
      readIds = [...readIds, ...pendings.map((x) => x.id)];
    }

    let conferences = notifications.filter((x) => x.type === NotificationType.ConferenceScheduled && x.status === NotificationStatusType.ActionPending);
    if (conferences?.length > 0) {
      let conferenceDatas = conferences
        .map((x) => {
          x.detail = JSON.parse(x.data);
          x.dates = x.detail.Dates.map((x) => moment(x).add(user.t, 'minutes'));

          return x;
        })
        .filter((x) => x.dates.some((d) => moment() > d));

      readIds = [...readIds, ...conferenceDatas.map((x) => x.id)];
    }

    if (readIds.length > 0) {
      read(readIds);
    }
  }, [notifications]);

  // Functions
  const load = async () => {
    list();
    count();
  };

  const count = async () => {
    await notificationCount((status, res) => {
      if (status) {
        setTotal(res.data);
      }
    });
  };

  const list = async () => {
    await notificationList((status, res) => {
      if (status) {
        setNotifications(res.data);
        setPanelVisibility(true);
      }
    });
  };

  const read = async (ids) => {
    await notificationRead({ ids }, (status, res) => {
      if (status) {
        count();
      }
    });
  };

  // Components
  const Template = ({ notification }) => {
    switch (notification.type) {
      case NotificationType.ConferenceJoinUser:
        return <NotificationConferenceJoinTemplate notification={notification} />;
      case NotificationType.ConferenceScheduled:
      case NotificationType.ConferenceScheduledApproved:
      case NotificationType.ConferenceScheduledRejected:
        return <NotificationConferenceTemplate notification={notification} load={load} />;
      case NotificationType.DeliveryStep:
        return <NotificationDeliveryStepChangeTemplate notification={notification} load={load} />;
      default:
        return <NotificationDefaultTemplate notification={notification} load={load} />;
    }
  };

  // Render
  return (
    <Container>
      <IconContainer>{total > 0 ? <IconActive onClick={() => list()} /> : <Icon onClick={() => list()} />}</IconContainer>

      <Drawer title={t('Notifications')} placement="left" visible={panelVisibility} onClose={() => setPanelVisibility(false)} width={500} templates={['notification']}>
        {notifications?.length > 0 ? (
          <List>
            {notifications.map((x, i) => (
              <ListItem key={`template-${i}`}>
                <Template notification={x} />
              </ListItem>
            ))}
          </List>
        ) : (
          t('NotificationNotFound')
        )}
      </Drawer>
    </Container>
  );
}

const Container = styled.div``;

const List = styled.div``;

const ListItem = styled.div`
  margin-bottom: 10px;
  border: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
  border-radius: 10px;
  padding: 8px;
`;

const IconContainer = styled.div`
  position: relative;
`;

const breatheAnimation = keyframes`
 0%   { transform: rotate(0deg);  }
 25%  { transform: rotate(50deg); }
 50%  { transform: rotate(-50deg); }
 100% { transform: rotate(0deg); }
`;

const Icon = styled(MdNotificationsNone)`
  font-size: 20px;
  padding: 15px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(x) => x.theme.colors.darkTurquoiseHeader};
`;

const IconActive = styled(MdNotificationsActive)`
  font-size: 20px;
  padding: 15px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(x) => x.theme.colors.rustyRed};
  animation-name: ${breatheAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;
