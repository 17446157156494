import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function StorageInputs({ files, setFiles, onFolderCheckChange, onFolderNameTextChange, isNewFolder }) {
  const { t } = useTranslation();

  return [
    {
      type: 'upload',
      name: 'upload',
      files,
      setFiles,
    },
    {
      type: 'switch',
      name: 'folder',
      label: t('CreateNewFolder'),
      templates: ['horizontal-item'],
      css: `margin-bottom: 0px;`,
      onChange: onFolderCheckChange,
    },
    {
      type: 'input',
      name: 'path',
      label: t('Path'),
      disabled: true,
    },
    {
      type: 'input',
      name: 'folderName',
      label: t('FolderName'),
      placeholder: t('FolderNameEmpty'),
      onChange: onFolderNameTextChange,
      visible: isNewFolder,
      rules: [
        { required: isNewFolder, message: t('FolderNameEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
    },
  ];
}
