import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { TiFlowChildren } from 'react-icons/ti';
import { Button, Switch } from '../../components/globals';
import { CountryTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function DeliveryFlowColumns({ isAllCountry, onUpdateClick, onStepUpdateClick, onActivityChange }) {
  const { t } = useTranslation();

  let columns = [
    isAllCountry && {
      locale: 'Country',
      name: 'country',
      type: 'string',
      render: (value, row) => {
        let country = CountryTypes.find((x) => x.value === value);

        return country ? (
          <Tooltip placement="topLeft" title={t(country.displayName)}>
            {t(country.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'NameKey',
      name: 'nameKey',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={t(value)}>
          {value}
        </Tooltip>
      ),
    },
    onActivityChange && {
      locale: 'Activity',
      name: 'isActive',
      render: (value, row) => <Switch key="isActive" checked={value} loading={row.loading} onChange={(val) => onActivityChange(val, row)} />,
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
    onStepUpdateClick && {
      locale: 'Steps',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="steps" onClick={() => onStepUpdateClick(row)} templates={['table']}>
          <TiFlowChildren />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
