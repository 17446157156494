const DeliveryFlowStepNotificationGroupTypes = [
  {
    value: 0,
    name: 'BeforeStepComplete',
    displayName: 'NotificationGroupBeforeStepComplete',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'AfterStepComplete',
    displayName: 'NotificationGroupAfterStepComplete',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  }
];

export default DeliveryFlowStepNotificationGroupTypes;