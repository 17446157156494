const RoleFieldType = {
  GenderType: 0,
  BirthDate: 1,
  IdentityNumber: 2,
  AadharNumber: 3,
  DiseaseTypes: 4,
  Address: 5,
  SecondaryAddress: 6,
  SecondaryContactName: 7,
  SecondaryContactPhone: 8,
  TherapeuticAreaType: 9,
  CegedimId: 10,
  Institution: 11,
  UciCode: 12,
  CrmId: 13
};

export default RoleFieldType;