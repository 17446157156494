import { Form } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { ConferenceStatusType, NotificationStatusTypes, NotificationType, NotificationTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { conferenceUpdateStatus } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Button, Element, Modal } from '../../globals';

export default function NotificationConferenceTemplate({ notification, load }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [state, dispatch] = useContext(ReduceContext);
  const [data, setData] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);

  // Hooks
  useEffect(() => {
    if (notification) {
      let result = { ...notification, detail: JSON.parse(notification.data) };

      if (result.detail?.Dates) {
        result.detail.Dates = result.detail.Dates.map((x) => moment(x).add(user.t, 'minutes'));
      }

      result.statusData = NotificationStatusTypes.find((x) => x.value === result.status);
      result.typeData = NotificationTypes.find((x) => x.value === result.type);

      setData(result);
    }
  }, [notification]);

  // Events
  const onFinish = async (model) => {
    model = { ...model, id: data.detail.Id, notificationId: notification.id, status: ConferenceStatusType.Approved };

    await conferenceUpdateStatus(model, (status, res) => {
      if (status) {
        load();
        dispatch({ ...state, conference: { list: true } });
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  const onRejectClick = async () => {
    let model = { id: data.detail.Id, notificationId: notification.id, status: ConferenceStatusType.Rejected };

    await conferenceUpdateStatus(model, (status, res) => {
      if (status) {
        load();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  // Components
  const RenderBody = () => {
    switch (data.type) {
      case NotificationType.ConferenceScheduled:
        let isAvailable = data.detail.Dates.some((x) => moment() < x);
        if (!isAvailable) {
          return (
            <Body>
              <Detail>{format(t('ConferenceIsNotAvailable'), data.detail?.UserFullName)}</Detail>
            </Body>
          );
        }

        return (
          <Body>
            <Detail>{format(t('ConferenceSentInvitation'), data.detail?.UserFullName)}</Detail>

            <Actions>
              <Button key="show" onClick={() => setPanelVisibility(true)}>
                {t('Show')}
              </Button>
              <Button key="reject" onClick={onRejectClick}>
                {t('Reject')}
              </Button>
            </Actions>
          </Body>
        );

      case NotificationType.ConferenceScheduledApproved:
        return (
          <Body>
            {data.detail.UserId === user.i ? (
              <Detail>{format(t('ConferenceHasAcceptedYourInvitation'), data.detail?.TargetUserFullName)}</Detail>
            ) : (
              <Detail>{format(t('ConferenceHasAccepted'), data.detail?.UserFullName)}</Detail>
            )}
          </Body>
        );

      case NotificationType.ConferenceScheduledRejected:
        return (
          <Body>
            {data.detail.UserId === user.i ? (
              <Detail>{format(t('ConferenceHasRejectedYourInvitation'), data.detail?.TargetUserFullName)}</Detail>
            ) : (
              <Detail>{format(t('ConferenceHasRejected'), data.detail?.UserFullName)}</Detail>
            )}
          </Body>
        );

      default:
        return <>BodyNotFound</>;
    }
  };

  // Render
  return (
    data && (
      <Container>
        <Type>{t(data.typeData?.displayName)}</Type>
        <Date>{moment(data.creationTime).format('llll')}</Date>

        <RenderBody />

        <Modal width={320} title={t(data.typeData?.displayName)} visible={panelVisibility} onCancelClick={() => setPanelVisibility(false)} footer={null}>
          <Element
            key="conference-form"
            columnSize={24}
            formRef={formRef}
            onFinish={onFinish}
            inputs={[
              {
                type: 'radio-group',
                name: `date`,
                label: t('SelectDate'),
                rules: [{ required: true, message: t('DateEmpty') }],
                data: data?.detail?.Dates?.map((x) => ({ label: moment(x).format('llll'), value: moment(x).format() })),
                css: `
                  .ant-radio-wrapper-in-form-item {
                    width: 100%;
                    margin-bottom: 10px;
                  }
                  `,
              },
            ]}
            submit={t('Confirm')}
          />
        </Modal>
      </Container>
    )
  );
}

const Container = styled.div``;

const Type = styled.div``;

const Date = styled.div``;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Detail = styled.div``;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
