import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button } from '../../components/globals';
import { TemplateGroupTypes, TemplateTypes, UserRoleTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function TemplateColumns({ onUpdateClick }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'TemplateType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let templateType = TemplateTypes.find((x) => x.value === value);

        return templateType ? (
          <Tooltip placement="topLeft" title={t(templateType.displayName)}>
            {t(templateType.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'TemplateGroup',
      name: 'group',
      type: 'string',
      render: (value, row) => {
        let templateGroup = TemplateGroupTypes.find((x) => x.value === value);

        return templateGroup ? (
          <Tooltip placement="topLeft" title={t(templateGroup.displayName)}>
            {t(templateGroup.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'UserRole',
      name: 'userRole',
      type: 'string',
      render: (value, row) => {
        let userRole = UserRoleTypes.find((x) => x.value === value);

        return userRole ? (
          <Tooltip placement="topLeft" title={t(userRole.displayName)}>
            {t(userRole.displayName)}
          </Tooltip>
        ) : (
          'N/A'
        );
      },
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
