import { useTranslation } from 'react-i18next';
import { DeliveryCalculateType, DeliveryCalculateTypes } from '../../../../../constants';

export default function EvrysdiInputs({ calculateType, onCalculateChange, onWeightChange, onDailyDoseMg, onDailyDoseMl }) {
  const { t } = useTranslation();

  return [
    {
      type: 'select',
      name: 'calculate',
      label: t('CalculateType'),
      placeholder: t('SelectCalculateType'),
      rules: [{ required: true, message: t('CalculateTypeEmpty') }],
      data: DeliveryCalculateTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: DeliveryCalculateType.Automatically,
      onChange: onCalculateChange,
    },
    {
      type: 'number',
      name: 'weight',
      label: t('WeightInKg'),
      placeholder: t('WeightEmpty'),
      rules: [{ required: true, message: t('WeightEmpty') }],
      disabled: calculateType === DeliveryCalculateType.Manuel,
      initialValue: 0,
      onChange: onWeightChange,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'dailyDoseMg',
      label: t('DailyDoseMg'),
      placeholder: t('DailyDoseMgEmpty'),
      rules: [{ required: true, message: t('DailyDoseMgEmpty') }],
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 5 },
      onChange: onDailyDoseMg,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'dailyDoseMl',
      label: t('DailyDoseMl'),
      placeholder: t('DailyDoseMlEmpty'),
      rules: [{ required: true, message: t('DailyDoseMlEmpty') }],
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 6.6 },
      onChange: onDailyDoseMl,
      step: '0.1',
    },
  ];
}
