import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash, FaTrashRestore } from 'react-icons/fa';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { Button, PopupConfirm, Radio } from '../../globals';

export default function ExtraBase({ pageState, selectedRowKeys, filter, setFilter, activityFilter, onFilterChanged, onDeleteClick }) {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const options = () => {
    let result = [
      { label: t('All'), value: 'all' },
      { label: t('Deleted'), value: 'deleteds' },
    ];

    if (activityFilter) {
      result.push(
        ...[
          { label: t('Actives'), value: 'actives' },
          { label: t('Passives'), value: 'passives' },
        ]
      );
    }

    return result;
  };

  const onFilterChange = (e) => {
    if (onFilterChanged) onFilterChanged(e);

    switch (e.target.value) {
      case 'all':
        setFilter({ ...filter, isActive: undefined, isDeleted: false });
        break;
      case 'deleteds':
        setFilter({ ...filter, isActive: undefined, isDeleted: true });
        break;
      case 'actives':
        setFilter({ ...filter, isActive: true, isDeleted: false });
        break;
      case 'passives':
        setFilter({ ...filter, isActive: false, isDeleted: false });
        break;
      default:
        setFilter({ ...filter, isActive: undefined, isDeleted: false });
        break;
    }
  };

  const getValue = () => {
    if (filter?.isActive === undefined && filter?.isDeleted === false) return 'all';
    if (filter?.isActive === undefined && filter?.isDeleted === true) return 'deleteds';
    if (filter?.isActive === true && filter?.isDeleted === false) return 'actives';
    if (filter?.isActive === false && filter?.isDeleted === false) return 'passives';
  };

  return (
    <Container>
      {filter && setFilter && <Radio.Group options={options()} onChange={onFilterChange} value={getValue()} optionType="button" />}

      {selectedRowKeys?.length > 0 && onDeleteClick && (
        <PopupConfirm
          title={filter.isDeleted ? format(t('YouSureWantRestore'), selectedRowKeys?.length) : format(t('YouSureWantDelete'), selectedRowKeys?.length)}
          onConfirm={onDeleteClick}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="bottomRight"
        >
          {filter.isDeleted && (
            <Button ready={pageState?.delete} templates={['filter']}>
              <FaTrashRestore />
            </Button>
          )}
          {!filter.isDeleted && (
            <Button ready={pageState?.delete} templates={['filter']}>
              <FaTrash />
            </Button>
          )}
        </PopupConfirm>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: 8px;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;

    > :not(:last-child) {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
`;
