import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deliveryStepUpdate } from '../../../../services/api';
import { notifySuccess } from '../../../../utilies/notification';
import { Button } from '../../../globals';

export default function RedirectDelivery({ country, delivery, step, redirects, complete }) {
  // Definitions
  const { t } = useTranslation();
  const [ready, setReady] = useState(true);

  const onSelect = async (redirect) => {
    let model = { ...redirect, currentStepId: step.id, country, deliveryId: delivery.id, targetUserId: delivery.targetUserId };

    setReady(false);
    await deliveryStepUpdate(model, (status, res) => {
      if (status) {
        complete();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
      setReady(true);
    });
  };

  // Render
  return (
    step &&
    redirects && (
      <Container>
        {redirects.map((x, i) => (
          <Button ready={ready} key={`redirect-${i}`} onClick={() => onSelect(x)} templates={['colored']} block={true}>
            {t(x.nameKey)}
          </Button>
        ))}
      </Container>
    )
  );
}

const Container = styled.div`
  > button {
    margin-top: 12px;
  }
`;
