import { createGlobalStyle } from 'styled-components';
import { button } from './elements/button';
import { editor } from './elements/editor';
import { layout } from './elements/layout';
import { notification } from './elements/notification';
import { phoneInput } from './elements/phoneInput';
import { popup } from './elements/popup';
import { select } from './elements/select';
import { treeSelect } from './elements/treeSelect';
import { upload } from './elements/upload';

export default createGlobalStyle`
  ${layout}
  ${notification}
  ${phoneInput}
  ${editor}
  ${button}
  ${select}
  ${popup}
  ${upload}
  ${treeSelect}
`;
