import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NotificationStatusTypes, NotificationTypes } from '../../../constants';

export default function NotificationDefaultTemplate({ notification }) {
  // Definitions
  const { t } = useTranslation();

  const [data, setData] = useState(null);

  // Hooks
  useEffect(() => {
    if (notification) {
      let result = { ...notification, detail: JSON.parse(notification.data) };

      result.statusData = NotificationStatusTypes.find((x) => x.value === result.status);
      result.typeData = NotificationTypes.find((x) => x.value === result.type);

      setData(result);
    }
  }, [notification]);

  // Render
  return (
    data && (
      <Container>
        <Type>{t(data.typeData?.displayName)}</Type>
        <Date>{moment(data.creationTime).format('llll')}</Date>
        <Body>NotImplemented</Body>
      </Container>
    )
  );
}

const Container = styled.div``;

const Type = styled.div``;

const Date = styled.div``;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
