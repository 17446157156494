import { ConfigProvider, Layout } from 'antd';
import antLocaleEN from 'antd/lib/locale/en_GB';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { LanguageTypes } from '../../../constants';
import i18n from '../../../i18n';
import { setItem } from '../../../services/cache';
import { getAntLocalization, getMomentLocalization } from '../../../utilies/localization';
import { notifyError } from '../../../utilies/notification';
import { LanguageSelect } from '../../globals';

const { Content } = Layout;

export default function GlobalLayoutBase({ children }) {
  // Definitions
  const location = useLocation();
  const { t } = useTranslation();

  const [localization, setLocalization] = useState(antLocaleEN);

  // Hooks
  useEffect(() => {
    if (location?.state?.error) {
      notifyError(t(location?.state?.error));
    }
  }, [location?.state]);

  const setLanguage = (languageCode) => {
    let language = LanguageTypes.find((x) => x.data.code === languageCode);

    let code = language.data.code.toLowerCase();
    let locale = language.data.locale.toLowerCase();
    let antLocalization = getAntLocalization(code);
    let momentLocalization = getMomentLocalization(code);

    setItem('lcl', code);
    setLocalization(antLocalization);
    moment.updateLocale(locale, [momentLocalization]);
    i18n.changeLanguage(code);
  };

  // Render
  return (
    <ConfigProvider locale={localization}>
      <Container>
        <LanguageSelect type="global" onClick={({ key }) => setLanguage(key)} />
        <Content>{children}</Content>
      </Container>
    </ConfigProvider>
  );
}

const Container = styled(Layout)`
  min-height: 100vh;
  background: url(/assets/images/layout/bg.jpg) center center no-repeat;
  background-size: cover;
`;
