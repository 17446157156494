import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import styled from 'styled-components';

export default function UserPasswordInputs({ onPasswordChange, onGeneratePasswordClick }) {
  const { t } = useTranslation();

  return [
    {
      type: 'input-password',
      name: 'password',
      label: (
        <PasswordLabel>
          {t('Password')}
          <GeneratePassword onClick={onGeneratePasswordClick}>{t('GenerateStrongPassword')}</GeneratePassword>
        </PasswordLabel>
      ),
      placeholder: t('PasswordEmpty'),
      onChange: onPasswordChange,
      rules: [
        { required: true, message: t('PasswordEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
      templates: ['grey'],
      css: `.ant-form-item-label label{
        width:100%;
      }`,
    },
    {
      type: 'input-password',
      name: 'passwordAgain',
      label: t('PasswordAgain'),
      placeholder: t('PasswordAgainEmpty'),
      rules: [
        { required: true, message: t('PasswordAgainEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
      templates: ['grey'],
    },
  ];
}

const PasswordLabel = styled.div`
  display: flex;
`;

const GeneratePassword = styled.div`
  font-weight: normal;
  position: absolute;
  right: 0px;
  cursor: pointer;
`;
