import { Form, Tabs } from 'antd';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { format } from 'react-string-format';
import replacer from 'react-string-replace';
import styled from 'styled-components';
import { Button, Card, Checkbox, Element, Global, Link, Modal } from '../../components/globals';
import { ContentGroupType, ContentType, UserLoginEmailInputs, UserLoginSmsInputs } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { useTimer } from '../../hooks/useTimer';
import i18n from '../../i18n';
import { contentStaticDetail, loginApprove, loginEmail, loginSms, loginSmsRequest } from '../../services/api';
import { getItem, getLocale, setItem } from '../../services/cache';
import { notifyError, notifyInfo, notifySuccess } from '../../utilies/notification';

const { TabPane } = Tabs;

export default function UserLogin() {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [emailFormRef] = Form.useForm();
  const [smsFormRef] = Form.useForm();
  const [, setUser] = useCacheUser();

  const [contentData, setContentData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [locationData, setLocationData] = useState(null);

  const [rememberMe, setRememberMe] = useState(false);
  const [isSmsLoginRequest, setIsSmsLoginRequest] = useState(false);
  const [seconds, startTimer, stopTimer, resetTimer] = useTimer(120);

  const [panelVisibility, setPanelVisibility] = useState(false);
  const [contentPanelVisibility, setContentPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ form: false });

  const [contentFilter, setContentFilter] = useState({ type: ContentType.Static });

  // Hooks
  useEffect(() => {
    let locale = getLocale();
    moment.locale(locale);
    i18n.changeLanguage(locale);
    setPageState((x) => ({ ...x, form: true }));

    let email = getItem('e');
    if (email) {
      emailFormRef.setFieldsValue({ email });
      setRememberMe(true);
    }

    let phone = getItem('p');
    if (phone) {
      smsFormRef.setFieldsValue({ phone });
      setRememberMe(true);
    }

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocationData({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      });
    }
  }, []);

  useEffect(() => {
    if (isSmsLoginRequest && seconds <= 0) {
      stopTimer();
      setIsSmsLoginRequest(false);
    }
  }, [isSmsLoginRequest, seconds]);

  // Functions
  const loginUser = (token) => {
    let result = jwtDecode(token);
    if (result.v === 0) {
      setUserData({ ...result, token });
      setPageState((x) => ({ ...x, form: true }));
      setPanelVisibility(true);
      setContentFilter((x) => ({ ...x, country: result.c }));
    } else {
      setUser(token);
      window.location = location?.state?.path ?? '/';
    }
  };

  // Events
  const onEmailFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    if (rememberMe) {
      setItem('e', model.email);
    }

    await loginEmail({ ...model, ...locationData, timeOffset: moment().utcOffset() }, (status, res) => {
      if (status) {
        loginUser(res.data);
      } else {
        setPageState((x) => ({ ...x, form: true }));
      }
    });
  };

  const onSmsFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));
    model = { ...model, ...locationData, timeOffset: moment().utcOffset() };

    if (isSmsLoginRequest) {
      await loginSms(model, (status, res) => {
        if (status) {
          stopTimer();
          loginUser(res.data);
        } else {
          setPageState((x) => ({ ...x, form: true }));
        }
      });

      return;
    }

    await loginSmsRequest(model, (status, res) => {
      if (status) {
        resetTimer(seconds);
        startTimer();
        setIsSmsLoginRequest(true);
        notifySuccess(t('VerificationCodeIsSended'));
      } else {
        let timeException = res.data.errors.find((x) => x.code === 'LoginSmsRequestTimeException');
        if (timeException) {
          let errorTime = moment(timeException.message);
          let duration = moment().diff(errorTime, 'seconds');
          if (duration > 0) {
            notifyError(format(t('LoginSmsRequestTimeout'), <strong>{duration}</strong>));
          } else {
            notifyInfo(t('PleaseTryAgainNow'));
          }
        }
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onStaticClick = async (group) => {
    await contentStaticDetail({ ...contentFilter, group }, (status, res) => {
      if (status) {
        setContentPanelVisibility(true);
        setContentData(res.data);
      }
    });
  };

  const onApproveConfirmClick = async () => {
    setPageState((x) => ({ ...x, form: false }));

    await loginApprove({ ...userData, country: userData.c }, (status, res) => {
      if (status) {
        setUser(res.data);
        setPanelVisibility(false);
        window.location = location?.state?.from?.pathname ?? '/';
      } else {
        setPageState((x) => ({ ...x, form: true }));
      }
    });
  };

  // Components
  const ApprovePanel = () => {
    let text = t('UserApproveText');

    let replacements = [
      {
        key: '{Static.PrivacyPolicy}',
        value: (
          <TextLink key="privacy-policy" onClick={() => onStaticClick(ContentGroupType.PrivacyPolicy)}>
            {t('PrivacyPolicy')}
          </TextLink>
        ),
      },
      {
        key: '{Static.TermsOfUse}',
        value: (
          <TextLink key="terms-of-use" onClick={() => onStaticClick(ContentGroupType.TermsOfUse)}>
            {t('TermsOfUse')}
          </TextLink>
        ),
      },
      {
        key: '{Static.PersonalDataProcessing}',
        value: (
          <TextLink key="personal-data-processing" onClick={() => onStaticClick(ContentGroupType.PersonalDataProcessing)}>
            {t('PersonalDataProcessing')}
          </TextLink>
        ),
      },
    ];

    replacements.forEach((x) => {
      text = replacer(text, x.key, () => x.value);
    });

    return text;
  };

  // Render
  return (
    <Global>
      <Container>
        <Card ready={pageState.form} templates={['login']}>
          <Logo src="/assets/images/logo-v2.svg" />
          <ContentTabs>
            <TabPane tab={t('LoginWithEmail')} key="email">
              <Element ready={pageState.form} formRef={emailFormRef} onFinish={onEmailFinish} inputs={UserLoginEmailInputs()} columnSize={24}>
                <ActionPanel>
                  <Checkbox checked={rememberMe} onClick={() => setRememberMe(!rememberMe)} templates={['bold']}>
                    {t('RememberMe')}
                  </Checkbox>
                  <Link to="/user/forgot-password">{t('ForgotPassword')}</Link>
                </ActionPanel>

                <Button htmlType="submit" templates={['colored']} ready={pageState.form} block={true}>
                  {t('Login')}
                </Button>
              </Element>
            </TabPane>
            <TabPane tab={t('LoginWithPhone')} key="phone">
              <Element ready={pageState.form} formRef={smsFormRef} onFinish={onSmsFinish} inputs={UserLoginSmsInputs({ isSmsLoginRequest })} columnSize={24}>
                {isSmsLoginRequest && (
                  <SmsRequestMessage>
                    Your verification code has been sent. Please enter the code received on your phone within <strong>{seconds}</strong> seconds.
                  </SmsRequestMessage>
                )}

                {/* {isSmsLoginRequest && <SmsRequestMessage>{format(t('SmsRequestMessage'), <strong>{seconds}</strong>)}</SmsRequestMessage>} */}
                <ActionPanel>
                  <Checkbox checked={rememberMe} onClick={() => setRememberMe(!rememberMe)} templates={['bold']}>
                    {t('RememberMe')}
                  </Checkbox>
                  <Link to="/user/forgot-password">{t('ForgotPassword')}</Link>
                </ActionPanel>

                <Button htmlType="submit" templates={['colored']} ready={pageState.form} block={true}>
                  {t(isSmsLoginRequest ? 'Confirm' : 'Login')}
                </Button>
              </Element>
            </TabPane>
          </ContentTabs>
        </Card>

        <Modal
          width={320}
          title={t('ApproveUser')}
          visible={panelVisibility}
          onCancelClick={() => setPanelVisibility(false)}
          okText={t('Confirm')}
          onOk={onApproveConfirmClick}
        >
          <ApprovePanel />
        </Modal>

        {contentData && (
          <Modal width="90%" title={t(contentData.title)} visible={contentPanelVisibility} onCancelClick={() => setContentPanelVisibility(false)} footer={null}>
            <ContentBody dangerouslySetInnerHTML={{ __html: contentData.body }}></ContentBody>
          </Modal>
        )}
      </Container>
    </Global>
  );
}

const ContentTabs = styled(Tabs)`
  .ant-tabs-nav {
    .ant-tabs-nav-list {
      width: 100%;
      .ant-tabs-tab {
        width: 100%;
        justify-content: center;
        font-weight: bold;
        color: ${(x) => x.theme.colors.lavenderGray};

        &:focus,
        &:hover {
          color: ${(x) => x.theme.colors.lavenderGray};
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${(x) => x.theme.colors.darkTurquoise};
          }
        }

        .ant-tabs-tab-btn {
          &:focus,
          &:hover {
            color: ${(x) => x.theme.colors.lavenderGray};
          }
        }
      }

      .ant-tabs-ink-bar {
        height: 3px;
        background: ${(x) => x.theme.colors.darkTurquoise};
      }
    }

    &::before {
      border-bottom: 3px solid ${(x) => x.theme.colors.lavenderGray};
    }
  }
`;

const TextLink = styled.a`
  color: blue;
`;

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 120px;
  margin: 10px auto 40px;
  display: block;
`;

const ActionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 44px;
`;

const ContentBody = styled.div``;

const SmsRequestMessage = styled.div`
  margin-bottom: 24px;
`;
