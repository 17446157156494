import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function EnspryngInputs() {
  const { t } = useTranslation();

  return [
    {
      name: 'usages',
      templates: ['item-row', 'left-number', 'multiple', 'treatment-list'],
      remove: true,
      list: [
        {
          type: 'input',
          name: 'row',
          allowClear: false,
          templates: ['row-number', 'number', 'treatment-row-number'],
        },
        {
          type: 'date',
          name: 'time',
          label: 'Time',
          rules: [{ required: true, message: 'TimeEmpty' }],
          disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
          css: `width:300px`,
        },
        {
          type: 'number',
          name: 'dailyDoseMg',
          label: t('DailyDoseMg'),
          placeholder: t('DailyDoseMgEmpty'),
          rules: [{ required: true, message: t('DailyDoseMgEmpty') }],
          disabled: true,
          css: `width:250px`,
        },
      ],
    },
  ];
}
