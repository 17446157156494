import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { NotificationStatusTypes, NotificationTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';

export default function NotificationConferenceJoinTemplate({ notification }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [data, setData] = useState(null);

  // Hooks
  useEffect(() => {
    if (notification) {
      let result = { ...notification, detail: JSON.parse(notification.data) };

      result.statusData = NotificationStatusTypes.find((x) => x.value === result.status);
      result.typeData = NotificationTypes.find((x) => x.value === result.type);

      setData(result);
    }
  }, [notification]);

  // Render
  return (
    data && (
      <Container>
        <Type>{t(data.typeData?.displayName)}</Type>
        <Date>{moment(data.creationTime).format('llll')}</Date>
        <Body>
          {data.detail.UserId === user.i ? (
            <Detail>{format(t('UserJoinedConference'), data.detail?.TargetUserFullName)}</Detail>
          ) : (
            <Detail>{format(t('UserJoinedConference'), data.detail?.UserFullName)}</Detail>
          )}
        </Body>
      </Container>
    )
  );
}

const Container = styled.div``;

const Type = styled.div``;

const Date = styled.div``;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Detail = styled.div``;
