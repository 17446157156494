const TemplateGroupTypes = [
  {
    value: 0,
    name: 'SmsTemplate',
    displayName: 'SmsTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 1,
    name: 'EmailTemplate',
    displayName: 'EmailTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  }
];

export default TemplateGroupTypes;