import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnterDeliveryDateInputs from '../../../../constants/inputs/delivery/step/EnterDeliveryDateInputs';
import { deliveryStepUpdate } from '../../../../services/api';
import { notifySuccess } from '../../../../utilies/notification';
import { Element } from '../../../globals';

export default function EnterDeliveryDate({ next, country, delivery, step, complete }) {
  // Definitions
  const { t } = useTranslation();
  const [ready, setReady] = useState(true);

  const onFinish = async (model) => {
    model = { ...next, currentStepId: step.id, country, deliveryId: delivery.id, targetUserId: delivery.targetUserId, data: JSON.stringify(model.deliveryDate) };

    setReady(false);
    await deliveryStepUpdate(model, (status, res) => {
      if (status) {
        complete();
        notifySuccess(t('UpdateCompleteSuccess'));
      }
      setReady(true);
    });
  };

  // Render
  return step && <Element ready={ready} key="conference-form" columnSize={24} onFinish={onFinish} inputs={EnterDeliveryDateInputs()} submit={t('Confirm')} />;
}
