import { t } from 'i18next';
import { format } from 'react-string-format';
import { DeliveryCalculateType, DeliveryCalculateTypes } from '../../../..';

export default function EvrysdiInputs({ calculateType, onCalculateChange, onFieldChange, onDailyDoseMg, onDailyDoseMl }) {
  return [
    {
      type: 'select',
      name: 'calculate',
      label: t('CalculateType'),
      placeholder: t('SelectCalculateType'),
      rules: [{ required: true, message: t('CalculateTypeEmpty') }],
      data: DeliveryCalculateTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: DeliveryCalculateType.Automatically,
      onChange: onCalculateChange,
    },
    {
      type: 'number',
      name: 'treatmentDuration',
      label: t('TreatmentDurationInMonths'),
      placeholder: t('TreatmentDurationInMonthsEmpty'),
      rules: [{ required: true, message: t('TreatmentDurationInMonthsEmpty') }],
      onChange: onFieldChange,
    },
    {
      type: 'number',
      name: 'weight',
      label: t('WeightInKg'),
      placeholder: t('WeightEmpty'),
      rules: [{ required: true, message: t('WeightEmpty') }],
      disabled: calculateType === DeliveryCalculateType.Manuel,
      initialValue: 0,
      onChange: onFieldChange,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'totalBottle',
      label: t('TotalBottle'),
      placeholder: t('TotalBottleEmpty'),
      rules: [{ required: true, message: t('TotalBottleEmpty') }],
    },
    {
      type: 'number',
      name: 'dailyDoseMg',
      label: t('DailyDoseMg'),
      placeholder: t('DailyDoseMgEmpty'),
      rules: [{ required: true, message: t('DailyDoseMgEmpty') }],
      disabled: calculateType === DeliveryCalculateType.Automatically,
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 5 },
      onChange: onDailyDoseMg,
      step: '0.1',
    },
    {
      type: 'number',
      name: 'dailyDoseMl',
      label: t('DailyDoseMl'),
      placeholder: t('DailyDoseMlEmpty'),
      rules: [{ required: true, message: t('DailyDoseMlEmpty') }],
      disabled: calculateType === DeliveryCalculateType.Automatically,
      initialValue: 0.1,
      numberRule: { min: 0.1, max: 6.6 },
      onChange: onDailyDoseMl,
      step: '0.1',
    },
    {
      type: 'input',
      name: 'note',
      label: t('Note'),
      placeholder: t('NoteOptional'),
      rules: [{ min: 3, max: 500, message: format(t('LengthValid'), 1, 500) }],
    },
    {
      type: 'input',
      name: 'targetUserAddress',
      label: t('Address'),
      placeholder: t('AddressEmpty'),
      rules: [
        { required: true, message: t('AddressEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 1, 500) },
      ],
    },
  ];
}
