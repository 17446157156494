import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

export default function SectionBase({ templates, pageState, setPageState, headerLeft, headerRight, children }) {
  const [changeTimeout, setChangeTimeout] = useState(null);

  useEffect(() => {
    if (pageState?.sync === undefined || pageState.sync === null) return;

    if (changeTimeout) clearTimeout(changeTimeout);

    setChangeTimeout(
      setTimeout(() => {
        setPageState((x) => ({ ...x, sync: undefined }));
      }, 2000)
    );
  }, [pageState]);

  const FormStatusIcon = () => {
    if (pageState?.sync === undefined) return null;
    if (pageState.sync === null) return <SyncIcon spin={true} />;

    return pageState.sync ? <SuccessIcon /> : <ErrorIcon />;
  };

  return (
    <Container templates={templates}>
      <Header templates={templates}>
        <Left>
          <FormStatusIcon />
          {headerLeft && headerLeft.map((x) => x)}
        </Left>
        <Right>{headerRight && headerRight.map((x) => x)}</Right>
      </Header>
      <Body>{children}</Body>
    </Container>
  );
}

const Container = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media only screen and (max-width: 600px) {
    ${({ templates }) =>
      templates?.includes('role-fields') &&
      css`
        flex-direction: column;
        align-items: flex-start;

        > div {
          width: 100%;
          > div {
            width: 100%;
          }
        }

        .ant-radio-group {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          .ant-radio-button-wrapper {
            margin: 0px;
            width: 100%;
            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      `}
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;

  .anticon {
    font-size: 20px;
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }

  h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const Body = styled.div``;

const SyncIcon = styled(SyncOutlined)`
  color: ${(x) => x.theme.colors.blue};
  margin-right: 10px;
`;

const SuccessIcon = styled(CheckCircleOutlined)`
  color: ${(x) => x.theme.colors.green};
  margin-right: 10px;
`;

const ErrorIcon = styled(CloseCircleOutlined)`
  color: ${(x) => x.theme.colors.red};
  margin-right: 10px;
`;
