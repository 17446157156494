import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { DeliveryActionType, DeliveryStepStatusType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import CompleteDelivery from './step/CompleteDelivery';
import ConfirmDelivery from './step/ConfirmDelivery';
import EnterDeliveryDate from './step/EnterDeliveryDate';
import EnterPatientVitals from './step/EnterPatientVitals';
import EnterVisitDate from './step/EnterVisitDate';
import RedirectDelivery from './step/RedirectDelivery';

export default function DeliveryStep({ country, delivery, step, flows, onCompleted }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  // Components
  const StepAction = () => {
    let next = flows.find((x) => x.parentId === step.deliveryFlowStepId);

    switch (step.action) {
      case DeliveryActionType.RedirectDelivery:
        let redirects = flows.filter((x) => x.parentId === step.deliveryFlowStepId);
        return <RedirectDelivery redirects={redirects} country={country} delivery={delivery} step={step} complete={onCompleted} />;

      case DeliveryActionType.ConfirmDelivery:
        return <ConfirmDelivery next={next} country={country} delivery={delivery} step={step} complete={onCompleted} />;

      case DeliveryActionType.CompleteDelivery:
        return <CompleteDelivery />;

      case DeliveryActionType.EnterDeliveryDate:
        return <EnterDeliveryDate next={next} country={country} delivery={delivery} step={step} complete={onCompleted} />;

      case DeliveryActionType.EnterVisitDate:
        return <EnterVisitDate next={next} country={country} delivery={delivery} step={step} complete={onCompleted} />;

      case DeliveryActionType.EnterPatientVitals:
        return <EnterPatientVitals next={next} country={country} delivery={delivery} step={step} complete={onCompleted} />;

      case DeliveryActionType.ReceivePayment:
        return <></>;

      case DeliveryActionType.ApprovePayment:
        return <></>;

      case DeliveryActionType.RussianCargoService:
        return <></>;

      default:
        return <>{t('NotImplemented')}</>;
    }
  };

  const StepData = () => {
    switch (step.action) {
      case DeliveryActionType.EnterDeliveryDate:
        let date = moment(JSON.parse(step.data)).add(user.t, 'minutes').format('llll');

        return <StepItem>{date}</StepItem>;
      default:
        return <StepItem>{moment(step.modificationTime).add(user.t, 'minutes').format('llll')}</StepItem>;
    }
  };

  // Render
  return (
    step && (
      <Container>
        <Detail>
          <Title>{t(step.nameKey)}</Title>
          <Status>{step.status === DeliveryStepStatusType.Approved ? <FaCheckCircle /> : <LoadingOutlined />}</Status>
        </Detail>

        {step.status === DeliveryStepStatusType.Approved && <StepData />}

        {step.status === DeliveryStepStatusType.Pending && step.userRole === user.u && <StepAction />}
      </Container>
    )
  );
}

const Container = styled.div`
  padding: 8px;
  border: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
  margin-bottom: 16px;
  border-radius: ${(x) => x.theme.global.borderRadius};
`;

const Detail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.div``;

const Status = styled.div`
  font-size: 20px;
  color: ${(x) => x.theme.colors.mediumSpringGreen};
`;

const StepItem = styled.div``;
