import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { CountryType, DrugType } from '../../../constants';

export default function TreatmentUsage({ formRef, data }) {
  const getUsagePath = () => {
    switch (data.drug.value) {
      case DrugType.Enspryng:
        switch (data.country.value) {
          case CountryType.India:
          case CountryType.Russian:
            return `${data.country.name.toLowerCase()}/${data.drug.name}`;

          default:
            return `DefaultUsage`;
        }

      default:
        return `DefaultUsage`;
    }
  };

  const Usage = lazy(() => import(`./usage/${getUsagePath()}`));

  return (
    <Container>
      <Usage formRef={formRef} data={data} />
    </Container>
  );
}

const Container = styled(Suspense)``;
