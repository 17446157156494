import { Tooltip } from 'antd';
import { Switch } from '../../components/globals';
import ColumnBase from './_ColumnBase';

export default function UserRelationColumns({ rowUserId, onRelationChange }) {
  let columns = [
    {
      locale: 'FirstName',
      name: 'firstName',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'LastName',
      name: 'lastName',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Email',
      name: 'email',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Phone',
      name: 'phone',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onRelationChange && {
      locale: 'Relation',
      name: 'isRelated',
      fixed: 'right',
      width: 100,
      render: (value, row) => rowUserId !== row.id && <Switch key="isRelated" checked={value} loading={row.loading} onChange={(val) => onRelationChange(val, row)} />,
    },
  ];

  return ColumnBase({ columns });
}
