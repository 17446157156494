import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, Switch } from '../../components/globals';
import { RoleFieldGroupType, RoleFieldTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function RoleFieldColumns({ onUpdateClick, onRequirementChange, onActivityChange }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'FieldType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let roleFieldType = RoleFieldTypes.find((x) => x.value === value);

        return roleFieldType ? (
          <Tooltip placement="topLeft" title={t(roleFieldType.displayName)}>
            {t(roleFieldType.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'FieldRow',
      name: 'row',
      type: 'int',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'FieldMinimumValue',
      name: 'minimumValue',
      type: 'int',
      render: (value, row) => {
        let roleFieldType = RoleFieldTypes.find((x) => x.value === row.type);

        return roleFieldType?.data.group === RoleFieldGroupType.Input || roleFieldType?.data.group === RoleFieldGroupType.Number ? (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ) : (
          t('Special')
        );
      },
    },
    {
      locale: 'FieldMaximumValue',
      name: 'maximumValue',
      type: 'int',
      render: (value, row) => {
        let roleFieldType = RoleFieldTypes.find((x) => x.value === row.type);

        return roleFieldType?.data.group === RoleFieldGroupType.Input || roleFieldType?.data.group === RoleFieldGroupType.Number ? (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ) : (
          t('Special')
        );
      },
    },
    {
      locale: 'IsRequired',
      name: 'isRequired',
      render: (value, row) => <Switch key="isRequired" checked={value} loading={row.loading} onChange={(val) => onRequirementChange(val, row)} />,
    },
    {
      locale: 'Activity',
      name: 'isActive',
      render: (value, row) => <Switch key="isActive" checked={value} loading={row.loading} onChange={(val) => onActivityChange(val, row)} />,
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
