import { Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserMd } from 'react-icons/fa';
import { GiMedicines } from 'react-icons/gi';
import { useNavigate, useParams } from 'react-router';
import { format } from 'react-string-format';
import styled, { css } from 'styled-components';
import { Button, Calendar, Modal, Page } from '../../components/globals';
import ContentUserList from '../../components/pages/journey/ContentUserList';
import VasScoreChart from '../../components/pages/journey/VasScoreChart';
import VisitList from '../../components/pages/journey/VisitList';
import VasScore from '../../components/pages/widget/VasScore';
import {
  ActionType,
  ConferenceStatusTypes,
  CountryTypes,
  DrugType,
  DrugTypes,
  TreatmentUsageStatusType,
  UserRoleActionType,
  UserRoleType,
  UserRoleTypes,
  VisitReasonTypes,
} from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import {
  conferenceList,
  treatmentList,
  treatmentMyList,
  treatmentUsageStatusUpdate,
  userDetail,
  userMyDetail,
  userRelationList,
  userRelationMyList,
  vasScoreList,
  vasScoreMyList,
  visitList,
  visitMyList,
} from '../../services/api';

export default function Journey() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { countryType, userRoleType, id } = useParams();

  const [user] = useCacheUser();

  const userRole = UserRoleTypes.find((x) => x.value === parseInt(userRoleType ?? user.u));
  const country = CountryTypes.find((x) => x.value === parseInt(countryType ?? user.c));

  const [response, setResponse] = useState({ vasScores: [] });
  const [pageState, setPageState] = useState({ list: false });
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [rowTreatment, setRowTreatment] = useState(null);

  const [vasScoreFilter] = useState({
    userId: id,
    isMyData: id === undefined,
    country: country.value,
    userRole: userRole.value,
  });

  const [treatmentFilter] = useState({
    pageNumber: 1,
    dataLimit: 1000,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    includeUsages: true,
    userId: id === undefined ? user.i : id,
    isMyData: id === undefined,
    userRole: userRole.value,
  });

  const [visitFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    includeUsages: true,
    userId: id === undefined ? user.i : id,
    isMyData: id === undefined,
    userRole: userRole.value,
  });

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    country: parseInt(user.c),
    id: id === undefined ? user.i : id,
    userRole: userRole.value,
    includeRoleActions: true,
    isMyData: id === undefined,
  });

  const [userRelationFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    country: parseInt(user.c),
    currentUserRole: UserRoleType.Patient,
    userRole: UserRoleType.Doctor,
    relationUserRole: UserRoleType.Patient,
    relationUserId: id === undefined ? user.i : id,
    includeUser: true,
    isDeleted: false,
  });

  const [contentUserFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    orderBy: ['modificationTime|desc'],
    userId: id === undefined ? user.i : id,
    isMyData: id === undefined,
    includeParentContent: true,
    country: parseInt(user.c),
    userRole: userRole.value,
  });

  const [conferenceFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: true,
    orderBy: ['date|asc'],
    minDate: moment().add(-1, 'hours').format(),
    currentUserId: user?.i,
    includeUser: true,
    includeTargetUser: true,
  });

  const isAllCountry = user.checkUserRole(userRole.value, UserRoleActionType.AllCountry);

  // Hooks
  useEffect(() => {
    if (
      (id === undefined && !user.checkAction(ActionType.JourneyPage)) ||
      (id !== undefined && !user.checkUserRole(userRole.value, UserRoleActionType.JourneyPage)) ||
      (!isAllCountry && user.c !== country.value)
    ) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    } else {
      load();
    }
  }, [vasScoreFilter, treatmentFilter]);

  // Functions
  const load = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let result = {};
    let userDetailRequest = userFilter.isMyData ? userMyDetail : userDetail;
    await userDetailRequest(userFilter, (status, res) => {
      if (status) {
        result.user = res.data;
      }
    });

    if (!result.user) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    }

    let [userParentResponse, vasScoreResponse, treatmentResponse, visitResponse, conferenceResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        let userParentRequest = userFilter.isMyData ? userRelationMyList : userRelationList;
        userParentRequest(userRelationFilter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
      new Promise((resolve, reject) => {
        if (result.user.actions.some((a) => a === ActionType.HomeWidgetVasScore)) {
          let vasScoreRequest = vasScoreFilter.isMyData ? vasScoreMyList : vasScoreList;
          vasScoreRequest(vasScoreFilter, (status, res) => {
            if (status) {
              resolve(res);
            } else {
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      }),
      new Promise((resolve, reject) => {
        if (result.user.actions.some((a) => a === ActionType.TreatmentList)) {
          let treatmentRequest = treatmentFilter.isMyData ? treatmentMyList : treatmentList;

          treatmentRequest(treatmentFilter, (status, res) => {
            if (status) {
              resolve(res);
            } else {
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      }),
      new Promise((resolve, reject) => {
        if (result.user.actions.some((a) => a === ActionType.VisitList)) {
          let visitRequest = visitFilter.isMyData ? visitMyList : visitList;
          visitRequest(visitFilter, (status, res) => {
            if (status) {
              resolve(res);
            } else {
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      }),
      new Promise((resolve, reject) => {
        if (result.user.actions.some((a) => a === ActionType.ConferenceList)) {
          conferenceList(conferenceFilter, (status, res) => {
            if (status) {
              resolve(res);
            } else {
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      }),
    ]);

    if (userParentResponse?.data) {
      result.parents = userParentResponse.data;
    }

    if (vasScoreResponse?.data) {
      result.vasScores = vasScoreResponse.data.map((x) => ({ ...x, modificationTime: moment(x.modificationTime).add(user.t, 'minutes').format('YYYY-MM-DD') }));
    }

    if (visitResponse?.data) {
      result.visits = visitResponse.data.map((x) => {
        x.date = moment(x.date).add(user.t, 'minutes').format('YYYY-MM-DD');
        x.reasonData = VisitReasonTypes.find((f) => f.value === x.reason);

        return x;
      });
    }

    if (treatmentResponse?.data) {
      let ongoingTreatments = treatmentResponse.data.map((x) => {
        if (x.suspendTime) {
          x.usages = x.usages.filter((u) => moment(x.suspendTime).add(user.t, 'minutes') > moment(u.time).add(user.t, 'minutes'));
        }

        x.usages = x.usages.map((u) => ({ ...u, drugData: DrugTypes.find((d) => d.value === x.drug) }));

        return x;
      });

      let usages = ongoingTreatments.flatMap((x) => x.usages);
      result.treatments = usages.map((x) => ({ ...x, time: moment(x.time).add(user.t, 'minutes').format('YYYY-MM-DD') }));
    }

    if (conferenceResponse?.data) {
      result.conferences = conferenceResponse.data.map((x) => {
        x.date = moment(x.date).add(user.t, 'minutes').format('YYYY-MM-DD');
        x.status = ConferenceStatusTypes.find((c) => c.value === x.status).displayName;
        return x;
      });
    }

    setResponse(result);
    setPageState((x) => ({ ...x, list: true }));
  };

  // Events
  const treatmentUsageClick = (row) => {
    if (moment() > moment(row.time) && treatmentFilter.isMyData) {
      setRowTreatment(row);
      setPanelVisibility(true);
    }
  };

  const treatmentUseClick = async (treatmentUsageStatusType) => {
    rowTreatment.status = treatmentUsageStatusType;

    await treatmentUsageStatusUpdate(rowTreatment, (status, res) => {
      if (status) {
        load();
        setPanelVisibility(false);
      }
    });
  };

  const conferenceListClick = () => {
    navigate(`/conference/list`);
  };

  // Components
  const GetDrugValue = ({ usage }) => {
    switch (usage.drugData.value) {
      case DrugType.Enspryng:
        return <Value>{usage.dailyDoseMg}mg</Value>;

      default:
        return (
          <Value>
            {usage.dailyDoseMg}mg/{usage.dailyDoseMl}ml
          </Value>
        );
    }
  };

  const dateCellRender = (value) => {
    let vasScores = response.vasScores?.filter((x) => value.isSame(x.modificationTime, 'day'));
    let treatments = response.treatments?.filter((x) => value.isSame(x.time, 'day'));
    let visits = response.visits?.filter((x) => value.isSame(x.date, 'day'));
    let conferences = response.conferences?.filter((x) => value.isSame(x.date, 'day'));

    return (
      <Events>
        {response.user.actions.some((a) => a === ActionType.HomeWidgetVasScore) &&
          vasScores?.map((x, i) => (
            <Event key={`event-${i}`}>
              <Tooltip placement="topLeft" title={format(t('VasScorePoint'), t(x.point))}>
                <VasScoreContainer>
                  <Title>{t('VasScore')}</Title>
                  <Value>{x.point}</Value>
                </VasScoreContainer>
              </Tooltip>
            </Event>
          ))}

        {response.user.actions.some((a) => a === ActionType.VisitList) &&
          visits?.map((x, i) => (
            <Event key={`event-${i}`}>
              <Tooltip placement="topLeft" title={format(t('VisitStatus'), t(x.reasonData.displayName))}>
                <VisitContainer>
                  <Title>{t(x.reasonData.displayName)}</Title>
                </VisitContainer>
              </Tooltip>
            </Event>
          ))}

        {response.user.actions.some((a) => a === ActionType.ConferenceList) &&
          conferences?.map((x, i) => (
            <Event key={`event-${i}`}>
              <Tooltip placement="topLeft" title={format(t('EventStatus'), x.status)}>
                <ConferenceContainer onClick={() => conferenceListClick()}>
                  <User>{t(x.user.fullName)}</User>
                  <TargetUser>{t(x.targetUser.fullName)}</TargetUser>
                </ConferenceContainer>
              </Tooltip>
            </Event>
          ))}

        {response.user.actions.some((a) => a === ActionType.TreatmentList) &&
          treatments?.map((x, i) => (
            <Event key={`event-${i}`}>
              <Tooltip placement="topLeft" title={format(t('DailyDoseUsage'), x.dailyDoseMg)}>
                <TreatmentUsageContainer status={x.status} onClick={() => treatmentUsageClick(x)}>
                  <Title>{t(x.drugData.displayName)}</Title>
                  <GetDrugValue usage={x} />
                </TreatmentUsageContainer>
              </Tooltip>
            </Event>
          ))}
      </Events>
    );
  };

  // Render
  return (
    pageState?.list && (
      <Page
        title={userFilter?.isMyData ? t('Journey') : response.user.fullName}
        icon={<GiMedicines />}
        routes={[
          {
            name: t('Journey'),
            breadcrumbName: t('Journey'),
            icon: <GiMedicines />,
          },
        ]}
        extra={[
          response.parents && (
            <ParentSection key="relation-doctor">
              <ParentTitle>
                <FaUserMd /> {t('RelationDoctorNames')}
              </ParentTitle>
              <Parents>
                {response.parents.map((x) => (
                  <Parent key={x.id}>{x.user.fullName}</Parent>
                ))}
              </Parents>
            </ParentSection>
          ),
        ]}
      >
        {pageState?.list && <Calendar dateCellRender={dateCellRender} />}

        {response.user.actions.some((a) => a === ActionType.VisitList) && <VisitList visitFilter={visitFilter} />}

        {response.user.actions.some((a) => a === ActionType.ContentPage) && <ContentUserList contentUserFilter={contentUserFilter} />}

        {response.user.actions.some((a) => a === ActionType.HomeWidgetVasScore) && (
          <VasScoreSection>
            {vasScoreFilter.isMyData && user.checkAction(ActionType.HomeWidgetVasScore) && (
              <VasScore country={vasScoreFilter.country} userRole={vasScoreFilter.userRole} id={id} load={load} />
            )}
            <VasScoreChart initialData={response.vasScores} fullWidth={!vasScoreFilter.isMyData} />
          </VasScoreSection>
        )}

        <Modal width={320} title={t('TreatmentUsage')} visible={panelVisibility} onCancelClick={() => setPanelVisibility(false)} footer={null}>
          <Button onClick={() => treatmentUseClick(TreatmentUsageStatusType.Used)} templates={['colored']} block={true}>
            {t('Used')}
          </Button>
          <p></p>
          <Button onClick={() => treatmentUseClick(TreatmentUsageStatusType.NotUsed)} templates={['colored']} block={true}>
            {t('NotUsed')}
          </Button>
        </Modal>
      </Page>
    )
  );
}

const ParentSection = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

const ParentTitle = styled.div`
  font-weight: bold;
  text-align: right;
  font-size: 20px;
`;

const Parents = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Parent = styled.div`
  margin-left: 5px;
`;

const VasScoreSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

const Events = styled.div`
  height: 100%;
  width: 100%;
`;

const Event = styled.div`
  min-height: 25px;
`;

const VasScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  background-color: ${(x) => x.theme.colors.mediumSpringGreen};
  color: ${(x) => x.theme.colors.white};
  border-radius: 10px;
  margin-bottom: 10px;
`;

const VisitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  background-color: ${(x) => x.theme.colors.rustyRed};
  color: ${(x) => x.theme.colors.white};
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ConferenceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 6px;
  background-color: ${(x) => x.theme.colors.lightBlue};
  color: ${(x) => x.theme.colors.white};
  border-radius: 10px;
  margin-bottom: 10px;
`;

const TreatmentUsageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  color: ${(x) => x.theme.colors.white};
  border-radius: 10px;
  margin-bottom: 10px;

  ${({ status }) => {
    switch (status) {
      case TreatmentUsageStatusType.NotUsed:
        return css`
          background-color: ${(x) => x.theme.colors.rustyRed};
        `;
      case TreatmentUsageStatusType.Used:
        return css`
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
        `;
      case TreatmentUsageStatusType.Pending:
        return css`
          background-color: ${(x) => x.theme.colors.darkTurquoise};
        `;
      default:
        return css`
          background-color: ${(x) => x.theme.colors.deepKaomaru};
        `;
    }
  }}
`;

const Title = styled.div``;

const Value = styled.div``;

const User = styled.div``;

const TargetUser = styled.div``;
