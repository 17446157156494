import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { ActionType, ContentColumns, ContentType, ContentTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { contentList, contentMultipleDeleteRestore, contentUpdateActivity } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { updateResponse } from '../../../utilies/stateManagement';
import { Button, Drawer, Extra, Section, Select, Table } from '../../globals';
import ContentForm from './ContentForm';

export default function ContentList({ parentData }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formPanelVisibility, setFormPanelVisibility] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['row|asc'],
    userCountry: user.c,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  useEffect(() => {
    if (parentData) {
      setFilter((x) => ({ ...x, parentId: parentData.id }));
    }
  }, [parentData]);

  // Functions
  const list = async () => {
    if (!filter.parentId) return;

    setFormPanelVisibility(false);
    setPageState((x) => ({ ...x, list: false }));

    await contentList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  const getContentTypes = () => {
    if (parentData === undefined) return ContentTypes.filter((x) => x.group === 'Parent');

    switch (parentData.type) {
      case ContentType.Survey:
      case ContentType.Course:
        return ContentTypes.filter((x) => x.value === ContentType.Question);

      case ContentType.Question:
        return ContentTypes.filter((x) => x.value === ContentType.Answer);

      default:
        return ContentTypes.filter((x) => x.group === 'Parent');
    }
  };

  // Events
  const onCreateClick = () => {
    setRowData(null);
    formRef.resetFields();
    setFormPanelVisibility(true);

    if (parentData) {
      let defaultInnerData = { ...parentData, id: null, parentId: parentData.id, type: null, title: null, url: null, description: null, body: null, row: 0 };

      formRef.setFieldsValue(defaultInnerData);
    }
  };

  const onUpdateClick = (row) => {
    setRowData(row);
    setFormPanelVisibility(true);
  };

  const onActivityChange = async (val, row) => {
    setPageState((x) => ({ ...x, update: false }));
    setResponse(updateResponse(response, 'isActive', val, true, row));

    await contentUpdateActivity({ id: row.id, isActive: val, country: row.country }, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setPageState((x) => ({ ...x, update: true }));
      setResponse(updateResponse(response, 'isActive', val, false, row));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await contentMultipleDeleteRestore({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  // Render
  return (
    <Section
      pageState={pageState}
      setPageState={setPageState}
      headerLeft={[
        <Title key="title">
          {t('InnerContents')}
          {user.checkAction(ActionType.ContentCreate) && (
            <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
              {t('AddNew')}
            </Button>
          )}
        </Title>,
      ]}
      headerRight={[
        <Select
          key="content-type-filter"
          data={getContentTypes().map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(contentType) => setFilter((x) => ({ ...x, contentType }))}
          placeholder={t('SelectContentType')}
          defaultValue={filter.contentType}
          value={filter.contentType}
          templates={['filter']}
        />,
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          activityFilter={true}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.ContentDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={ContentColumns({
          load: list,
          onUpdateClick: user.checkAction(ActionType.ContentUpdate) && onUpdateClick,
          onActivityChange: user.checkAction(ActionType.ContentUpdate) && onActivityChange,
        })}
      />

      {parentData && (
        <Drawer width={'80%'} visible={formPanelVisibility} onClose={() => setFormPanelVisibility(false)}>
          <ContentForm id={rowData?.id} formRef={formRef} parentId={parentData.id} parentContentType={parentData.type} load={list} />

          <Button key="confirm" onClick={() => formRef.submit()} templates={['colored']}>
            {t('Confirm')}
          </Button>
        </Drawer>
      )}
    </Section>
  );
}

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;
