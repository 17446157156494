import { Collapse } from 'antd';
import { useState } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import styled, { css } from 'styled-components';

const { Panel } = Collapse;

export default function CollapseBase({ panels }) {
  const [activeKeys, setActiveKeys] = useState([]);

  const onPanelClick = (key) => {
    if (activeKeys?.some((x) => x === key)) {
      setActiveKeys(activeKeys?.filter((y) => y !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  return (
    <Container activeKey={activeKeys}>
      {panels.map((x, i) => {
        let panelKey = `panel-${i}`;
        return (
          <ContainerPanel
            key={panelKey}
            showArrow={false}
            header={
              <PanelHeaderContainer onClick={(e) => e.stopPropagation()} arrow={x.showArrow ?? true ? 'show' : ''}>
                {x.showArrow && <CollapseArrow status={activeKeys?.some((x) => x === panelKey) ? 1 : 0} />}

                <PanelHeader onClick={() => onPanelClick(panelKey)}>{x.headers.map((h) => h)}</PanelHeader>
                <PanelExtra>{x.extras.map((e) => e)}</PanelExtra>
              </PanelHeaderContainer>
            }
          >
            {x.children}
          </ContainerPanel>
        );
      })}
    </Container>
  );
}

const Container = styled(Collapse)``;

const ContainerPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0px !important;
  }
`;

const PanelHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PanelHeader = styled.div`
  padding: 10px;
  width: 100%;
`;

const PanelExtra = styled.div`
  padding: 10px;
`;

const CollapseArrow = styled(FaCaretRight)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  ${(x) =>
    x.status &&
    css`
      transform: rotate(90deg);
    `}
`;
