import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import jwtDecode from 'jwt-decode';
import { initReactI18next } from 'react-i18next';
import { getToken } from './services/cache';
import { getApiUrl } from './services/config';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en', 'ru', 'es', 'sr', 'tw'],
    load: 'unspecific',
    backend: {
      loadPath: () => {
        let token = getToken();
        let user = token && jwtDecode(token);

        return `${getApiUrl()}/localization/list/{{lng}}/${user?.c ?? ''}`;
      },
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
