import moment from 'moment';

export const evrysdiCalculator = (weight, duration, months) => {
  let miligram = 0;
  let mililiter = 0;
  let bottle = 0;
  let days = 0;

  if (weight > 0 && duration > 0 && months > 0) {
    if (months < 24) {
      miligram = parseFloat(0.2 * weight);
    } else {
      if (weight < 20) {
        miligram = parseFloat(0.25 * weight);
      } else {
        miligram = parseFloat(5 * weight);
      }
    }
    if (miligram > 5) miligram = 5;

    days = moment().add(duration, 'months').diff(moment(), 'days', true);

    bottle = Math.ceil(days / (60 / miligram));

    mililiter = miligram / 0.75;
    if (mililiter > 6.5) mililiter = 6.6;

    mililiter = mililiter.toFixed(2);
    miligram = miligram.toFixed(2);
    days = days.toFixed(0);
  }

  return {
    miligram,
    mililiter,
    days,
    bottle,
  };
};
