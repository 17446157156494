import { Tooltip } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AiFillPhone } from 'react-icons/ai';
import { FaCheckCircle, FaClock, FaPhoneSlash } from 'react-icons/fa';
import { ImBlocked } from 'react-icons/im';
import styled from 'styled-components';
import { Button } from '../../components/globals';
import { ConferenceStatusType, ConferenceStatusTypes, ConferenceType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import theme from '../../styles/theme';
import ColumnBase from './_ColumnBase';

export default function ConferenceColumns({ isDeleted, onJoinClick }) {
  const { t } = useTranslation();
  const [user] = useCacheUser();

  let columns = [
    {
      locale: 'CoferenceSender',
      name: 'userId',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={row.user?.fullName}>
          {row.user?.fullName}
        </Tooltip>
      ),
    },
    {
      locale: 'CoferenceRecipient',
      name: 'targetUserId',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={row.targetUser?.fullName}>
          {row.targetUser?.fullName}
        </Tooltip>
      ),
    },
    {
      locale: 'CoferenceDate',
      name: 'date',
      type: 'datetime',
      render: (value, row) => {
        let conferenceData = JSON.parse(row.data);
        let conferenceTime = moment(row.date ?? conferenceData.Dates[0])
          .add(user.t, 'minutes')
          .format('llll');

        return (
          <Tooltip
            placement="topLeft"
            title={
              <DateList>
                {conferenceData.Dates.map((x, i) => (
                  <DateItem key={`date-${i}`}>{moment(x).add(user.t, 'minutes').format('llll')}</DateItem>
                ))}
              </DateList>
            }
          >
            {conferenceTime} ({conferenceData.Dates.length})
          </Tooltip>
        );
      },
    },
    {
      locale: 'ConferenceType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let selectedDate = moment(row.date ?? row.dates.Dates[0]).add(user.t, 'minutes');
        if (moment() > selectedDate && row.status === ConferenceStatusType.Pending) {
          return (
            <Tooltip placement="topLeft" title={t('Expired')}>
              {t('Expired')}
            </Tooltip>
          );
        }

        return (
          <Tooltip placement="topLeft" title={t(row.conferenceTypeData?.displayName)}>
            {t(row.conferenceTypeData?.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'ConferenceStatus',
      name: 'status',
      type: 'string',
      render: (value, row) => {
        let status = ConferenceStatusTypes.find((x) => x.value === row.status);

        let selectedDate = moment(row.date ?? row.dates.Dates[0]).add(user.t, 'minutes');
        if (moment() > selectedDate && row.status === ConferenceStatusType.Pending) {
          return (
            <Tooltip placement="topLeft" title={t('Expired')}>
              {t('Expired')}
            </Tooltip>
          );
        }

        return (
          <Tooltip placement="topLeft" title={t(status.displayName)}>
            {t(status.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    !isDeleted &&
      onJoinClick && {
        locale: 'Join',
        align: 'center',
        width: 120,
        render: (v, row) => {
          let selectedDate = moment(row.date ?? row.dates.Dates[0]).add(user.t, 'minutes');
          if (moment() > selectedDate && row.status === ConferenceStatusType.Pending) {
            return <ImBlocked style={{ fontSize: 20, color: theme.colors.darkTurquoise }} />;
          }

          switch (row.conferenceType) {
            case ConferenceType.Available:
              return (
                <Button key="join" onClick={() => onJoinClick(row)} templates={['table']}>
                  <AiFillPhone />
                </Button>
              );

            case ConferenceType.Completed:
              return <FaCheckCircle style={{ fontSize: 20, color: theme.colors.darkTurquoise }} />;

            case ConferenceType.Rejected:
              return <FaPhoneSlash style={{ fontSize: 20, color: theme.colors.darkTurquoise }} />;

            default:
              return <FaClock style={{ fontSize: 20, color: theme.colors.darkTurquoise }} />;
          }
        },
      },
  ];

  return ColumnBase({ columns });
}

const DateList = styled.div``;

const DateItem = styled.div``;
