const TemplateType = {
  CreatePassword: 0,
  ForgotPassword: 1,
  AskForHelp: 2,
  AdverseEvent: 3,
  MissedDose: 4,
  DoseRemind: 5,
  UserInvite: 6,
  DeliveryStep: 7,
  DeliveryReject: 8,
  LoginTemplate: 9
};

export default TemplateType;