export const convertEvrysdiMlToMg = (value) => {
  let mililiter = value >= 6.6 ? 6.6 : value;
  let miligram = mililiter * 0.75;

  return (miligram > 5 ? 5 : miligram).toFixed(1);
};

export const convertEvrysdiMgToMl = (value) => {
  let miligram = value >= 5 ? 5 : value;
  let mililiter = miligram / 0.75;

  return (mililiter > 6.5 ? 6.5 : mililiter).toFixed(1);
};

export const convertMlToMg = (value) => {
  return (value * 0.75).toFixed(1);
};

export const convertMgToMl = (value) => {
  return (value / 0.75).toFixed(1);
};
