import { Tabs } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import ContentList from '../../../components/pages/content/ContentList';
import StoragePanel from '../../../components/pages/storage/StoragePanel';
import { ActionType, ContentInputs, ContentType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { contentCreate, contentDetail, contentUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import slugify from '../../../utilies/slugify';
import { Card, Drawer, Element } from '../../globals';

const { TabPane } = Tabs;

export default function ContentForm({ formRef, id, parentId, parentContentType, load }) {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [user] = useCacheUser();

  const isAllCountry = user.checkAction(ActionType.ContentAllCountry);

  const [response, setResponse] = useState(null);
  const [isCreate, setIsCreate] = useState(id === undefined);
  const [contentType, setContentType] = useState(ContentType.Article);
  const [pageState, setPageState] = useState({ form: true });
  const [storagePanelVisiblity, setStoragePanelVisibility] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState(null);

  const [filter, setFilter] = useState({
    id,
    includeCountries: true,
    includeUserRoles: true,
    includeDiseases: true,
    includeTherapeuticAreas: true,
    userCountry: user.c,
  });

  // Hooks
  useEffect(() => {
    setFilter((x) => ({ ...x, id }));
    setIsCreate(id === undefined);
  }, [id]);

  useEffect(() => {
    if (filter.id) {
      detail();
    }
  }, [filter]);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, form: false }));

    await contentDetail(filter, (status, res) => {
      if (status) {
        setContentType(res.data.type);

        if (res.data.expireTime) {
          res.data.expireTime = moment(res.data.expireTime).add(user.t, 'minutes');
        }
        if (res.data.contentTime) {
          res.data.contentTime = moment(res.data.contentTime).add(user.t, 'minutes');
        }

        setResponse(res);

        formRef.setFieldsValue(res.data);
      }
      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Events
  const onContentTypeChange = ({ value }) => {
    setContentType(value);
  };

  const onTitleChange = () => {
    if (!isCreate) return;

    let title = formRef.getFieldValue('title');
    formRef.setFieldValue('url', slugify(title));
  };

  const onContentFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let request = isCreate ? contentCreate : contentUpdate;

    model.userCountry = user.c;
    await request(model, (status, res) => {
      if (status) {
        if (load) {
          load();
        }
        if (filter.id) {
          detail();
        }

        setStoragePanelVisibility(false);
        notifySuccess(t(isCreate ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));

        if (!parentId) {
          setTimeout(() => navigate(`/content/update/${res.data}`), 500);
        }
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onChangeClick = (name) => {
    setSelectedImageName(name);
    setStoragePanelVisibility(true);
  };

  const onDeleteClick = (name) => {
    setPageState((x) => ({ ...x, delete: false }));
    formRef.setFieldValue(name, null);
    setPageState((x) => ({ ...x, delete: true }));
  };

  const onSelectImageClick = (file) => {
    formRef.setFieldValue(selectedImageName, file.url);
    setStoragePanelVisibility(false);
  };

  // Render
  return (
    <Container type={parentId !== undefined ? 'drawer' : ''}>
      <Card ready={pageState.form} templates={['page']}>
        <ContentTabs type={isCreate ? 'create' : 'update'}>
          <TabPane tab={t('Detail')} key="detail" forceRender={true}>
            <Element
              formRef={formRef}
              inputs={ContentInputs({
                country: filter.userCountry,
                isAllCountry,
                parentId,
                parentContentType,
                contentType,
                isCreate,
                onContentTypeChange,
                onTitleChange,
                onChangeClick,
                onDeleteClick,
              })}
              onFinish={onContentFormFinish}
              columnSize={12}
            />
          </TabPane>
          {!isCreate && contentType !== ContentType.Answer && (
            <TabPane tab={t('InnerContents')} key="items" forceRender={true}>
              <ContentList parentData={response?.data} />
            </TabPane>
          )}
        </ContentTabs>
      </Card>

      <Drawer width={'100%'} visible={storagePanelVisiblity} onClose={() => setStoragePanelVisibility(false)} templates={['storage']}>
        {storagePanelVisiblity && <StoragePanel onSelectImageClick={onSelectImageClick} />}
      </Drawer>
    </Container>
  );
}

const Container = styled.div`
  ${({ type }) =>
    type === 'drawer' &&
    css`
      .ant-card-bordered {
        border: 0px;
        .ant-card-body {
          padding: 0px;
        }
      }
    `}
`;

const ContentTabs = styled(Tabs)`
  ${({ type }) =>
    type === 'create' &&
    css`
      .ant-tabs-nav {
        display: none;
      }
    `}

  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        color: ${(x) => x.theme.colors.darkTurquoise};
        &:hover {
          color: ${(x) => x.theme.colors.darkTurquoise};
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${(x) => x.theme.colors.mediumSpringGreen};
          }
        }
      }

      .ant-tabs-ink-bar {
        background: ${(x) => x.theme.colors.mediumSpringGreen};
      }
    }
  }
`;
