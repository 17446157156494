import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { HiTemplate } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import { Button, Drawer, Element, Page, Select, Table } from '../../components/globals';
import { ActionType, TemplateColumns, TemplateGroupTypes, TemplateInputs, TemplateTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { templateCreate, templateList } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';

export default function Templates() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [templateFormRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [templateType, setTemplateType] = useState(null);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await templateList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    templateFormRef.resetFields();
    setPanelVisibility(true);
  };

  const onTemplateTypeChange = ({ value }) => {
    setTemplateType(value);
  };

  const onUpdateClick = (row) => {
    setPageState((x) => ({ ...x, update: false }));
    navigate(`/template/update/${row.id}`);
  };

  const onTemplateFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model.content = '-';
    await templateCreate(model, (status, res) => {
      if (status) {
        setPanelVisibility(false);
        notifySuccess(t('CreateCompleteSuccess'));
        list();
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Page
      title={t('Templates')}
      icon={<HiTemplate />}
      subTitle={
        user.checkAction(ActionType.TemplateCreate) && (
          <Button ready={pageState?.create} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Templates'),
          breadcrumbName: t('Templates'),
          icon: <HiTemplate />,
        },
      ]}
      templates={['default-2-filter-0']}
      extra={[
        <Select
          key="type-filter"
          data={TemplateTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(type) => setFilter((x) => ({ ...x, type }))}
          placeholder={t('SelectTemplateType')}
          value={filter.type}
          templates={['filter']}
        />,
        <Select
          key="group-filter"
          data={TemplateGroupTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(group) => setFilter((x) => ({ ...x, group }))}
          placeholder={t('SelectTemplateGroup')}
          value={filter.group}
          templates={['filter']}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={TemplateColumns({ onUpdateClick: user.checkAction(ActionType.TemplateUpdate) && onUpdateClick })}
      />

      <Drawer title={t('AddNew')} visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
        <Element
          key="template-form"
          ready={pageState.create}
          formRef={templateFormRef}
          onFinish={onTemplateFormFinish}
          inputs={TemplateInputs({ templateType, onTemplateTypeChange })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
