import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillPhone } from 'react-icons/ai';
import { FaCaretRight, FaCheckCircle, FaPhoneSlash } from 'react-icons/fa';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { ConferenceStatusType, ConferenceType, ConferenceTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { conferenceJoin, conferenceList } from '../../../services/api';
import { combineOnlineUsers } from '../../../utilies/conferenceHelper';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Button, Card, Link } from '../../globals';

export default function Conference() {
  // Definitions
  let onlineUserTimeout;
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const location = useLocation();

  const [state, dispatch] = useContext(ReduceContext);
  const [data, setData] = useState(null);
  const [pageState, setPageState] = useState({ detail: false, form: true });

  const [filter] = useState({
    pageNumber: 1,
    dataLimit: 5,
    isDeleted: false,
    isActive: true,
    orderBy: ['date|asc'],
    minDate: moment().add(-1, 'hours').format(),
    currentUserId: user?.i,
    includeUser: true,
    includeTargetUser: true,
    status: ConferenceStatusType.Approved,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  useEffect(() => {
    if (state?.conference?.list) {
      list();
      dispatch({ ...state, conference: { list: null } });
    }
  }, [state?.conference?.list]);

  useEffect(() => {
    clearTimeout(onlineUserTimeout);

    if (state?.online?.users?.length && data?.length) {
      setTimeout(() => {
        setData(combineOnlineUsers(data, user, state.online.users));
      }, 1000);
    }
  }, [state?.online?.users, pageState]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, detail: false }));

    await conferenceList(filter, (status, res) => {
      if (status) {
        let result = combineOnlineUsers(
          res.data.map((x) => ({
            ...x,
            dates: JSON.parse(x.data),
            conferenceType: ConferenceType.Pending,
            conferenceTypeData: ConferenceTypes.find((x) => x.value === ConferenceType.Pending),
          })),
          user,
          state?.online?.users
        );
        setData(result);
      }
      setPageState((x) => ({ ...x, detail: true }));
    });
  };

  // Events
  const onJoinClick = async (row) => {
    await conferenceJoin(row, (status, res) => {
      if (status) {
        dispatch({ ...state, conference: { channel: row.id, token: res.data.item1, applicationId: res.data.item2 } });
      }
    });
  };

  // Components
  const ConferenceAction = ({ row }) => {
    switch (row.conferenceType) {
      case ConferenceType.Available:
        return (
          <Button key="join" onClick={() => onJoinClick(row)} templates={['table']}>
            <AiFillPhone />
          </Button>
        );

      case ConferenceType.Completed:
        return <FaCheckCircle />;

      case ConferenceType.Rejected:
        return <FaPhoneSlash />;

      default:
        return <LoadingOutlined />;
    }
  };

  // Render
  return (
    <Card
      ready={pageState.detail}
      templates={['widget', 'conference', 'with-header', 'header-information', 'header-colored']}
      information={t('ConferenceInformation')}
      title={t('Conferences')}
      right={
        !location.pathname.includes('conference/list') && (
          <Link to="/conference/list">
            <FaCaretRight />
          </Link>
        )
      }
    >
      {data?.length > 0 ? (
        <List length={data.length}>
          {data.map((x, i) => {
            let conferenceData = JSON.parse(x.data);
            let conferenceTime = moment(x.date ?? conferenceData.Dates[0])
              .add(user.t, 'minutes')
              .format('llll');

            return (
              <ListItem key={`item-${i}`}>
                <User>{x.user.fullName}</User>
                <Detail>
                  <Date>
                    <Tooltip
                      placement="topLeft"
                      title={
                        <DateList>
                          {conferenceData.Dates.map((x, i) => (
                            <DateItem key={`date-${i}`}>{moment(x).add(user.t, 'minutes').format('llll')}</DateItem>
                          ))}
                        </DateList>
                      }
                    >
                      {conferenceTime} ({conferenceData.Dates.length})
                    </Tooltip>
                  </Date>
                </Detail>
                <Action>
                  <ConferenceAction row={x} />
                </Action>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <NotFound>{t('HomeConferenceNotFound')}</NotFound>
      )}
    </Card>
  );
}

const List = styled.div`
  padding: 10px;
  height: 100%;
  ${({ length }) =>
    length > 5 &&
    css`
      overflow-y: scroll;
    `}
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  position: relative;
  padding-left: 60px;
  padding-right: 5px;
  border: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
  border-radius: ${(x) => x.theme.global.borderRadius};
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Date = styled.div``;

const DateList = styled.div``;

const DateItem = styled.div``;

const Action = styled.div`
  left: 5px;
  position: absolute;
`;

const NotFound = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
