const NotificationTypes = [
  {
    value: 0,
    name: 'ConferenceScheduled',
    displayName: 'ConferenceScheduled',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'ConferenceScheduledApproved',
    displayName: 'ConferenceScheduledApproved',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'ConferenceScheduledRejected',
    displayName: 'ConferenceScheduledRejected',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'ConferenceJoinUser',
    displayName: 'ConferenceJoinUser',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'DeliveryStep',
    displayName: 'DeliveryStepChange',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  },
  {
    value: 5,
    name: 'DeliveryRejected',
    displayName: 'DeliveryRejected',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  }
];

export default NotificationTypes;