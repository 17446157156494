import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeItem } from '../../services/cache';

export default function UserLogout() {
  // Definitions
  const navigate = useNavigate();

  // Hooks
  useEffect(() => {
    removeItem('tkn');
    setTimeout(() => navigate('/login'), 500);
  }, []);

  // Render
  return <></>;
}
