import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { HiUsers } from 'react-icons/hi';
import { RiEdit2Fill } from 'react-icons/ri';
import { TiCancel } from 'react-icons/ti';
import { Button } from '../../components/globals';
import { DeliveryStatusType, DeliveryStatusTypes, DrugTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function DeliveryColumns({ onDeliveryUserClick, onUpdateClick, onRejectDelivery }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'Reference',
      name: 'reference',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Drug',
      name: 'drug',
      type: 'string',
      render: (value, row) => {
        let drug = DrugTypes.find((x) => x.value === value);
        return (
          <Tooltip placement="topLeft" title={t(drug.displayName)}>
            {t(drug.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'Status',
      name: 'status',
      type: 'string',
      render: (value, row) => {
        let status = DeliveryStatusTypes.find((x) => x.value === value);
        return (
          <Tooltip placement="topLeft" title={t(status.displayName)}>
            {t(status.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'DeliverySender',
      name: 'user',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value.fullName}>
          {value.fullName}
        </Tooltip>
      ),
    },
    {
      locale: 'DeliveryRecipient',
      name: 'targetUser',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value.fullName}>
          {value.fullName}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onDeliveryUserClick && {
      locale: 'DeliveryUsers',
      align: 'center',
      width: 120,
      render: (v, row) =>
        row.status !== DeliveryStatusType.Rejected && (
          <Button key="delivery-user" onClick={() => onDeliveryUserClick(row)} templates={['table']}>
            <HiUsers />
          </Button>
        ),
    },
    onRejectDelivery && {
      locale: 'Reject',
      align: 'center',
      width: 120,
      render: (v, row) =>
        row.status !== DeliveryStatusType.Rejected && (
          <Button key="reject" onClick={() => onRejectDelivery(row)} templates={['table']}>
            <TiCancel />
          </Button>
        ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) =>
        row.status !== DeliveryStatusType.Rejected && (
          <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
            <RiEdit2Fill />
          </Button>
        ),
    },
  ];

  return ColumnBase({ columns });
}
