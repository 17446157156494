import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTruck } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { format } from 'react-string-format';
import { Button, Drawer, Element, Page, Select, Table } from '../../components/globals';
import DeliverySteps from '../../components/pages/delivery/DeliverySteps';
import DeliveryUser from '../../components/pages/delivery/DeliveryUser';
import { ActionType, CountryTypes, DeliveryColumns, DeliveryStatusType, DeliveryStatusTypes, DrugTypes, UserRoleActionType, UserRoleType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { deliveryList, deliveryReject, userList } from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function Deliveries() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [formRef] = Form.useForm();

  const isAllCountry = user.checkAction(ActionType.DeliveryAllCountry);
  const isAll = user.checkAction(ActionType.DeliveryAll);

  const [response, setResponse] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [users, setUsers] = useState([]);

  const [stepPanelVisiblity, setStepPanelVisibility] = useState(false);
  const [userPanelVisibility, setUserPanelVisibility] = useState(false);
  const [rejectPanelVisibility, setRejectPanelVisibility] = useState(false);
  const [createPanelVisibility, setCreatePanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
    reject: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
    includeUser: true,
    includeTargetUser: true,
    targetUserId: isAll ? null : user.i,
    status: DeliveryStatusType.Pending,
  });

  const [userFilter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: user?.c,
    userRole: UserRoleType.Patient,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    if (!user?.dd) {
      notifyError(t('RoleDeliveryDrugNotFoundException'));
      navigate(`/`);
      return;
    }

    await deliveryList(filter, (status, res) => {
      if (status) {
        res.data = res.data.map((x) => {
          x.drugData = DrugTypes.find((f) => f.value === x.drug);
          x.statusData = DeliveryStatusTypes.find((f) => f.value === x.status);

          return x;
        });
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = async () => {
    await userList(userFilter, (status, res) => {
      if (status) {
        setUsers(res.data);
        setCreatePanelVisibility(true);
      }
    });
  };

  const onUpdateClick = async (row) => {
    setRowData(row);
    setStepPanelVisibility(true);
  };

  const onDeliveryUserClick = async (row) => {
    setRowData(row);
    setUserPanelVisibility(true);
  };

  const onRejectDelivery = async (row) => {
    setRowData(row);
    setRejectPanelVisibility(true);
  };

  const onRejectFinish = async (model) => {
    setPageState((x) => ({ ...x, reject: false }));

    model = { ...rowData, ...model, country: parseInt(user.c) };

    await deliveryReject(model, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('DeliveryRejected'));
        setRejectPanelVisibility(false);
      }
      setPageState((x) => ({ ...x, reject: true }));
    });
  };

  const onCreateFinish = (model) => {
    setPageState((x) => ({ ...x, create: false }));
    navigate(`/delivery/create/${UserRoleType.Patient}/${user.c}/${model.targetUserId}`);
  };

  // Render
  return (
    <Page
      title={t('Deliveries')}
      icon={<FaTruck />}
      subTitle={
        user.checkAction(ActionType.DeliveryCreate) && (
          <Button onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Deliveries'),
          breadcrumbName: t('Deliveries'),
          icon: <FaTruck />,
        },
      ]}
      extra={[
        <span key="filter-text">{t('Filter')}</span>,
        isAllCountry && (
          <Select
            key="country-filter"
            allowClear={false}
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
          />
        ),
        <Select
          key="status-filter"
          data={DeliveryStatusTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(status) => setFilter((x) => ({ ...x, status }))}
          value={filter.status}
          templates={['filter']}
          allowClear={false}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={DeliveryColumns({
          onUpdateClick: filter.status !== DeliveryStatusType.Rejected && onUpdateClick,
          onDeliveryUserClick:
            filter.status !== DeliveryStatusType.Rejected &&
            user.anyUserRoleAction(UserRoleActionType.List) &&
            user.checkAction(ActionType.DeliveryUser) &&
            onDeliveryUserClick,
          onRejectDelivery: filter.status !== DeliveryStatusType.Rejected && user.checkAction(ActionType.DeliveryReject) && onRejectDelivery,
        })}
      />

      <Drawer visible={stepPanelVisiblity} onClose={() => setStepPanelVisibility(false)}>
        {rowData && stepPanelVisiblity && <DeliverySteps country={filter.country} delivery={rowData} list={list} />}
      </Drawer>

      {rowData && <DeliveryUser visible={userPanelVisibility} onClose={() => setUserPanelVisibility(false)} delivery={rowData} />}

      <Drawer visible={rejectPanelVisibility} onClose={() => setRejectPanelVisibility(false)}>
        <Element
          key="reject"
          onFinish={onRejectFinish}
          ready={pageState.reject}
          formRef={formRef}
          inputs={[
            {
              type: 'textarea',
              name: 'rejectReason',
              label: t('RejectReason'),
              placeholder: t('RejectReasonEmpty'),
              rules: [
                { required: false, message: t('RejectReasonEmpty') },
                { min: 3, max: 50, message: format(t('LengthValid'), 1, 500) },
              ],
            },
          ]}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer visible={createPanelVisibility} onClose={() => setCreatePanelVisibility(false)}>
        <Element
          key="users"
          onFinish={onCreateFinish}
          ready={pageState.create}
          inputs={[
            {
              type: 'select',
              name: 'targetUserId',
              label: t('RolePatient'),
              placeholder: t('SelectRolePatient'),
              rules: [{ required: true, message: t('SelectRolePatientEmpty') }],
              data: users.map((x) => ({ value: x.id, text: x.fullName })),
            },
          ]}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>
    </Page>
  );
}
