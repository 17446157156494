import { Dropdown, Menu } from 'antd';
import { FaFlag } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { LanguageTypes } from '../../../constants';

export default function LanguageSelectBase({ type, onClick }) {
  const languageMenu = (
    <LanguageMenu
      type={type}
      onClick={onClick}
      items={LanguageTypes?.map((x, i) => ({
        key: x?.data?.code,
        label: x?.data?.code?.toUpperCase(),
      }))}
    />
  );

  return (
    <LanguageDropdown type={type} overlay={languageMenu}>
      <CurrentLanguage>
        <LanguageFlagIcon />
      </CurrentLanguage>
    </LanguageDropdown>
  );
}

const LanguageDropdown = styled(Dropdown)`
  color: black;
  cursor: pointer;

  ${({ type }) => {
    return (
      type === 'global' &&
      css`
        position: absolute;
        right: 10px;
        z-index: 9;
        color: ${(x) => x.theme.colors.white};
      `
    );
  }}
`;

const CurrentLanguage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 64px;
  height: 64px;
`;

const LanguageFlagIcon = styled(FaFlag)`
  color: ${(x) => x.theme.colors.darkTurquoiseHeader};
  font-size: 20px;
`;

const LanguageMenu = styled(Menu)`
  padding: 0px;
  border-radius: ${(x) => x.theme.global.borderRadius};
  overflow: hidden;

  ${({ type }) => {
    return (
      type === 'global' &&
      css`
        background-color: transparent;
        box-shadow: none;
        .ant-dropdown-menu-item {
          color: ${(x) => x.theme.colors.white};

          &:hover {
            background-color: transparent;
            font-weight: bold;
          }
        }
      `
    );
  }}

  .ant-dropdown-menu-item {
    text-align: center;
    min-height: 40px;
  }
`;
