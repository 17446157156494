import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, Switch } from '../../components/globals';
import {
  CountryType,
  DeliveryFlowStepNotificationGroupTypes,
  DeliveryFlowStepNotificationStatusTypes,
  DeliveryFlowStepNotificationTypes,
  TextlocalSmsTemplateTypes,
} from '../../constants';
import ColumnBase from './_ColumnBase';

export default function DeliveryFlowStepNotificationColumns({ country, onUpdateClick, onActivityChange }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'StepNotificationGroup',
      name: 'group',
      type: 'string',
      render: (value, row) => {
        let result = DeliveryFlowStepNotificationGroupTypes.find((x) => x.value === value);

        return (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'StepNotificationStatus',
      name: 'status',
      type: 'string',
      render: (value, row) => {
        let result = DeliveryFlowStepNotificationStatusTypes.find((x) => x.value === value);

        return (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'StepNotificationType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let result = DeliveryFlowStepNotificationTypes.find((x) => x.value === value);

        return (
          <Tooltip placement="topLeft" title={t(result.displayName)}>
            {t(result.displayName)}
          </Tooltip>
        );
      },
    },
    {
      locale: 'NameKey',
      name: 'nameKey',
      type: 'string',
      render: (value, row) =>
        value ? (
          <Tooltip placement="topLeft" title={t(value)}>
            {value}
          </Tooltip>
        ) : (
          'N/A'
        ),
    },
    {
      locale: 'DescriptionKey',
      name: 'descriptionKey',
      type: 'string',
      render: (value, row) =>
        value ? (
          <Tooltip placement="topLeft" title={t(value)}>
            {value}
          </Tooltip>
        ) : (
          'N/A'
        ),
    },
    {
      locale: 'DeliveryNotificationValue',
      name: 'value',
      type: 'string',
      render: (value, row) =>
        value ? (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
        ) : (
          'N/A'
        ),
    },
    {
      locale: 'DeliveryNotificationSmsTemplateType',
      name: 'smsTemplateTypeId',
      type: 'string',
      render: (value, row) => {
        if (country === CountryType.India) {
          let result = TextlocalSmsTemplateTypes.find((x) => x.value === value);
          if (result) {
            return (
              <Tooltip placement="topLeft" title={t(result.displayName)}>
                {t(result.displayName)}
              </Tooltip>
            );
          }
        }

        return 'N/A';
      },
    },
    onActivityChange && {
      locale: 'Activity',
      name: 'isActive',
      width: 120,
      render: (value, row) => <Switch key="isActive" checked={value} loading={row.loading} onChange={(val) => onActivityChange(val, row)} />,
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
