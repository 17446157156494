import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CountryType, DrugType } from '../../../constants';
import DeliveryUserDetail from './DeliveryUserDetail';

export default function DeliveryDetail({ response, onFinish }) {
  const { t } = useTranslation();

  const getDeliveryDrugPath = () => {
    switch (response.drug.value) {
      case DrugType.Evrysdi:
        switch (response.country.value) {
          case CountryType.India:
          case CountryType.Russian:
            return `${response.country.name.toLowerCase()}/${response.drug.name}`;

          default:
            return `Default`;
        }

      case DrugType.Enspryng:
        switch (response.country.value) {
          case CountryType.Russian:
            return `${response.country.name.toLowerCase()}/${response.drug.name}`;

          default:
            return `Default`;
        }

      case DrugType.Hemlibra:
        switch (response.country.value) {
          case CountryType.Bangladesh:
            return `${response.country.name.toLowerCase()}/${response.drug.name}`;

          default:
            return `Default`;
        }

      case DrugType.Pralsetinib:
      default:
        return `Default`;
    }
  };

  const DeliveryForm = lazy(() => import(`./detail/${getDeliveryDrugPath()}`));

  return (
    <Container>
      <DeliveryUserDetail user={response?.user} />

      <Title>{t('DeliveryDetail')}</Title>

      <DeliveryForm user={response?.user} onFinish={onFinish} />
    </Container>
  );
}

const Container = styled(Suspense)``;

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: bold;
`;
