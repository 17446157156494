import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { UserRoleType, UserRoleTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { conferenceCreate, userList } from '../../../services/api';
import { notifyError, notifySuccess } from '../../../utilies/notification';
import { Drawer, Element, Select } from '../../globals';

export default function ConferenceCreate({ load, panelVisibility, setPanelVisibility }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();
  const [formRef] = Form.useForm();

  const [users, setUsers] = useState([]);
  const [userStatus, setUserStatus] = useState(null);
  const [ready, setReady] = useState(true);

  const userRoles = user.getUserRoles();

  const [userFilter, setUserFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: user?.c,
    currentUserId: user?.i,
    userRole: UserRoleType.Patient,
  });

  // Hooks
  useEffect(() => {
    if (userFilter.userRole !== undefined && userFilter.userRole !== null && userRoles.length > 0) {
      userFilterList();
    }
  }, [userFilter]);

  // Functions
  const userFilterList = async () => {
    formRef.resetFields();
    setUserStatus(null);

    await userList(userFilter, (status, res) => {
      if (status) {
        setUsers(res.data);
      }
    });
  };

  // Events
  const onFinish = async (model) => {
    if (model.dates.some((x) => x.date < moment())) {
      notifyError(t('InvalidConferenceDates'));
      return;
    }

    setReady(false);
    let dates = model.dates.map((x) => moment(x.date).add(-user.t, 'minutes').format('YYYY-MM-DDTHH:mm:ss'));
    model = { ...model, dates, country: userFilter.country };
    await conferenceCreate(model, (status, res) => {
      if (status) {
        load();
        notifySuccess(t('CreateCompleteSuccess'));
        setPanelVisibility(false);
      }

      setReady(true);
    });
  };

  const onUserChange = ({ value }) => {
    let selectedUser = users.find((x) => x.id === value);
    setUserStatus({
      isVerified: selectedUser.isVerified,
      timeOffset: user.t - selectedUser.timeOffset,
    });
  };

  // Render
  return (
    <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)} width={500}>
      {false && userRoles.length > 0 && (
        <ConferenceFilter>
          <FilterText>{t('Filter')}</FilterText>
          <Select
            key="user-role-filter"
            data={UserRoleTypes.filter((x) => userRoles.some((u) => u === x.value)).map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={userRoles.length > 0 && ((userRole) => setUserFilter((x) => ({ ...x, userRole })))}
            placeholder={t('SelectUserRole')}
            defaultValue={userFilter.userRole}
            value={userFilter.userRole}
          />
        </ConferenceFilter>
      )}

      {(userStatus?.isVerified === false || userStatus?.timeOffset > 0) && (
        <Information>
          <ImportantInformation>
            <FaInfoCircle /> Important Information
          </ImportantInformation>
          {!userStatus.isVerified && <LoginStatus>The user you selected has not yet logged into the system.</LoginStatus>}
          {userStatus.timeOffset > 0 && (
            <TimeOffsetStatus>
              There is a{' '}
              <strong>
                {userStatus.timeOffset} minutes ({parseFloat(userStatus.timeOffset) / 60} hours)
              </strong>{' '}
              difference between you and the person you want to conference.
            </TimeOffsetStatus>
          )}
        </Information>
      )}

      <Element
        key="conference-form"
        columnSize={24}
        ready={ready}
        formRef={formRef}
        onFinish={onFinish}
        inputs={[
          {
            type: 'select',
            name: 'targetUserId',
            label: t('User'),
            placeholder: t('SelectUser'),
            data: users.map((x) => ({ value: x.id, text: x.fullName })),
            rules: [{ required: true, message: t('UserEmpty') }],
            onChange: onUserChange,
            allowClear: false,
          },
          {
            name: 'dates',
            add: t('AddDate'),
            remove: true,
            list: [
              {
                type: 'date',
                name: 'date',
                label: 'Date',
                showTime: true,
                placeholder: t('SelectDate'),
                rules: [{ required: true, message: 'DateEmpty' }],
                disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
              },
            ],
            templates: ['multiple', 'empty-bottom'],
          },
        ]}
        submit={t('Confirm')}
      />
    </Drawer>
  );
}

const ConferenceFilter = styled.div`
  position: relative;
  padding-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
  > * {
    width: 100%;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const FilterText = styled.div`
  position: absolute;
  top: 0px;
  font-weight: bold;
`;

const Information = styled.div`
  margin-bottom: 42px;
`;

const ImportantInformation = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${(x) => x.theme.colors.darkTurquoise};
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
  }
`;

const LoginStatus = styled.div``;

const TimeOffsetStatus = styled.div``;
