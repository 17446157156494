import { css } from 'styled-components';

export const layout = css`
  * {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(x) => x.theme.colors.deepKaomaru};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(x) => x.theme.colors.mediumSpringGreen};
  }

  @font-face {
    src: url('/assets/fonts/montserrat.woff2') format('woff2-variations');
    font-family: 'montserrat-vf';
    font-style: normal;
    font-display: swap;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${(x) => x.theme.fonts.families.default} !important;
    font-size: ${(x) => x.theme.fonts.sizes.default};
    letter-spacing: 0.5px;
  }
`;
