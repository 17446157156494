import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import AgoraRTC, { AgoraVideoPlayer, createClient, createMicrophoneAndCameraTracks } from 'agora-rtc-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash } from 'react-icons/fa';
import { ImPhoneHangUp } from 'react-icons/im';
import { RiSettingsFill } from 'react-icons/ri';
import styled from 'styled-components';
import { conferenceLeave } from '../../../services/api';
import { notifyError } from '../../../utilies/notification';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Button, Select } from '../../globals';

export default function ConferencePlayer({ conference, setConference }) {
  // Definitions
  const { t } = useTranslation();
  const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const useClient = createClient({ mode: 'rtc', codec: 'vp8' });
  const client = useClient();

  const [state, dispatch] = useContext(ReduceContext);

  const [targetUser, setTargetUser] = useState(null);
  const [targetVideoPlayer, setTargetVideoPlayer] = useState(null);
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [device, setDevice] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isDevicePanelVisibility, setIsDevicePanelVisibility] = useState(false);

  // Hooks
  useEffect(() => {
    if (ready) {
      load();
    }
  }, [ready, conference.channel, conference.token]);

  useEffect(() => {
    if (state?.conference?.leave) {
      setTargetUser(null);
      setTargetVideoPlayer(null);
      dispatch({ ...state, conference: { leave: false } });
    }
  }, [state?.conference?.leave]);

  // Functions
  const load = async () => {
    let clientDevices = await AgoraRTC.getDevices();

    let audioInputs = clientDevices.filter((x) => x.kind === 'audioinput');
    let videoInputs = clientDevices.filter((x) => x.kind === 'videoinput');

    setDevice({ audioInputs, videoInputs });

    client.on('user-joined', async (user) => {
      setTargetUser({});
    });

    client.on('user-published', async (user, type) => {
      await client.subscribe(user, type);

      if (type === 'video') {
        setTargetVideoPlayer(user);
      }

      if (type === 'audio') {
        user.audioTrack?.play();
      }

      setTargetUser({ isVideoPublished: type === 'video', isAudioPublished: type === 'audio' });
    });

    client.on('user-unpublished', (user, type) => {
      if (type === 'audio') {
        user.audioTrack?.stop();
      }

      if (type === 'video') {
        setTargetVideoPlayer(null);
      }

      setTargetUser({ isVideoPublished: !(type === 'video'), isAudioPublished: !(type === 'audio') });
    });

    client.on('user-left', (user) => {
      setTargetUser(null);
      setTargetVideoPlayer(null);
    });

    await client.join(conference.applicationId, conference.channel, conference.token);

    if (tracks) {
      let audioDevice = audioInputs[0].deviceId;
      let videoDevice = videoInputs[0].deviceId;

      tracks[0].setDevice(audioDevice);
      tracks[1].setDevice(videoDevice);

      setSelectedDevice({ audioDevice, videoDevice });
      await client.publish([tracks[0], tracks[1]]);
    }
  };

  const mute = async (type) => {
    if (tracks === null) return;

    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => ({ ...ps, audio: !ps.audio }));
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => ({ ...ps, video: !ps.video }));
    }
  };

  const leave = async () => {
    await conferenceLeave({}, async (status, res) => {
      await client.leave();
      //client.removeAllListeners();
      setTrackState(null);
      setConference(null);
      dispatch({ ...state, conference: null });

      if (tracks) {
        tracks[0].close();
        tracks[1].close();
      }
    });
  };

  const onDeviceChange = async (type, value) => {
    if (tracks === null) return;

    switch (type) {
      case 'audio':
        tracks[0].setDevice(value);

        setSelectedDevice((x) => ({ ...x, audioDevice: value }));
        break;

      case 'video':
        tracks[1].setDevice(value);
        setSelectedDevice((x) => ({ ...x, videoDevice: value }));
        break;

      default:
        notifyError(t('NotImplemented'));
        break;
    }
  };

  // Render
  return (
    <Container>
      <Panel>
        <User>{ready && tracks && selectedDevice && <UserVideoPlayer videoTrack={tracks[1]} />}</User>

        <TargetUser>
          {targetUser ? (
            <TargetUserDetail>
              {targetVideoPlayer ? (
                targetVideoPlayer.videoTrack && <TargetVideoPlayer videoTrack={targetVideoPlayer.videoTrack} key={targetVideoPlayer.uid} />
              ) : (
                <UserOutlined />
              )}
            </TargetUserDetail>
          ) : (
            <LoadingOutlined />
          )}
        </TargetUser>

        <Controls>
          <Button onClick={() => setIsDevicePanelVisibility(!isDevicePanelVisibility)} templates={['conference', isDevicePanelVisibility && 'close']}>
            <RiSettingsFill />
          </Button>
          <Button onClick={() => mute('audio')} templates={['conference', trackState.audio ? '' : 'close']}>
            {trackState.audio ? <FaMicrophoneSlash /> : <FaMicrophone />}
          </Button>
          <Button onClick={() => mute('video')} templates={['conference', trackState.video ? '' : 'close']}>
            {trackState.video ? <FaVideoSlash /> : <FaVideo />}
          </Button>
          <Button onClick={leave} templates={['conference', 'leave']}>
            {<ImPhoneHangUp />}
          </Button>
        </Controls>

        {device && isDevicePanelVisibility && (
          <DevicePanel>
            <Device key="audio-in-devices">
              <FaMicrophone />
              <Select
                data={device?.audioInputs?.map((x) => ({ value: x.deviceId, text: x.label }))}
                showSearch={true}
                allowClear={false}
                templates={['device']}
                value={selectedDevice?.audioDevice}
                onChange={(value) => onDeviceChange('audio', value)}
              />
            </Device>
            <Device key="video-in-devices">
              <FaVideo />
              <Select
                data={device?.videoInputs?.map((x) => ({ value: x.deviceId, text: x.label }))}
                showSearch={true}
                allowClear={false}
                templates={['device']}
                value={selectedDevice?.videoDevice}
                onChange={(value) => onDeviceChange('video', value)}
              />
            </Device>
          </DevicePanel>
        )}
      </Panel>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Panel = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px;
`;

const User = styled.div`
  height: 400px;
  width: 400px;
  position: absolute;
  z-index: 99;
  right: 40px;
  bottom: 40px;
  border-radius: 20px;
  overflow: hidden;
`;

const TargetUser = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
`;

const TargetUserDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const UserVideoPlayer = styled(AgoraVideoPlayer)`
  width: 100%;
  height: 100%;
`;

const TargetVideoPlayer = styled(AgoraVideoPlayer)`
  width: 100%;
  height: 100%;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const DevicePanel = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  background-color: ${(x) => x.theme.colors.deepKaomaru};
  padding: 10px;
  border-radius: 10px;
`;

const Device = styled.div`
  height: 50px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  > svg {
    position: absolute;
    color: #000;
    z-index: 11;
    top: 15px;
    line-height: 0;
    left: 10px;
    width: 20px;
    height: 20px;
  }
`;
