import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import {
  CountryType,
  DeliveryFlowStepNotificationGroupType,
  DeliveryFlowStepNotificationGroupTypes,
  DeliveryFlowStepNotificationStatusType,
  DeliveryFlowStepNotificationStatusTypes,
  DeliveryFlowStepNotificationType,
  DeliveryFlowStepNotificationTypes,
  TextlocalSmsTemplateTypes,
} from '../../constants';

export default function DeliveryFlowStepNotificationInputs({ country, onGroupChange, group, onStatusChange, status, onTypeChange, type, onSmsTemplateChange }) {
  const { t } = useTranslation();

  const [smsTemplates, setSmsTemplates] = useState(null);

  useEffect(() => {
    if (country === CountryType.India) {
      setSmsTemplates(TextlocalSmsTemplateTypes.filter((x) => x.prompt === 'Delivery').map((x) => ({ ...x, text: t(x.displayName) })));
    }
  }, [country]);

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'hidden',
      name: 'deliveryFlowStepId',
    },
    {
      type: 'select',
      name: 'isActive',
      label: t('Activity'),
      placeholder: t('SelectActivity'),
      rules: [{ required: true, message: t('IsActiveEmpty') }],
      data: [
        { value: true, text: t('Active') },
        { value: false, text: t('Passive') },
      ],
    },
    {
      type: 'select',
      name: 'group',
      label: t('StepNotificationGroup'),
      placeholder: t('SelectStepNotificationGroup'),
      rules: [{ required: true, message: t('StepNotificationGroupEmpty') }],
      data: DeliveryFlowStepNotificationGroupTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onGroupChange,
    },
    group === DeliveryFlowStepNotificationGroupType.AfterStepComplete && {
      type: 'number',
      name: 'afterDays',
      label: t('NotificationAfterDays'),
      placeholder: t('NotificationAfterDaysEmpty'),
      rules: [{ required: true, message: t('NotificationAfterDaysEmpty') }],
    },
    {
      type: 'select',
      name: 'status',
      label: t('StepNotificationStatus'),
      placeholder: t('SelectStepNotificationStatus'),
      rules: [{ required: true, message: t('StepNotificationStatusEmpty') }],
      data: DeliveryFlowStepNotificationStatusTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onStatusChange,
    },
    {
      type: 'select',
      name: 'type',
      label: t('StepNotificationType'),
      placeholder: t('SelectStepNotificationType'),
      rules: [{ required: true, message: t('StepNotificationTypeEmpty') }],
      data: DeliveryFlowStepNotificationTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onTypeChange,
    },
    (type === DeliveryFlowStepNotificationType.Sms && country !== CountryType.India) ||
    type === DeliveryFlowStepNotificationType.Email ||
    type === DeliveryFlowStepNotificationType.Mobile
      ? {
          type: 'input',
          name: 'nameKey',
          label: t('NameKey'),
          placeholder: t('NameKeyEmpty'),
          rules: [
            { required: true, message: t('NameKeyEmpty') },
            { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
          ],
        }
      : null,
    (type === DeliveryFlowStepNotificationType.Sms && country !== CountryType.India) ||
    type === DeliveryFlowStepNotificationType.Email ||
    type === DeliveryFlowStepNotificationType.Mobile
      ? {
          type: 'input',
          name: 'descriptionKey',
          label: t('DescriptionKey'),
          placeholder: t('DescriptionKeyEmpty'),
          rules: [
            { required: true, message: t('DescriptionKeyEmpty') },
            { min: 3, max: 500, message: format(t('LengthValid'), 1, 500) },
          ],
        }
      : null,
    smsTemplates &&
      type === DeliveryFlowStepNotificationType.Sms && {
        type: 'select',
        name: 'smsTemplateTypeId',
        label: t('DeliveryNotificationSmsTemplateType'),
        placeholder: t('SelectDeliveryNotificationSmsTemplateType'),
        rules: [{ required: true, message: t('DeliveryNotificationSmsTemplateTypeEmpty') }],
        data: smsTemplates,
      },
    status === DeliveryFlowStepNotificationStatusType.Special &&
      type !== DeliveryFlowStepNotificationType.Mobile && {
        type: type === DeliveryFlowStepNotificationType.Sms ? 'phone' : 'input',
        name: 'value',
        label: t('DeliveryNotificationValue'),
        placeholder: t('DeliveryNotificationValueEmpty'),
        rules: [
          { required: true, message: t('DeliveryNotificationValueEmpty') },
          { min: 3, max: 500, message: format(t('LengthValid'), 1, 500) },
        ],
        onChange: onSmsTemplateChange,
      },
  ];
}
