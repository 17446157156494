import { Tooltip } from 'antd';
import { FaEye } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button } from '../../components/globals';
import ColumnBase from './_ColumnBase';

export default function VitalColumns({ isUserVisible, onUpdateClick, onDetailClick }) {
  let columns = [
    isUserVisible && {
      locale: 'UserFullName',
      name: 'user',
      type: 'string',
      render: (value, row) => {
        return (
          <Tooltip placement="topLeft" title={row.user.fullName}>
            {row.user.fullName}
          </Tooltip>
        );
      },
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
    onDetailClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onDetailClick(row)} templates={['table']}>
          <FaEye />
        </Button>
      ),
    },
  ];

  return ColumnBase({ columns });
}
