import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ConfigProvider, Layout } from 'antd';
import antLocaleEN from 'antd/lib/locale/en_GB';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { LanguageTypes, NotificationType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import i18n from '../../../i18n';
import { loginRelations } from '../../../services/api';
import { getLocale, setItem } from '../../../services/cache';
import { getApiUrl } from '../../../services/config';
import { getAntLocalization, getMomentLocalization } from '../../../utilies/localization';
import { notifyError } from '../../../utilies/notification';
import { ReduceContext } from '../../contexts/ReduceContext';
import { Footer, Header, SideBar } from '../../globals';
import ConferencePlayer from '../conference/ConferencePlayer';

const { Content } = Layout;

export default function LayoutBase({ children }) {
  // Definitions
  const location = useLocation();
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [state, dispatch] = useContext(ReduceContext);
  const [hub, setHub] = useState(null);
  const [connection, setConnection] = useState(null);
  const [localization, setLocalization] = useState(antLocaleEN);
  const [conference, setConference] = useState(null);

  const [size, setSize] = useState({ width: window?.innerWidth, height: window?.innerHeight });

  // Hooks
  useEffect(() => {
    let code = getLocale();
    setLanguage(code);

    hubConnect();
    loadRelations();
  }, []);

  useEffect(() => {
    if (window === undefined) return;

    window.addEventListener('resize', () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);

  useEffect(() => {
    if (location?.state?.error) {
      notifyError(t(location?.state?.error));
    }
  }, [location?.state]);

  useEffect(() => {
    if (!hub) return;

    hub
      .start()
      .then(() => {
        setConnection(true);
      })
      .catch(() => {
        setConnection(false);
      });
  }, [hub]);

  useEffect(() => {
    if (state?.conference?.token && state?.conference?.leave === undefined) {
      setConference(state.conference);
    }
  }, [state]);

  useEffect(() => {
    if (!connection) return;

    hub.on('Conference', (type) => {
      if (type.some((x) => x === 'leave')) {
        dispatch({ ...state, conference: { ...state?.conference, leave: true } });
      }
    });

    hub.on('Notification', (types) => {
      if (types.some((x) => x === NotificationType.ConferenceScheduledApproved)) {
        dispatch({ ...state, conference: { list: true } });
      }
      dispatch({ ...state, notification: { list: true } });
    });

    hub.on('Login', (users) => {
      dispatch({ ...state, online: { users: state?.online?.users?.length ? [...state?.online?.users, ...users] : [...users] } });
    });

    hub.on('Logout', (users) => {
      let user = users[0];

      dispatch({ ...state, online: { users: state?.online?.users.filter((x) => x !== user) } });
    });
  }, [connection]);

  // Functions
  const hubConnect = () => {
    setHub(
      new HubConnectionBuilder()
        .withUrl(`${getApiUrl()}/hubs/user`, { accessTokenFactory: () => user.token })
        .configureLogging(LogLevel.None)
        .withAutomaticReconnect()
        .build()
    );
  };

  const loadRelations = async () => {
    await loginRelations((status, res) => {
      if (status) {
        dispatch({ ...state, online: { users: res.data } });
      }
    });
  };

  const setLanguage = (languageCode) => {
    let language = LanguageTypes.find((x) => x.data.code === languageCode);

    let code = language.data.code.toLowerCase();
    let locale = language.data.locale.toLowerCase();
    let antLocalization = getAntLocalization(code);
    let momentLocalization = getMomentLocalization(code);

    setItem('lcl', code);
    setLocalization(antLocalization);
    moment.updateLocale(locale, [momentLocalization]);
    i18n.changeLanguage(code);
  };

  // Render
  return (
    <ConfigProvider locale={localization}>
      <Container>
        <SideBar user={user} size={size} />
        <Body>
          <Header user={user} size={size} onLanguageClick={({ key }) => setLanguage(key)} />
          <Content>{children}</Content>
          <Footer />
          {conference && <ConferencePlayer conference={conference} setConference={setConference} />}
        </Body>
      </Container>
    </ConfigProvider>
  );
}

const Container = styled(Layout)`
  min-height: 100vh;
`;

const Body = styled(Layout)`
  padding-top: 64px;
  background-color: ${(x) => x.theme.colors.white};

  .ant-layout-header,
  .ant-layout-content {
    padding-left: 350px;
  }

  @media only screen and (max-width: 1366px) {
    .ant-layout-header,
    .ant-layout-content {
      padding-left: 300px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .ant-layout-header,
    .ant-layout-content {
      padding-left: 60px;
    }
  }
`;
