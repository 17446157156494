import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { TiFlowChildren } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router';
import { Button, Card, Drawer, Element, Page } from '../../components/globals';
import DeliveryFlowStep from '../../components/pages/delivery/DeliveryFlowStep';
import DeliveryFlowSteps from '../../components/pages/delivery/DeliveryFlowSteps';
import { CountryTypes, DeliveryActionTypes, DeliveryFlowStepInputs, UserRoleTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import {
  deliveryFlowDetail,
  deliveryFlowStepCreate,
  deliveryFlowStepDelete,
  deliveryFlowStepList,
  deliveryFlowStepRowParentUpdate,
  deliveryFlowStepUpdate,
} from '../../services/api';
import { notifyError, notifySuccess } from '../../utilies/notification';
import DeliveryFlowStepNotification from './DeliveryFlowStepNotification';

export default function DeliveryFlowUpdate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, countryType } = useParams();
  const [user] = useCacheUser();
  const [stepFormRef] = Form.useForm();

  const country = CountryTypes.find((x) => x.value === parseInt(countryType ?? user.c));

  const [rowData, setRowData] = useState(null);
  const [steps, setSteps] = useState([]);
  const [stepTree, setStepTree] = useState([]);
  const [stepList, setStepList] = useState([]);
  const [panelVisiblity, setPanelVisibility] = useState(false);
  const [notificationPanelVisibility, setNotificationPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    detail: false,
    form: true,
    stepForm: true,
  });

  const [detailFilter] = useState({
    pageNumber: 1,
    dataLimit: 1000,
    id,
    country: country.value,
    orderBy: ['row|asc'],
  });

  const [stepFilter] = useState({
    pageNumber: 1,
    dataLimit: 1000,
    deliveryFlowId: id,
    country: country.value,
    orderBy: ['row|asc'],
    isDeleted: false,
  });

  // Hooks
  useEffect(() => {
    detail();
  }, [detailFilter, stepFilter]);

  // Functions
  const fillStepListData = (data) => {
    return data.map((x) => ({ value: x.id, title: x.nameKey, children: fillStepListData(x.steps) }));
  };

  const fillStepTreeData = (data) => {
    return data.map((x) => {
      x.actionData = DeliveryActionTypes.find((a) => a.value === x.action);
      x.userRoleData = UserRoleTypes.find((a) => a.value === x.userRole);

      return {
        key: x.id,
        title: (
          <DeliveryFlowStep
            step={x}
            onCreateClick={onStepCreateClick}
            onEditClick={onStepEditClick}
            onNotificationClick={onStepNotificationClick}
            onDeleteClick={onStepDeleteClick}
          />
        ),
        children: fillStepTreeData(x.steps),
      };
    });
  };

  const detail = async () => {
    setPageState((x) => ({ ...x, detail: false }));

    let [deliveryFlowDetailResponse, deliveryFlowStepListResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        deliveryFlowDetail(detailFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        deliveryFlowStepList(stepFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (!deliveryFlowDetailResponse.data) {
      notifyError(t('DeliveryNotFoundException'));
      navigate(`/delivery/list`);
      return;
    }

    let result = deliveryFlowStepListResponse.data.filter((x) => x.parentId === null);

    setSteps(deliveryFlowStepListResponse.data);
    setStepList(fillStepListData(result));
    setStepTree(fillStepTreeData(result));

    setPageState((x) => ({ ...x, detail: true }));
  };

  // Events
  const onStepFormFinish = async (model) => {
    setPageState((x) => ({ ...x, stepForm: false }));

    let request = model.id === undefined ? deliveryFlowStepCreate : deliveryFlowStepUpdate;
    await request(model, (status, res) => {
      if (status) {
        detail();
        setPanelVisibility(false);
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, stepForm: true }));
    });
  };

  const onParentStepAddClick = () => {
    setRowData(null);
    setPanelVisibility(true);
    stepFormRef.resetFields();
    stepFormRef.setFieldsValue({ deliveryFlowId: id, country: detailFilter.country });
  };

  const onStepCreateClick = async (step) => {
    setRowData(null);
    setPanelVisibility(true);
    stepFormRef.resetFields();
    stepFormRef.setFieldsValue({ parentId: step.id, deliveryFlowId: id, country: detailFilter.country });
  };

  const onStepEditClick = (step) => {
    setRowData(step);
    setPanelVisibility(true);
    stepFormRef.resetFields();
    stepFormRef.setFieldsValue({ ...step, deliveryFlowId: id, country: detailFilter.country });
  };

  const onStepNotificationClick = (step) => {
    setRowData(step);
    setNotificationPanelVisibility(true);
  };

  const onStepDeleteClick = async (step) => {
    let model = { ...step, country: detailFilter.country };

    await deliveryFlowStepDelete(model, (status, res) => {
      if (status) {
        detail();
        notifySuccess(t('DeleteCompleteSuccess'));
      }
    });
  };

  const onStepDropped = async (steps) => {
    let model = { deliveryFlowId: id, steps, country: detailFilter.country };

    await deliveryFlowStepRowParentUpdate(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      }
    });
  };

  // Render
  return (
    <Page
      title={t('DeliveryFlowSteps')}
      icon={<RiEdit2Fill />}
      routes={[
        {
          path: `/delivery/flow/list`,
          name: t(`DeliveryFlows`),
          breadcrumbName: `DeliveryFlows`,
          icon: <TiFlowChildren />,
        },
        {
          name: t('DeliveryFlowSteps'),
          breadcrumbName: `DeliveryFlowSteps`,
          icon: <RiEdit2Fill />,
        },
      ]}
      extra={[
        <Button key="parent-step" onClick={onParentStepAddClick} style={{ marginBottom: 16 }} templates={['colored']}>
          {t('AddParentStep')}
        </Button>,
      ]}
    >
      <Card ready={pageState.detail} templates={['page']}>
        {steps?.length ? (
          <DeliveryFlowSteps steps={stepTree} setSteps={setStepTree} onDropped={onStepDropped} keys={steps?.map((x) => x.id)} />
        ) : (
          <>{t('PleaseAddParentStep')}</>
        )}

        <Drawer visible={panelVisiblity} onClose={() => setPanelVisibility(false)}>
          <Element
            key="step-form"
            ready={pageState.stepForm}
            formRef={stepFormRef}
            onFinish={onStepFormFinish}
            inputs={DeliveryFlowStepInputs({ isUpdate: rowData !== null, steps: stepList })}
            columnSize={24}
            submit={t('Confirm')}
          />
        </Drawer>

        <Drawer width="90%" title={t('DeliveryFlowStepNotifications')} visible={notificationPanelVisibility} onClose={() => setNotificationPanelVisibility(false)}>
          {rowData && notificationPanelVisibility && <DeliveryFlowStepNotification country={stepFilter.country} deliveryFlowStep={rowData} />}
        </Drawer>
      </Card>
    </Page>
  );
}
