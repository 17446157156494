import { Layout } from 'antd';
import styled from 'styled-components';
import { CountryType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';

const { Footer: AntFooter } = Layout;

export default function FooterBase() {
  const [user] = useCacheUser();

  switch (user?.c) {
    case CountryType.Russian:
      return <DefaultFooter>M-RU-00010166, январь 2023. Salos ©{new Date().getFullYear()}</DefaultFooter>;

    default:
      return <DefaultFooter>Salos ©{new Date().getFullYear()}</DefaultFooter>;
  }
}

const DefaultFooter = styled(AntFooter)`
  padding: 0px 25px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
