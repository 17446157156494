import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaVideo } from 'react-icons/fa';
import { format } from 'react-string-format';
import { ReduceContext } from '../../components/contexts/ReduceContext';
import { Button, Extra, Page, Table } from '../../components/globals';
import ConferenceCreate from '../../components/pages/conference/ConferenceCreate';
import { ActionType, ConferenceColumns, ConferenceType, ConferenceTypes, UserRoleActionType, UserRoleType } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { conferenceJoin, conferenceList, conferenceMultipleDelete } from '../../services/api';
import { combineOnlineUsers } from '../../utilies/conferenceHelper';
import { notifySuccess } from '../../utilies/notification';

export default function Conferences() {
  // Definitions
  let onlineUserTimeout;
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [state, dispatch] = useContext(ReduceContext);
  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    includeUser: true,
    includeTargetUser: true,
  });

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    update: true,
    delete: true,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  useEffect(() => {
    clearTimeout(onlineUserTimeout);

    if (state?.online?.users?.length && response?.length) {
      onlineUserTimeout = setTimeout(() => {
        response.data = combineOnlineUsers(response, user, state?.online?.users);
        setResponse(response);
      }, 1000);
    }
  }, [state?.online?.users?.length, pageState]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await conferenceList(filter, (status, res) => {
      if (status) {
        res.data = combineOnlineUsers(
          res.data.map((x) => ({
            ...x,
            dates: JSON.parse(x.data),
            conferenceType: ConferenceType.Pending,
            conferenceTypeData: ConferenceTypes.find((x) => x.value === ConferenceType.Pending),
          })),
          user,
          state?.online?.users
        );
        setResponse(res);
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await conferenceMultipleDelete({ ids: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onJoinClick = async (row) => {
    await conferenceJoin(row, (status, res) => {
      if (status) {
        dispatch({ ...state, conference: { channel: row.id, token: res.data.item1, applicationId: res.data.item2 } });
      }
    });
  };

  // Render
  return (
    <Page
      title={t('Conferences')}
      icon={<FaVideo />}
      subTitle={
        user.checkAction(ActionType.ConferenceCreate) &&
        user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List) && (
          <Button ready={pageState?.create} onClick={() => setPanelVisibility(true)} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
            {t('AddNew')}
          </Button>
        )
      }
      routes={[
        {
          name: t('Conferences'),
          breadcrumbName: t('Conferences'),
          icon: <FaVideo />,
        },
      ]}
      templates={['default-0-filter-2']}
      extra={[
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.ConferenceDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={!filter.isDeleted && selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={ConferenceColumns({ isDeleted: filter.isDeleted, onJoinClick })}
      />

      {user.checkAction(ActionType.ConferenceCreate) && user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List) && (
        <ConferenceCreate panelVisibility={panelVisibility} setPanelVisibility={setPanelVisibility} load={list} />
      )}
    </Page>
  );
}
