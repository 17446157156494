import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { CountryType, DrugType } from '../../../constants';

export default function TreatmentDrug({ formRef, data, onValuesChange }) {
  const getDrugPath = () => {
    switch (data.drug.value) {
      case DrugType.Evrysdi:
        switch (data.country.value) {
          case CountryType.India:
          case CountryType.Russian:
            return `${data.country.name.toLowerCase()}/${data.drug.name}`;

          default:
            return `DefaultDrug`;
        }

      case DrugType.Enspryng:
        switch (data.country.value) {
          case CountryType.Russian:
            return `${data.country.name.toLowerCase()}/${data.drug.name}`;

          default:
            return `DefaultDrug`;
        }

      case DrugType.Hemlibra:
        switch (data.country.value) {
          case CountryType.Bangladesh:
            return `${data.country.name.toLowerCase()}/${data.drug.name}`;

          default:
            return `DefaultDrug`;
        }

      case DrugType.Pralsetinib:
      default:
        return `DefaultDrug`;
    }
  };

  const Drug = lazy(() => import(`./drug/${getDrugPath()}`));

  return (
    <Container>
      <Drug formRef={formRef} data={data} onValuesChange={onValuesChange} />
    </Container>
  );
}

const Container = styled(Suspense)``;
