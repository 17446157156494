import { Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoticeGroupType, NoticeInputs } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { noticeCreate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Element } from '../../globals';

export default function AskForHelp() {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [pageState, setPageState] = useState({ form: true });

  // Events
  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, country: user.c, group: NoticeGroupType.AskForHelp };
    await noticeCreate(model, (status, res) => {
      if (status) {
        notifySuccess(t('MessageSendCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Element
      key="notice-ask-for-help-form"
      onFinish={onFinish}
      ready={pageState.form}
      formRef={formRef}
      inputs={NoticeInputs({ type: 'ask-for-help' })}
      columnSize={24}
      submit={t('Confirm')}
    />
  );
}
