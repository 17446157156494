const NoticeGroupTypes = [
  {
    value: 0,
    name: 'AskForHelp',
    displayName: 'AskForHelp',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 1,
    name: 'AdverseEvent',
    displayName: 'AdverseEvent',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  },
  {
    value: 2,
    name: 'MissedDose',
    displayName: 'MissedDose',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 0
  }
];

export default NoticeGroupTypes;