import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function DefaultUsageInputs({ onDailyDoseMg, onDailyDoseMl }) {
  const { t } = useTranslation();

  return [
    {
      name: 'usages',
      templates: ['item-row', 'left-number', 'multiple', 'treatment-list'],
      remove: true,
      list: [
        {
          type: 'input',
          name: 'row',
          allowClear: false,
          templates: ['row-number', 'number', 'treatment-row-number'],
        },
        {
          type: 'date',
          name: 'time',
          label: 'Time',
          rules: [{ required: true, message: 'TimeEmpty' }],
          disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
        },
        {
          type: 'number',
          name: 'dailyDoseMg',
          label: t('DailyDoseMg'),
          placeholder: t('DailyDoseMgEmpty'),
          rules: [{ required: true, message: t('DailyDoseMgEmpty') }],
          initialValue: 0.1,
          numberRule: { min: 0.1, max: 5 },
          onChange: onDailyDoseMg,
          step: '0.1',
          css: `max-width:150px`,
        },
        {
          type: 'number',
          name: 'dailyDoseMl',
          label: t('DailyDoseMl'),
          placeholder: t('DailyDoseMlEmpty'),
          rules: [{ required: true, message: t('DailyDoseMlEmpty') }],
          initialValue: 0.1,
          numberRule: { min: 0.1, max: 6.6 },
          onChange: onDailyDoseMl,
          step: '0.1',
          css: `max-width:150px`,
        },
      ],
    },
  ];
}
