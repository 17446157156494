import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deliveryFlowStepListGlobal, deliveryStepList } from '../../../services/api';
import DeliveryStep from './DeliveryStep';

export default function DeliverySteps({ country, delivery, list }) {
  // Definitions
  const { t } = useTranslation();

  const [steps, setSteps] = useState([]);
  const [flows, setFlows] = useState([]);

  const [stepFilter] = useState({
    pageNumber: 1,
    dataLimit: 1000,
    orderBy: ['row|asc'],
    deliveryId: delivery.id,
    targetUserId: delivery.targetUserId,
    country,
  });

  const [flowStepFilter] = useState({
    pageNumber: 1,
    dataLimit: 1000,
    orderBy: ['row|asc'],
    deliveryFlowId: delivery.deliveryFlowId,
    country,
  });

  // Hooks
  useEffect(() => {
    load();
  }, [delivery]);

  // Functions
  const load = async () => {
    let [deliveryStepResponse, deliveryFlowStepResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        deliveryStepList(stepFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        deliveryFlowStepListGlobal(flowStepFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    setSteps(deliveryStepResponse?.data ?? []);
    setFlows(deliveryFlowStepResponse?.data ?? []);
  };

  // Events
  const onCompleted = () => {
    load();
    list();
  };

  // Render
  return (
    <Container>
      <Title>{t('DeliveryDetail')}</Title>

      <Details>
        <Detail>
          <Name>{t('Reference')}</Name>
          <Value>{delivery.reference}</Value>
        </Detail>
        {delivery.freeBottle !== null && (
          <Detail>
            <Name>{t('FreeBottle')}</Name>
            <Value>{delivery.freeBottle}</Value>
          </Detail>
        )}
        {delivery.paidBottle !== null && (
          <Detail>
            <Name>{t('PaidBottle')}</Name>
            <Value>{delivery.paidBottle}</Value>
          </Detail>
        )}
        {delivery.totalBottle !== null && (
          <Detail>
            <Name>{t('TotalBottle')}</Name>
            <Value>{delivery.totalBottle}</Value>
          </Detail>
        )}
        <Detail>
          <Name>{t('CityName')}</Name>
          <Value>{delivery.targetUser.city.name}</Value>
        </Detail>
        <Detail>
          <Name>{t('Address')}</Name>
          <Value>{delivery.targetUserAddress}</Value>
        </Detail>
        <Detail>
          <Name>{t('Note')}</Name>
          <Value>{delivery.note}</Value>
        </Detail>
      </Details>

      <Title>{t('DeliverySteps')}</Title>

      {steps.map((x, i) => (
        <DeliveryStep country={country} key={`step-${i}`} delivery={delivery} step={x} flows={flows} onCompleted={onCompleted} />
      ))}
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Details = styled.div`
  margin-bottom: 32px;
`;

const Detail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const Name = styled.div`
  width: 50%;
`;
const Value = styled.div`
  width: 50%;
  text-align: right;
`;
