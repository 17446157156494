import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button, PopupConfirm } from '../../components/globals';
import { VisitReasonTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function VisitColumns({ onUpdateClick, onDeleteClick }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'Reason',
      name: 'reason',
      type: 'string',
      render: (value, row) => {
        let type = VisitReasonTypes.find((x) => x.value === value);

        return type ? (
          <Tooltip placement="topLeft" title={t(type.displayName)}>
            {t(type.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'Date',
      name: 'date',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Message',
      name: 'message',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
          <RiEdit2Fill />
        </Button>
      ),
    },
    onDeleteClick && {
      locale: 'Delete',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <PopupConfirm
          key="delete-confirm"
          title={t('AreYouSureWantToDelete')}
          onConfirm={() => onDeleteClick(row)}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="bottomRight"
        >
          <Button templates={['table']}>
            <FaTrash />
          </Button>
        </PopupConfirm>
      ),
    },
  ];

  return ColumnBase({ columns });
}
