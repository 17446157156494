import { Modal } from 'antd';
import styled, { css } from 'styled-components';

export default function PopupModalBase({ footer, okText, cancelText, children, title, visible, confirmLoading, onOk, onCancelClick, width, templates }) {
  return (
    <Container
      footer={footer}
      title={title}
      visible={visible ?? false}
      confirmLoading={confirmLoading !== undefined ? !confirmLoading : false}
      onOk={onOk}
      onCancel={onCancelClick}
      okText={okText}
      cancelText={cancelText}
      width={width}
      templates={templates}
    >
      {children}
    </Container>
  );
}

const Container = styled(Modal)`
  .ant-modal-content {
    border-radius: ${(x) => x.theme.global.borderRadius};
    .ant-modal-header {
      border-radius: ${(x) => x.theme.global.borderRadius} ${(x) => x.theme.global.borderRadius} 0 0;
      border-bottom: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
    }
  }

  ${({ templates }) =>
    templates?.includes('preview') &&
    css`
      .ant-modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}

  ${({ templates }) =>
    templates?.includes('document-preview') &&
    css`
      width: 100% !important;
      height: calc(100vh - 20px);
      top: 20px;
      .ant-modal-content {
        height: 100%;
        .ant-modal-body {
          height: 100%;
        }

        .ant-modal-close {
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
          border-radius: 50px;
          color: ${(x) => x.theme.colors.white};
          top: -10px;
          right: -10px;
        }
      }
    `}
`;
