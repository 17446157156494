import { useTranslation } from 'react-i18next';
import { TemplateGroupType, TemplateGroupTypes, TemplateType, TemplateTypes, UserRoleTypes } from '../../constants';

export default function TemplateInputs({ isUpdate, templateGroup, templateType, onTemplateTypeChange }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'select',
      name: 'type',
      label: t('TemplateType'),
      placeholder: t('SelectTemplateType'),
      rules: [{ required: true, message: t('TemplateTypeEmpty') }],
      data: TemplateTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onTemplateTypeChange,
      disabled: isUpdate,
    },
    {
      type: 'select',
      name: 'group',
      label: t('TemplateGroup'),
      placeholder: t('SelectTemplateGroup'),
      rules: [{ required: true, message: t('TemplateGroupEmpty') }],
      data: TemplateGroupTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      disabled: isUpdate,
    },
    templateType === TemplateType.UserInvite && {
      type: 'select',
      name: 'userRole',
      label: t('UserRole'),
      placeholder: t('SelectUserRole'),
      data: UserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      disabled: isUpdate,
    },
    isUpdate && {
      type: templateGroup === TemplateGroupType.EmailTemplate ? 'editor' : 'textarea',
      name: 'content',
      label: t('TemplateContent'),
      rules: [{ required: true, message: t('TemplateContentEmpty') }],
      data: TemplateGroupTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      columnSize: 24,
      height: 400,
    },
  ];
}
