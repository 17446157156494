import { notification } from 'antd';
import { ExceptionType } from '../constants';
import i18n from '../i18n';

export const notifyResponseError = (res) => {
  let errors = res?.response?.data?.errors;

  if (errors) {
    errors.forEach((x) => {
      switch (x.type) {
        case ExceptionType.Validation:
          notifyError(i18n.t(x.message ?? 'ServerConnectionError'));
          break;

        case ExceptionType.Core:
        case ExceptionType.Default:
        case ExceptionType.Authentication:
        default:
          notifyError(i18n.t(x.code ?? 'ServerConnectionError'));
          break;
      }
    });
  } else {
    notifyError(i18n.t('ServerConnectionError'));
  }
};

export const notifySuccess = (message, description) => {
  notification.success({ message, description, placement: 'bottomRight' });
};

export const notifyError = (message, description) => {
  notification.error({ message, description, placement: 'bottomRight' });
};

export const notifyInfo = (message, description) => {
  notification.info({ message, description, placement: 'bottomRight' });
};

export const notifyWarning = (message, description) => {
  notification.success({ message, description, placement: 'bottomRight' });
};
