import moment from 'moment';
import { ConferenceStatusType, ConferenceType, ConferenceTypes } from '../constants';

export const combineOnlineUsers = (data, user, onlineUsers) => {
  return data.map((x) => {
    if (onlineUsers) {
      x.isOnline = (x.user.id === user.i && onlineUsers.some((s) => s === x.targetUser.id)) || (x.targetUser.id === user.i && onlineUsers.some((s) => s === x.user.id));
    }

    let conferenceType = ConferenceType.Pending;

    switch (x.status) {
      case ConferenceStatusType.Approved:
        let now = moment();
        let conferenceTime = moment(x.date).add(user.t, 'minutes');
        let conferenceExpireTime = moment(x.date).add(user.t, 'minutes').add(1, 'hours');

        if (now >= conferenceTime && now < conferenceExpireTime) {
          conferenceType = ConferenceType.Available;
        } else if (now < conferenceTime) {
          conferenceType = ConferenceType.Upcoming;
        } else if (now > conferenceTime && now > conferenceExpireTime) {
          conferenceType = ConferenceType.Completed;
        } else {
          conferenceType = ConferenceType.Waiting;
        }
        break;

      case ConferenceStatusType.Rejected:
        conferenceType = ConferenceType.Rejected;
        break;

      case ConferenceStatusType.Pending:
      default:
        conferenceType = ConferenceType.Pending;

        let selectedDate = moment(x.date ?? x.dates[0]).add(user.t, 'minutes');
        if (moment() > selectedDate) {
          conferenceType = ConferenceType.Expired;
        }

        break;
    }

    x.conferenceType = conferenceType;
    x.conferenceTypeData = ConferenceTypes.find((c) => c.value === x.conferenceType);

    return x;
  });
};
