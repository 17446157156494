import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function UserLoginSmsInputs({ isSmsLoginRequest }) {
  const { t } = useTranslation();

  return [
    {
      type: 'phone',
      name: 'phone',
      label: t('Phone'),
      placeholder: t('PhoneEmpty'),
      rules: [
        { required: true, message: t('PhoneEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
      templates: ['grey'],
    },
    isSmsLoginRequest && {
      type: 'number',
      name: 'code',
      label: t('VerificationCode'),
      placeholder: t('VerificationCodeEmpty'),
      maxLength: 6,
      numberRule: { min: 100000, max: 999999, message: t('VerificationCodeInvalid') },
      rules: [{ required: true, message: t('VerificationCodeEmpty') }],
      templates: ['grey'],
    },
  ];
}
