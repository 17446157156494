import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';

export default function PhoneInputBase({ value, placeholder, onChange, disabled, templates, ...restProps }) {
  const { t } = useTranslation();

  return (
    <Container
      enableSearch
      searchPlaceholder={t('Search')}
      value={value}
      inputClass="ant-input"
      placeholder={placeholder}
      disabled={disabled ?? false}
      localization={{
        af: t('PhAfghanistan'),
        al: t('PhAlbania'),
        dz: t('PhAlgeria'),
        ad: t('PhAndorra'),
        ao: t('PhAngola'),
        ag: t('PhAntiguaAndBarbuda'),
        ar: t('PhArgentina'),
        am: t('PhArmenia'),
        aw: t('PhAruba'),
        au: t('PhAustralia'),
        at: t('PhAustria'),
        az: t('PhAzerbaijan'),
        bs: t('PhBahamas'),
        bh: t('PhBahrain'),
        bd: t('PhBangladesh'),
        bb: t('PhBarbados'),
        by: t('PhBelarus'),
        be: t('PhBelgium'),
        bz: t('PhBelize'),
        bj: t('PhBenin'),
        bt: t('PhBhutan'),
        bo: t('PhBolivia'),
        ba: t('PhBosniaAndHerzegovina'),
        bw: t('PhBotswana'),
        br: t('PhBrazil'),
        io: t('PhBritishIndianOceanTerritory'),
        bn: t('PhBrunei'),
        bg: t('PhBulgaria'),
        bf: t('PhBurkinaFaso'),
        bi: t('PhBurundi'),
        kh: t('PhCambodia'),
        cm: t('PhCameroon'),
        ca: t('PhCanada'),
        cv: t('PhCapeVerde'),
        bq: t('PhCaribbeanNetherlands'),
        cf: t('PhCentralAfricanRepublic'),
        td: t('PhChad'),
        cl: t('PhChile'),
        cn: t('PhChina'),
        co: t('PhColombia'),
        km: t('PhComoros'),
        cd: t('PhDemocraticRepublicCongo'),
        cg: t('PhCongo'),
        cr: t('PhCostaRica'),
        ci: t('PhCotedIvoire'),
        hr: t('PhCroatia'),
        cu: t('PhCuba'),
        cw: t('PhCuraçao'),
        cy: t('PhCyprus'),
        cz: t('PhCzechRepublic'),
        dk: t('PhDenmark'),
        dj: t('PhDjibouti'),
        dm: t('PhDominica'),
        do: t('PhDominicanRepublic'),
        ec: t('PhEcuador'),
        eg: t('PhEgypt'),
        sv: t('PhElSalvador'),
        gq: t('PhEquatorialGuinea'),
        er: t('PhEritrea'),
        ee: t('PhEstonia'),
        et: t('PhEthiopia'),
        fj: t('PhFiji'),
        fi: t('PhFinland'),
        fr: t('PhFrance'),
        gf: t('PhFrenchGuiana'),
        pf: t('PhFrenchPolynesia'),
        ga: t('PhGabon'),
        gm: t('PhGambia'),
        ge: t('PhGeorgia'),
        de: t('PhGermany'),
        gh: t('PhGhana'),
        gr: t('PhGreece'),
        gd: t('PhGrenada'),
        gp: t('PhGuadeloupe'),
        gu: t('PhGuam'),
        gt: t('PhGuatemala'),
        gn: t('PhGuinea'),
        gw: t('PhGuineaBissau'),
        gy: t('PhGuyana'),
        ht: t('PhHaiti'),
        hn: t('PhHonduras'),
        hk: t('PhHongKong'),
        hu: t('PhHungary'),
        is: t('PhIceland'),
        in: t('PhIndia'),
        id: t('PhIndonesia'),
        ir: t('PhIran'),
        iq: t('PhIraq'),
        ie: t('PhIreland'),
        il: t('PhIsrael'),
        it: t('PhItaly'),
        jm: t('PhJamaica'),
        jp: t('PhJapan'),
        jo: t('PhJordan'),
        kz: t('PhKazakhstan'),
        ke: t('PhKenya'),
        ki: t('PhKiribati'),
        xk: t('PhKosovo'),
        kw: t('PhKuwait'),
        kg: t('PhKyrgyzstan'),
        la: t('PhLaos'),
        lv: t('PhLatvia'),
        lb: t('PhLebanon'),
        ls: t('PhLesotho'),
        lr: t('PhLiberia'),
        ly: t('PhLibya'),
        li: t('PhLiechtenstein'),
        lt: t('PhLithuania'),
        lu: t('PhLuxembourg'),
        mo: t('PhMacau'),
        mk: t('PhMacedonia'),
        mg: t('PhMadagascar'),
        mw: t('PhMalawi'),
        my: t('PhMalaysia'),
        mv: t('PhMaldives'),
        ml: t('PhMali'),
        mt: t('PhMalta'),
        mh: t('PhMarshallIslands'),
        mq: t('PhMartinique'),
        mr: t('PhMauritania'),
        mu: t('PhMauritius'),
        mx: t('PhMexico'),
        fm: t('PhMicronesia'),
        md: t('PhMoldova'),
        mc: t('PhMonaco'),
        mn: t('PhMongolia'),
        me: t('PhMontenegro'),
        ma: t('PhMorocco'),
        mz: t('PhMozambique'),
        mm: t('PhMyanmar'),
        na: t('PhNamibia'),
        nr: t('PhNauru'),
        np: t('PhNepal'),
        nl: t('PhNetherlands'),
        nc: t('PhNewCaledonia'),
        nz: t('PhNewZealand'),
        ni: t('PhNicaragua'),
        ne: t('PhNiger'),
        ng: t('PhNigeria'),
        kp: t('PhNorthKorea'),
        no: t('PhNorway'),
        om: t('PhOman'),
        pk: t('PhPakistan'),
        pw: t('PhPalau'),
        ps: t('PhPalestine'),
        pa: t('PhPanama'),
        pg: t('PhPapuaNewGuinea'),
        py: t('PhParaguay'),
        pe: t('PhPeru'),
        ph: t('PhPhilippines'),
        pl: t('PhPoland'),
        pt: t('PhPortugal'),
        pr: t('PhPuertoRico'),
        qa: t('PhQatar'),
        re: t('PhRéunion'),
        ro: t('PhRomania'),
        ru: t('PhRussia'),
        rw: t('PhRwanda'),
        kn: t('PhSaintKittsAndNevis'),
        lc: t('PhSaintLucia'),
        vc: t('PhSaintVincentAndtheGrenadines'),
        ws: t('PhSamoa'),
        sm: t('PhSanMarino'),
        st: t('PhSaoTomevePrincipe'),
        sa: t('PhSaudiArabia'),
        sn: t('PhSenegal'),
        rs: t('PhSerbia'),
        sc: t('PhSeychelles'),
        sl: t('PhSierraLeone'),
        sg: t('PhSingapore'),
        sk: t('PhSlovakia'),
        si: t('PhSlovenia'),
        sb: t('PhSolomonIslands'),
        so: t('PhSomalia'),
        za: t('PhSouthAfrica'),
        kr: t('PhSouthKorea'),
        ss: t('PhSouthSudan'),
        es: t('PhSpain'),
        lk: t('PhSriLanka'),
        sd: t('PhSudan'),
        sr: t('PhSuriname'),
        sz: t('PhSwaziland'),
        se: t('PhSweden'),
        ch: t('PhSwitzerland'),
        sy: t('PhSyria'),
        tw: t('PhTaiwan'),
        tj: t('PhTajikistan'),
        tz: t('PhTanzania'),
        th: t('PhThailand'),
        tl: t('PhTimorLeste'),
        tg: t('PhTogo'),
        to: t('PhTonga'),
        tt: t('PhTrinidadAndTobago'),
        tn: t('PhTunisia'),
        tr: t('PhTurkey'),
        tm: t('PhTurkmenistan'),
        tv: t('PhTuvalu'),
        ug: t('PhUganda'),
        ua: t('PhUkraine'),
        ae: t('PhUnitedArabEmirates'),
        gb: t('PhUnitedKingdom'),
        us: t('PhUnitedStates'),
        uy: t('PhUruguay'),
        uz: t('PhUzbekistan'),
        vu: t('PhVanuatu'),
        va: t('PhVaticanCity'),
        ve: t('PhVenezuela'),
        vn: t('PhVietnam'),
        ye: t('PhYemen'),
        zm: t('PhZambia'),
        zw: t('PhZimbabwe'),
      }}
      onChange={onChange}
      templates={templates}
      {...restProps}
    />
  );
}

const Container = styled(PhoneInput)`
  height: 50px;
  border-radius: ${(x) => x.theme.global.borderRadius};

  border: 0px;
  border-color: transparent !important;
  box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;

  .ant-input {
    height: 50px;
    background-color: transparent;
    border: 0px;

    &:focus,
    &:hover,
    &:active {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .country-list {
    .search {
      padding: 8px 4px 8px 8px;
    }
  }

  ${({ templates }) =>
    templates?.includes('grey') &&
    css`
      background-color: ${(x) => x.theme.colors.antiFlashWhiteDark};
      .country-list {
        background-color: ${(x) => x.theme.colors.antiFlashWhiteDark} !important;
        .search {
          background-color: inherit !important;
          .search-box {
            background-color: inherit !important;
          }
        }
      }
    `}

  ${({ value }) =>
    value &&
    css`
      box-shadow: inset 4px 4px 4px 0px ${(x) => x.theme.colors.lavenderGray} !important;
    `}
`;
