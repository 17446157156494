import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function UserLoginEmailInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'input',
      name: 'email',
      label: t('Email'),
      placeholder: t('EmailEmpty'),
      rules: [
        { required: true, message: t('EmailEmpty') },
        {
          type: 'email',
          message: t('EmailInvalid'),
          min: 3,
          max: 500,
        },
      ],
      templates: ['grey'],
    },
    {
      type: 'input-password',
      name: 'password',
      label: t('Password'),
      placeholder: t('PasswordEmpty'),
      rules: [
        { required: true, message: t('PasswordEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
      templates: ['grey'],
    },
  ];
}
