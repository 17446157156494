import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CountryType, NoticeGroupType, NoticeInputs, UserRoleActionType, UserRoleType } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { noticeCreate, userList } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Element } from '../../globals';

export default function AdverseEvent() {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [user] = useCacheUser();

  const [users, setUsers] = useState([]);
  const [pageState, setPageState] = useState({ form: true });

  const [filter] = useState({
    pageNumber: 1,
    dataLimit: 9999,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: user?.c,
    userRole: UserRoleType.Patient,
  });

  // Hooks
  useEffect(() => {
    if (user.checkUserRole(UserRoleType.Patient, UserRoleActionType.List)) {
      load();
    }
  }, []);

  // Functions
  const load = async () => {
    await userList(filter, (status, res) => {
      if (status) {
        setUsers(res.data);
      }
    });
  };

  // Events
  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, country: user.c, group: NoticeGroupType.AdverseEvent };
    await noticeCreate(model, (status, res) => {
      if (status) {
        formRef.resetFields();
        notifySuccess(t('MessageSendCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Container>
      <Element
        key="notice-adverse-event-form"
        onFinish={onFinish}
        ready={pageState.form}
        formRef={formRef}
        inputs={NoticeInputs({ type: 'adverse-event', users })}
        columnSize={24}
        submit={t('Confirm')}
      />
    </Container>
  );
}

const Container = styled.div``;

const Disclaimer = styled.div`
  margin-top: 24px;
  font-size: 12px;
`;
