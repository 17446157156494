const LocalizationTypes = [
  {
    value: 0,
    name: 'Global',
    displayName: 'LocalizationTypeGlobal',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Country',
    displayName: 'LocalizationTypeCountry',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  }
];

export default LocalizationTypes;