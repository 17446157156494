import { useTranslation } from 'react-i18next';
import { TreatmentStageType, TreatmentStageTypes, TreatmentStatusType, TreatmentStatusTypes } from '../../../../../constants';

export default function EnspryngInputs({ status, onStatusChange, stage, onStageChange, lastStatuses }) {
  const { t } = useTranslation();

  return [
    {
      type: 'select',
      name: 'status',
      label: t('TreatmentStatus'),
      placeholder: t('SelectTreatmentStatus'),
      rules: [{ required: true, message: t('TreatmentStatusEmpty') }],
      data: TreatmentStatusTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onStatusChange,
    },
    status === TreatmentStatusType.Continue && {
      type: 'select',
      name: 'stage',
      label: t('TreatmentStage'),
      onChange: onStageChange,
      placeholder: t('SelectTreatmentStage'),
      rules: [{ required: true, message: t('TreatmentStageEmpty') }],
      data: TreatmentStageTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    status === TreatmentStatusType.Continue &&
      stage === TreatmentStageType.StandartTreatment && {
        type: 'number',
        name: 'lastCount',
        label: t('NumberOfLastInjection'),
        placeholder: t('NumberOfLastInjectionEmpty'),
        rules: [{ required: true, message: t('NumberOfLastInjectionEmpty') }],
      },
    status === TreatmentStatusType.Continue &&
      stage !== undefined &&
      stage !== null &&
      stage !== TreatmentStageType.StandartTreatment && {
        type: 'select',
        name: 'lastStatus',
        label: t('TreatmentLastStatus'),
        placeholder: t('SelectTreatmentLastStatus'),
        rules: [{ required: true, message: t('TreatmentLastStatusEmpty') }],
        data: lastStatuses?.map((x) => ({ ...x, text: t(x.displayName) })),
      },
  ];
}
