import { Radio } from 'antd';
import styled, { css } from 'styled-components';

export default function RadioBase({ onChange, value, ...restProps }) {
  return <RadioContainer onChange={onChange} value={value} {...restProps} />;
}

RadioBase.Group = ({ options, onChange, value, optionType, ...restProps }) => (
  <RadioGroupContainer options={options} onChange={onChange} value={value} optionType={optionType} {...restProps} />
);

const RadioContainer = styled(Radio)``;

const RadioGroupContainer = styled(Radio.Group)`
  ${({ optionType }) =>
    optionType === 'button' &&
    css`
      display: flex;
      align-items: center;

      .ant-radio-button-wrapper {
        height: 50px;
        display: flex;
        align-items: center;
        color: ${(x) => x.theme.colors.lavenderGray};
        border: 1px solid ${(x) => x.theme.colors.lavenderGray};
        border-radius: 10px;

        &:not(:first-child) {
          margin-left: 8px;
        }

        &:before {
          display: none;
        }

        &:hover {
          box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
        }

        &.ant-radio-button-wrapper-checked {
          border-color: ${(x) => x.theme.colors.mediumSpringGreen};
          border-right-color: ${(x) => x.theme.colors.mediumSpringGreen} !important;
          color: ${(x) => x.theme.colors.mediumSpringGreen};

          &:not(.ant-radio-button-wrapper-disabled):focus-within {
            box-shadow: none;
          }
        }
      }
    `}
`;
