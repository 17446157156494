import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { vasScoreMyList } from '../../../services/api';
import { Card, LineChart, Link } from '../../globals';

export default function VasScoreChart({ initialData, fullWidth }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [pageState, setPageState] = useState({ list: false });

  // Hooks
  useEffect(() => {
    if (initialData === undefined) {
      list();
    } else {
      setData(generateData(initialData));
      setPageState((x) => ({ ...x, list: true }));
    }
  }, [initialData]);

  // Functions
  const generateData = (data) => {
    return data.map((x) => ({ ...x, modificationTime: moment(x.modificationTime).format('ll') }));
  };

  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await vasScoreMyList({}, (status, res) => {
      if (status) {
        setData(generateData(res.data));
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'vas-score-chart', 'with-header', 'header-information', 'header-colored', fullWidth ? 'full-width' : '']}
      information={t('VasScoreHistoryInformation')}
      title={t('VasScoreHistory')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      {data && (
        <LineChart
          margin={{
            top: 20,
            right: 50,
            left: 5,
            bottom: 10,
          }}
          data={data}
          xAxisDataKey="modificationTime"
          lineDataKey="point"
        />
      )}
    </Card>
  );
}
