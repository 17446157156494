import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryTypes } from '../../constants';

export default function DeliveryFlowInputs({ country, isAllCountry, isUpdate }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    !isAllCountry && {
      type: 'hidden',
      name: 'country',
      initialValue: country && parseInt(country),
    },
    isAllCountry && {
      type: 'select',
      name: 'country',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: country && parseInt(country),
      disabled: isUpdate,
    },
    {
      type: 'input',
      name: 'nameKey',
      label: t('NameKey'),
      placeholder: t('NameKeyEmpty'),
      rules: [
        { required: true, message: t('NameKeyEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
    },
    {
      type: 'select',
      name: 'isActive',
      label: t('Activity'),
      placeholder: t('SelectActivity'),
      rules: [{ required: true, message: t('IsActiveEmpty') }],
      data: [
        { value: true, text: t('Active') },
        { value: false, text: t('Passive') },
      ],
      initialValue: true,
    },
  ];
}
