import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import styled from 'styled-components';
import { Button } from '../../globals';

export default function DeliveryRedirect({ redirects, onSelect }) {
  // Definitions
  const { t } = useTranslation();
  const [ready, setReady] = useState(true);

  // Hooks
  useEffect(() => {
    if (redirects) {
      setReady(true);

      if (redirects.length === 1) {
        onSelectRedirect(redirects[0]);
      }
    }
  }, []);

  // Events
  const onSelectRedirect = (drug) => {
    setTimeout(() => {
      setReady(false);

      setTimeout(() => {
        onSelect(drug);
      }, 1000);
    }, 1000);
  };

  // Render
  return (
    <Container>
      {redirects?.map((x, i) => {
        return (
          <Button key={`redirect-${i}`} onClick={() => onSelectRedirect(x)} icon={<FaCaretRight />} templates={['white-full-width', 'with-icon']} ready={ready}>
            {t(x.nameKey)}
          </Button>
        );
      })}
    </Container>
  );
}

const Container = styled.div``;
