import { useTranslation } from 'react-i18next';
import { LanguageTypes, LocalizationTypes } from '../../constants';

export default function LocalizationInputs({ isAllCountry, isUpdate }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    isUpdate && {
      type: 'hidden',
      name: 'currentKey',
    },
    {
      type: 'input',
      name: 'key',
      label: t('LocalizationKey'),
      placeholder: t('LocalizationKeyEmpty'),
      rules: [{ required: true, message: t('LocalizationKeyEmpty') }],
    },
    !isAllCountry && {
      type: 'hidden',
      name: 'type',
    },
    isAllCountry && {
      type: 'select',
      name: 'type',
      label: t('LocalizationType'),
      placeholder: t('LocalizationTypeEmpty'),
      rules: [{ required: true, message: t('LocalizationTypeEmpty') }],
      data: LocalizationTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      name: 'localizations',
      templates: 'full-width',
      elements: LanguageTypes.map((x) => ({
        type: 'input',
        name: x.value,
        label: t(`${x.name}Translation`),
        placeholder: t(`${x.name}TranslationEmpty`),
        rules: [{ required: true, message: t(`${x.name}TranslationEmpty`) }],
      })),
    },
    isAllCountry && {
      type: 'switch',
      name: 'isAllCountry',
      label: t('LocalizationIsAllCountry'),
      templates: ['horizontal-item'],
    },
  ];
}
