import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserParentColumns, UserRoleTypes } from '../../../constants';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { userList, userRelationList, userRelationUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { updateResponse } from '../../../utilies/stateManagement';
import { Select, Table } from '../../globals';

export default function UserParent({ currentUser }) {
  // Definitions
  const { t } = useTranslation();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [pageState, setPageState] = useState({
    list: true,
    create: true,
    update: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: true,
    orderBy: ['modificationTime|desc'],
    country: currentUser?.country,
  });

  const [relationIdFilter] = useState({
    currentUserRole: currentUser.userRole,
    userRole: filter.userRole,
    country: currentUser.country,
    relationUserId: currentUser.id,
    isDeleted: false,
  });

  const userRoles = user.getUserRoles();

  // Hooks
  useEffect(() => {
    if (currentUser && filter.userRole) {
      list();
    }
  }, [currentUser, filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let [relationResponse, userReponse] = await Promise.all([
      new Promise((resolve, reject) => {
        userRelationList(relationIdFilter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
      new Promise((resolve, reject) => {
        userList(filter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
    ]);

    userReponse.data = userReponse.data.map((x) => ({ ...x, isRelated: relationResponse.data.some((r) => x.id === r.userId) }));

    setResponse(userReponse);

    setPageState((x) => ({ ...x, list: true }));
  };

  // Events
  const onRelationChange = async (val, row) => {
    setResponse(updateResponse(response, 'isRelated', val, true, row));

    let model = {
      currentUserRole: currentUser.userRole,
      country: currentUser.country,
      userId: row.id,
      userRole: row.userRole,
      relationUserId: currentUser.id,
      relationUserRole: currentUser.userRole,
      isActive: val,
    };

    await userRelationUpdate(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      } else {
        val = !val;
      }

      setResponse(updateResponse(response, 'isRelated', val, false, row));
    });
  };

  return (
    <Container>
      <FilterText>{t('ParentFilter')}</FilterText>
      <Select
        key="user-role-filter"
        data={UserRoleTypes.filter((x) => userRoles.some((u) => u === x.value) && currentUser?.userRole !== x.value).map((x) => ({ ...x, text: t(x.displayName) }))}
        onChange={userRoles.length > 0 && ((userRole) => setFilter((x) => ({ ...x, userRole })))}
        placeholder={t('SelectUserRole')}
        defaultValue={filter.userRole}
        value={filter.userRole}
        allowClear={false}
        templates={['full-width']}
      />

      <Table
        style={{ marginTop: 24 }}
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        columns={UserParentColumns({ onRelationChange })}
        templates={['drawer']}
      />
    </Container>
  );
}

const Container = styled.div``;

const FilterText = styled.div`
  font-weight: bold;
  padding: 0 0 8px;
  font-weight: bold;
`;
