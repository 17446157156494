import antLocaleTR from 'antd/es/locale/tr_TR';
import antLocaleEN from 'antd/lib/locale/en_GB';
import antLocaleES from 'antd/lib/locale/es_ES';
import antLocaleRU from 'antd/lib/locale/ru_RU';
import antLocaleSR from 'antd/lib/locale/sr_RS';
import antLocaleTW from 'antd/lib/locale/zh_TW';
import momentLocaleEN from 'moment/locale/en-gb';
import momentLocaleES from 'moment/locale/es';
import momentLocaleRU from 'moment/locale/ru';
import momentLocaleSR from 'moment/locale/sr';
import momentLocaleTR from 'moment/locale/tr';
import momentLocaleTW from 'moment/locale/zh-tw';
import { LanguageType, LanguageTypes } from '../constants';
import i18n from '../i18n';
import { getItem } from '../services/cache';

export const getTranslate = (key) => {
  return i18n.t(key);
};

export const getMomentLocalization = (code) => {
  switch (code ?? getItem('lcl')) {
    case 'es':
      return momentLocaleES;
    case 'ru':
      return momentLocaleRU;
    case 'sr':
      return momentLocaleSR;
    case 'tw':
      return momentLocaleTW;
    case 'tr':
      return momentLocaleTR;
    default:
    case 'en':
      return momentLocaleEN;
  }
};

export const getAntLocalization = (code) => {
  switch (code ?? getItem('lcl')) {
    case 'es':
      return antLocaleES;
    case 'ru':
      return antLocaleRU;
    case 'sr':
      return antLocaleSR;
    case 'tw':
      return antLocaleTW;
    case 'tr':
      return antLocaleTR;
    default:
    case 'en':
      return antLocaleEN;
  }
};

export const getLanguageType = (code) => {
  let result = LanguageTypes.find((x) => x.data.code === code);
  if (result) return result.value;

  return LanguageType.English;
};
