import { Calendar } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Card, Link } from '../../globals';

export default function CalendarBase({ value, onSelect, onPanelChange, dateCellRender, monthCellRender }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Card
      ready={true}
      templates={['widget', 'calendar', 'with-header', 'header-information', 'header-colored']}
      information={t('CalendarInformation')}
      title={t('Calendar')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      <Container value={value} onSelect={onSelect} onPanelChange={onPanelChange} dateCellRender={dateCellRender} monthCellRender={monthCellRender} />
    </Card>
  );
}

const Container = styled(Calendar)`
  .ant-picker-calendar-date-content {
    height: initial !important;
    min-height: 120px;
  }
`;
