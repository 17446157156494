import {
  FaBookReader,
  FaCaretRight,
  FaFlag,
  FaHandHoldingMedical,
  FaHome,
  FaHospitalUser,
  FaTruck,
  FaUserAlt,
  FaUserCircle,
  FaUserEdit,
  FaUserMd,
  FaUserNurse,
  FaUsersCog,
  FaUserShield,
  FaUserTag,
  FaUserTie,
  FaVideo,
} from 'react-icons/fa';
import { GiMedicines } from 'react-icons/gi';
import { HiTemplate } from 'react-icons/hi';
import { RiAdminFill, RiHeartPulseFill, RiMailFill, RiMailSettingsFill, RiUploadCloudFill, RiUserHeartFill } from 'react-icons/ri';
import { TbEdit } from 'react-icons/tb';
import { TiFlowChildren } from 'react-icons/ti';

import { Layout, Menu } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImUserPlus } from 'react-icons/im';
import { MdLocationCity } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { ActionType, ActionTypes, UserRoleActionType, UserRoleType, UserRoleTypes } from '../../../constants';
import { Link } from '../../globals';

const { Sider } = Layout;

export default function SidebarBase({ user, size }) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);

  const getRoleIcon = (role) => {
    switch (role) {
      case UserRoleType.Owner:
      case UserRoleType.Administrator:
      case UserRoleType.Manager:
        return <RiAdminFill />;
      case UserRoleType.Editor:
        return <FaUserEdit />;
      case UserRoleType.PSP:
        return <FaUserTie />;
      case UserRoleType.Doctor:
        return <FaUserMd />;
      case UserRoleType.Nurse:
        return <FaUserNurse />;
      case UserRoleType.Pharmacist:
        return <FaUserTag />;
      case UserRoleType.Warehouse:
      case UserRoleType.IMS:
        return <FaUserShield />;
      case UserRoleType.Patient:
        return <RiUserHeartFill />;

      default:
        return <FaUserAlt />;
    }
  };

  const getMenuItems = () => {
    let userRoleActions = ActionTypes.filter((x) => x.data?.userRoleActionType === UserRoleActionType.List && user?.checkAction(x.value)).map((x) => {
      x.userRoleData = UserRoleTypes.find((f) => f.value === x.data.userRole);
      return x;
    });

    let items = [
      {
        key: 'home',
        icon: <FaHome />,
        label: <Link to="/">{t('Home')}</Link>,
      },
    ];

    if (userRoleActions) {
      items = [
        ...items,
        ...userRoleActions.map((x, i) => ({
          key: `user-${i}`,
          icon: getRoleIcon(x.data.userRole),
          label: <Link to={`/user/list/${x.data.userRole}`}>{t(`Menu${x.userRoleData.displayName}`)}</Link>,
        })),
      ];
    }

    if (user?.checkAction(ActionType.JourneyPage)) {
      items.push({
        key: 'journey',
        icon: <GiMedicines />,
        label: <Link to="/journey">{t('Journey')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.TreatmentList)) {
      items.push({
        key: 'treatments',
        icon: <FaHandHoldingMedical />,
        label: <Link to="/treatment/list">{t('Treatments')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.ConferenceList)) {
      items.push({
        key: 'conferences',
        icon: <FaVideo />,
        label: <Link to="/conference/list">{t('Conferences')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.VisitList)) {
      items.push({
        key: 'visit',
        icon: <FaHospitalUser />,
        label: <Link to="/visit/list">{t('Visits')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.ContentPage)) {
      items.push({
        key: 'content',
        icon: <FaBookReader />,
        label: <Link to="/contents">{t('Contents')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.UserInviteList)) {
      items.push({
        key: 'invite',
        icon: <ImUserPlus />,
        label: <Link to="/user/invite">{t('UserInvite')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.RoleList)) {
      items.push({
        key: 'roles',
        icon: <FaUsersCog />,
        label: <Link to="/role/list">{t('Roles')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.CityList)) {
      items.push({
        key: 'cities',
        icon: <MdLocationCity />,
        label: <Link to="/city/list">{t('Cities')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.LocalizationList)) {
      items.push({
        key: 'localizations',
        icon: <FaFlag />,
        label: <Link to="/localization/list">{t('Localizations')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.TemplateList)) {
      items.push({
        key: 'templates',
        icon: <HiTemplate />,
        label: <Link to="/template/list">{t('Templates')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.DeliveryFlowList)) {
      items.push({
        key: 'delivery-flows',
        icon: <TiFlowChildren />,
        label: <Link to="/delivery/flow/list">{t('DeliveryFlows')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.DeliveryList) && user?.dd) {
      items.push({
        key: 'deliveries',
        icon: <FaTruck />,
        label: <Link to="/delivery/list">{t('Deliveries')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.VitalList)) {
      items.push({
        key: 'vitals',
        icon: <RiHeartPulseFill />,
        label: <Link to="/vital/list">{t('Vitals')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.ContentList)) {
      items.push({
        key: 'contents',
        icon: <TbEdit />,
        label: <Link to="/content/list">{t('Contents')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.NoticeContactList)) {
      items.push({
        key: 'noticeContacts',
        icon: <RiMailSettingsFill />,
        label: <Link to="/notice/contact/list">{t('NoticeContacts')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.NoticeList)) {
      items.push({
        key: 'notices',
        icon: <RiMailFill />,
        label: <Link to="/notice/list">{t('Notices')}</Link>,
      });
    }

    if (user?.checkAction(ActionType.StorageList)) {
      items.push({
        key: 'storages',
        icon: <RiUploadCloudFill />,
        label: <Link to="/storage/list">{t('Storage')}</Link>,
      });
    }

    items.push({
      key: 'profile',
      icon: <FaUserCircle />,
      label: <Link to="/profile">{t('Profile')}</Link>,
    });

    return items;
  };

  return (
    <Aside
      collapsible={size?.width <= 1000}
      collapsed={collapsed && size?.width <= 1000}
      onCollapse={(value) => setCollapsed(value)}
      trigger={
        <SiderTrigger collapsed={collapsed && size?.width <= 1000}>
          <FaCaretRight />
        </SiderTrigger>
      }
    >
      <LogoContainer collapsed={collapsed && size?.width <= 1000}>
        <Link to="/">
          <img src="/assets/images/logo.svg" alt="Logo" />
        </Link>
      </LogoContainer>
      <SideMenu defaultSelectedKeys={['1']} mode="inline" items={getMenuItems()} />
    </Aside>
  );
}

const Aside = styled(Sider)`
  width: 350px !important;
  flex: 0 0 350px !important;
  max-width: 350px !important;
  min-width: 350px !important;
  transition: all ease-in-out 0.1s;

  height: 100vh;
  position: fixed;
  z-index: 999;
  overflow: hidden;
  background: url(/assets/images/layout/menu-bg.jpg) left top no-repeat;
  background-size: cover;
  background-color: #fff;
  box-shadow: 0px 0px 6px 1px ${(x) => x.theme.colors.deepKaomaru};

  .ant-layout-sider-trigger {
    width: 350px !important;
    background-color: ${(x) => x.theme.colors.deepKaomaru};
    color: ${(x) => x.theme.colors.white};
  }

  &.ant-layout-sider-collapsed {
    width: 60px !important;
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;

    .ant-layout-sider-trigger {
      width: 60px !important;
    }
  }

  @media only screen and (max-width: 1366px) {
    width: 300px !important;
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;

    .ant-layout-sider-trigger {
      width: 300px !important;
      background-color: ${(x) => x.theme.colors.blue};
      color: ${(x) => x.theme.colors.white};
    }
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  height: 60px;
  top: 30px;
  left: 30px;
  transition: left ease-in-out 0.2s;

  a {
    display: block;
    height: 100%;
    img {
      transition: height ease-in-out 0.2s;
      height: 100%;
    }
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      @media only screen and (max-width: 1000px) {
        left: 8px;

        img{
          height:70% !important;
        }
      }
    `}
`;

const SideMenu = styled(Menu)`
  overflow-x: hidden;
  height: 100%;
  margin-top: 120px;
  max-height: calc(100% - 150px);
  border-right: 0px;
  color: ${(x) => x.theme.colors.lavenderGray};
  background: transparent;
  direction: rtl;
  ::-webkit-scrollbar {
    width: 2px;
  }

  .ant-menu-item {
    padding: 32px 0px;
    margin: 0px !important;
    direction: ltr;
    a {
      color: ${(x) => x.theme.colors.lavenderGray};
      &:hover {
        text-decoration: none;
      }
    }

    .ant-menu-item-icon {
      color: ${(x) => x.theme.colors.lavenderGray};
      font-size: 30px;
    }

    &.ant-menu-item-selected {
      background-color: transparent;
      &::after {
        display: none;
      }
    }

    &:active,
    .ant-menu-submenu-title:active {
      background: transparent;
    }

    &:hover {
      color: ${(x) => x.theme.colors.mediumSpringGreen};

      .ant-menu-item-icon {
        color: ${(x) => x.theme.colors.mediumSpringGreen};
      }

      a {
        color: ${(x) => x.theme.colors.mediumSpringGreen};
      }
    }
  }
`;

const SiderTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    font-size: 30px;
  }

  ${({ collapsed }) =>
    !collapsed &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
