import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { NoticeType } from '../../constants';

export default function NoticeInputs({ type, isDetail, users }) {
  const { t } = useTranslation();

  return [
    !isDetail &&
      users?.length > 0 &&
      type === 'adverse-event' && {
        type: 'select',
        name: 'targetUserId',
        label: t('RolePatient'),
        placeholder: t('SelectRolePatient'),
        rules: [{ required: true, message: t('SelectRolePatientEmpty') }],
        data: users.map((x) => ({ value: x.id, text: x.fullName })),
      },
    !isDetail &&
      type === 'adverse-event' && {
        type: 'hidden',
        name: 'type',
        initialValue: NoticeType.AdverseEvent,
      },
    !isDetail &&
      type === 'ask-for-help' && {
        type: 'hidden',
        name: 'type',
        initialValue: NoticeType.AskForHelpTechnicalProblem,
      },
    isDetail && {
      type: 'input',
      name: ['user', 'firstName'],
      label: t('UserFirstName'),
      readOnly: isDetail,
    },
    isDetail && {
      type: 'input',
      name: ['user', 'lastName'],
      label: t('UserLastName'),
      readOnly: isDetail,
    },
    {
      type: 'textarea',
      name: 'message',
      label: t('NoticeMessage'),
      placeholder: t('NoticeMessageEmpty'),
      rules: [
        { required: !isDetail, message: t('NoticeMessageEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 1, 500) },
      ],
      readOnly: isDetail,
    },
  ];
}
