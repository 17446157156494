import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTruck } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { Card, Page } from '../../components/globals';
import DeliveryCreateProcess from '../../components/pages/delivery/DeliveryCreateProcess';
import { CountryTypes, DrugTypes, RoleFieldDiseaseTypes, RoleFieldType, UserRoleTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { deliveryCreate, deliveryFlowListGlobal, userDetail } from '../../services/api';
import { removeItem } from '../../services/cache';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function DeliveryCreate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { countryType, userRoleType, id } = useParams();
  const [user] = useCacheUser();

  const userRole = UserRoleTypes.find((x) => x.value === parseInt(userRoleType ?? user.u));
  const country = CountryTypes.find((x) => x.value === parseInt(countryType ?? user.c));

  const [response, setResponse] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const [pageState, setPageState] = useState({
    detail: false,
    form: true,
  });

  const [userFilter] = useState({
    id,
    includeFields: true,
    includeCity: true,
    country: country.value,
    userRole: userRole.value,
  });

  const [deliveryFlowFilter] = useState({
    country: country.value,
    includeSteps: true,
    isActive: true,
    isDeleted: false,
  });

  // Hooks
  useEffect(() => {
    detail();
  }, [userFilter, deliveryFlowFilter]);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, detail: false }));

    if (!user?.dd) {
      notifyError(t('RoleDeliveryDrugNotFoundException'));
      navigate(`/`);
      return;
    }

    let [targetUserResponse, deliveryFlowResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        userDetail(userFilter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
      new Promise((resolve, reject) => {
        deliveryFlowListGlobal(deliveryFlowFilter, (status, res) => {
          if (status) {
            resolve(res);
          } else {
            resolve(null);
          }
        });
      }),
    ]);

    if (targetUserResponse?.data === null) {
      notifyError(t('UserNotFoundException'));
      navigate(`/`);
      return;
    }

    if (deliveryFlowResponse === null || !deliveryFlowResponse.data?.length) {
      notifyError(t('DeliveryFlowNotFoundException'));
      navigate(`/journey/${userRoleType}/${countryType}/${id}`);
      return;
    }

    // Diseases
    let diseases = targetUserResponse.data.fields?.find((x) => x.fieldType === RoleFieldType.DiseaseTypes);

    if (diseases === undefined) {
      notifyError(t('DiseaseNotFoundException'));
      navigate(`/journey/${userRoleType}/${countryType}/${id}`);
      return;
    }

    // Drugs
    let diseaseDrugValues = diseases?.value?.map((x) => RoleFieldDiseaseTypes.find((d) => d.value === x)?.data?.drugs);
    let drugs = [...new Set(diseaseDrugValues.reduce((a, b) => a.concat(b)))].sort().map((x) => DrugTypes.find((d) => d.value === x));

    // Months
    let birthDate = targetUserResponse.data.fields.find((x) => x.fieldType === RoleFieldType.BirthDate);
    if (birthDate) {
      let months = moment().diff(moment(birthDate.value), 'months', true);
      targetUserResponse.data.months = months;
    }

    // Redirects
    let redirects = deliveryFlowResponse.data.filter((x) => x.parentId === null);

    removeItem('delivery');
    setResponse({ country, user: targetUserResponse.data, drugs: drugs, flows: deliveryFlowResponse.data, redirects });
    setPageState((x) => ({ ...x, detail: true }));
  };

  // Events
  const onStepClick = (value) => {
    if (value < currentStep) setCurrentStep(value);
  };

  const onSelectDrug = (drug) => {
    setResponse((x) => ({ ...x, drug }));

    setCurrentStep(1);
  };

  const onSelectFlow = (flow) => {
    setResponse((x) => ({ ...x, flow }));

    setCurrentStep(2);
  };

  const onDetailFinish = (model) => {
    setResponse((x) => ({ ...x, model }));

    setCurrentStep(3);
  };

  const onSelectRedirect = async (redirect) => {
    let model = { ...response.model, country: response.country.value, drug: response.drug.value, deliveryFlowStepId: redirect.id, targetUserId: userFilter.id };

    removeItem('delivery');

    await deliveryCreate(model, (status, res) => {
      if (status) {
        notifySuccess(t('CreateCompleteSuccess'));
        navigate(`/delivery/list`);
      }
    });
  };

  // Render
  return (
    <Page
      title={t('DeliveryCreate')}
      icon={<FaPlus />}
      routes={[
        {
          path: `/delivery/list`,
          name: t('Deliveries'),
          breadcrumbName: t('Deliveries'),
          icon: <FaTruck />,
        },
        {
          name: t('DeliveryCreate'),
          breadcrumbName: `DeliveryCreate`,
          icon: <FaPlus />,
        },
      ]}
    >
      <Card ready={currentStep !== null && pageState.detail} templates={['page']}>
        <DeliveryCreateProcess
          response={response}
          current={currentStep}
          onStepClick={onStepClick}
          onDetailFinish={onDetailFinish}
          onSelectDrug={onSelectDrug}
          onSelectFlow={onSelectFlow}
          onSelectRedirect={onSelectRedirect}
        />
      </Card>
    </Page>
  );
}
