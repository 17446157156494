import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFlag, FaPlus } from 'react-icons/fa';
import { format } from 'react-string-format';
import styled from 'styled-components';
import { Button, Drawer, Element, Extra, FileInput, Page, Select, Table } from '../../components/globals';
import { ActionType, CountryTypes, LocalizationColumns, LocalizationInputs, LocalizationTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import {
  localizationCreate,
  localizationExcelDownload,
  localizationExcelUpload,
  localizationList,
  localizationMultipleDeleteRestore,
  localizationUpdate,
} from '../../services/api';
import { groupByList } from '../../utilies/expression';
import { notifyError, notifySuccess } from '../../utilies/notification';

export default function Localizations() {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();
  const [importFormRef] = Form.useForm();
  const [user] = useCacheUser();

  const isAllCountry = user.checkAction(ActionType.LocalizationAllCountry);

  const [response, setResponse] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formPanelVisiblity, setFormPanelVisibility] = useState(false);
  const [importPanelVisiblity, setImportPanelVisibility] = useState(false);
  const [importFiles, setImportFiles] = useState([]);

  const [pageState, setPageState] = useState({
    list: true,
    createUpdate: true,
    delete: true,
    download: true,
    upload: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 9999999,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['modificationTime|desc'],
    country: parseInt(user.c),
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await localizationList(filter, (status, res) => {
      if (status) {
        let data = [];
        let localizationKeys = groupByList(res.data, 'key');
        localizationKeys.forEach((x) => {
          let model = { key: x.key, type: x.values[0].type };
          x.values.forEach((v) => {
            model = { ...model, ...v, [`lang-${v.language}`]: v.value };
          });

          data.push(model);
        });
        res.data = data;

        setResponse(res);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onCreateClick = () => {
    setIsUpdate(false);
    formRef.resetFields();
    setFormPanelVisibility(true);
  };

  const onUpdateClick = (row) => {
    let model = { currentKey: row.key };

    Object.keys(row).forEach((x) => {
      if (x.includes('lang-')) {
        if (model['localizations'] === undefined) {
          model['localizations'] = {};
        }
        model['localizations'][x.split('lang-')[1]] = row[x];
      } else {
        model[x] = row[x];
      }
    });

    setIsUpdate(true);
    formRef.setFieldsValue(model);
    setFormPanelVisibility(true);
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, delete: false }));

    await localizationMultipleDeleteRestore({ keys: selectedRowKeys, country: filter.country }, (status, res) => {
      if (status) {
        list();

        notifySuccess(format(t(filter.isDeleted ? 'DataRestoreSuccess' : 'DataDeleteSuccess'), <strong>{res.data}</strong>));
      }

      setSelectedRowKeys([]);
      setPageState((x) => ({ ...x, delete: true }));
    });
  };

  const onLocalizationFormFinish = async (model) => {
    if (model.localizations) {
      model.localizations = Object.keys(model.localizations).map((x) => ({ language: x, value: model.localizations[x] }));
    }

    model.country = filter.country;

    setPageState((x) => ({ ...x, form: false }));

    let request = isUpdate ? localizationUpdate : localizationCreate;
    await request(model, (status, res) => {
      if (status) {
        list();
        setFormPanelVisibility(false);
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onDownloadExcelClick = async () => {
    setPageState((x) => ({ ...x, download: false }));

    await localizationExcelDownload(filter.country, (status, res, fileName) => {
      if (status) {
        saveAs(res, fileName);
      }

      setPageState((x) => ({ ...x, download: true }));
    });
  };

  const onUploadExcelClick = async () => {
    if (importFiles?.length <= 0) {
      notifyError(t('LocalizationFileRequired'));
      return;
    }

    const form = new FormData();
    form.append('File', importFiles[0]);

    let formValues = importFormRef.getFieldsValue();
    for (let key of Object.keys(formValues)) {
      form.append(key, formValues[key]);
    }

    setPageState((x) => ({ ...x, upload: false }));
    await localizationExcelUpload(filter.country, form, (status, res) => {
      if (status) {
        list();
        notifySuccess(t('UpdateCompleteSuccess'));
        setImportPanelVisibility(false);
        setFormPanelVisibility(false);
        setImportFiles([]);
      }

      setPageState((x) => ({ ...x, upload: true }));
    });
  };

  const onClickImportExport = () => {
    setImportFiles([]);
    setImportPanelVisibility(true);
  };

  // Render
  return (
    <Page
      title={t('Localizations')}
      icon={<FaFlag />}
      subTitle={
        user.checkAction(ActionType.LocalizationCreate) && (
          <CreatePanel>
            <Button ready={pageState?.createUpdate} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-title', 'with-icon']}>
              {t('AddNew')}
            </Button>
          </CreatePanel>
        )
      }
      routes={[
        {
          name: t('Localizations'),
          breadcrumbName: 'Localizations',
          icon: <FaFlag />,
        },
      ]}
      templates={[isAllCountry ? 'default-2-filter-2' : 'default-1-filter-2']}
      extra={[
        isAllCountry && (
          <Select
            key="country-filter"
            allowClear={false}
            data={CountryTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
            onChange={(country) => setFilter((x) => ({ ...x, country }))}
            placeholder={t('SelectCountry')}
            defaultValue={filter.country}
            value={filter.country}
            templates={['filter']}
          />
        ),
        <Select
          key="type-filter"
          data={LocalizationTypes.map((x) => ({ ...x, text: t(x.displayName) }))}
          onChange={(type) => setFilter((x) => ({ ...x, type }))}
          placeholder={t('SelectLocalizationType')}
          defaultValue={filter.type}
          value={filter.type}
          templates={['filter']}
        />,
        <Extra
          key="filter"
          pageState={pageState}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onFilterChanged={() => setSelectedRowKeys([])}
          onDeleteClick={user.checkAction(ActionType.LocalizationDelete) && onDeleteClick}
        />,
      ]}
    >
      <Table
        rowKey="key"
        ready={pageState.list}
        response={response}
        filter={filter}
        setFilter={setFilter}
        selectedRowKeys={selectedRowKeys}
        onRowSelectChange={(values) => setSelectedRowKeys(values)}
        columns={LocalizationColumns({ isAllCountry, onUpdateClick: user.checkAction(ActionType.LocalizationUpdate) && onUpdateClick })}
      />

      <Drawer title={t(isUpdate ? 'LocalizationUpdate' : 'LocalizationCreate')} visible={formPanelVisiblity} onClose={() => setFormPanelVisibility(false)}>
        <Button key="import" onClick={onClickImportExport} icon={<UploadOutlined />} templates={['colored', 'with-icon', 'full-width', 'mb-24']}>
          {t('ImportExport')}
        </Button>

        <Element
          key="localization-form"
          ready={pageState.form}
          formRef={formRef}
          onFinish={onLocalizationFormFinish}
          inputs={LocalizationInputs({ isAllCountry, isUpdate })}
          columnSize={24}
          submit={t('Confirm')}
        />
      </Drawer>

      <Drawer title={t('LocalizationImportExport')} visible={importPanelVisiblity} onClose={() => setImportPanelVisibility(false)}>
        <Button ready={pageState.download} icon={<DownloadOutlined />} onClick={onDownloadExcelClick} block={true} templates={['primary']}>
          {t('LocalizationExcelDownload')}
        </Button>
        <DownloadDescription>{t('LocalizationExcelDownloadDescription')}</DownloadDescription>

        <FileInput files={importFiles} setFiles={setImportFiles} />

        <Element
          key="localization-import-form"
          ready={pageState.upload}
          formRef={importFormRef}
          inputs={[
            {
              type: 'switch',
              name: 'isAllCountry',
              label: t('LocalizationIsAllCountry'),
              templates: ['horizontal-item'],
              css: `margin-bottom: 0px;`,
            },
          ]}
          columnSize={24}
          style={{ margin: '12px 0px 24px' }}
        />

        <Button ready={pageState.upload} icon={<UploadOutlined />} onClick={onUploadExcelClick} block={true} templates={['colored']}>
          {t('LocalizationExcelImport')}
        </Button>
      </Drawer>
    </Page>
  );
}

const CreatePanel = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadDescription = styled.div`
  margin: 12px 0px 24px;
`;
