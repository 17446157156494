import { Form, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaUserAlt, FaUserEdit, FaUserMd, FaUserNurse, FaUserShield, FaUserTag, FaUserTie } from 'react-icons/fa';
import { RiAdminFill, RiEdit2Fill, RiUserHeartFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { format } from 'react-string-format';
import styled, { css } from 'styled-components';
import { Button, Card, Page } from '../../components/globals';
import UserField from '../../components/pages/user/UserField';
import UserParent from '../../components/pages/user/UserParent';
import { CountryType, CountryTypes, UserRoleActionType, UserRoleType, UserRoleTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { userCreate, userDetail, userUpdate } from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { getUserFieldFormData, setFieldData } from '../../utilies/userField';

const { TabPane } = Tabs;

export default function UserCreateUpdate() {
  // Definitions
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, userRoleType, countryType } = useParams();
  const [searchParams] = useSearchParams();

  const [user] = useCacheUser();
  const [formRef] = Form.useForm();

  const isCreate = id === undefined;
  const userRole = UserRoleTypes.find((x) => x.value === parseInt(userRoleType));
  const country = CountryTypes.find((x) => x.value === parseInt(countryType ?? user.c));

  const [response, setResponse] = useState(null);
  const [relationResponse, setRelationResponse] = useState(null);
  const [pageState, setPageState] = useState({ form: true });

  const [filter, setFilter] = useState({
    id,
    includeFields: true,
    country: country.value,
    userRole: userRole.value,
  });

  const isAllCountry = user.checkUserRole(userRole.value, UserRoleActionType.AllCountry);

  // Hooks
  useEffect(() => {
    if (
      (isCreate && !user.checkUserRole(userRole.value, UserRoleActionType.Create)) ||
      (!isCreate && !user.checkUserRole(userRole.value, UserRoleActionType.Update)) ||
      (!isAllCountry && user.c !== country.value)
    ) {
      setTimeout(() => navigate(`/`, { error: 'UserForbiddenException' }), 500);
      return;
    } else if (filter.id) {
      detail();
      relationDetail();
    }
  }, [id]);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, form: false }));

    await userDetail(filter, (status, res) => {
      if (status) {
        res.data.fields = getUserFieldFormData(res.data.fields, res.data.timeOffset);
        formRef.setFieldsValue(res.data);

        setResponse(res);
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const relationDetail = async () => {
    if (user.c === CountryType.Russian && searchParams) {
      if (
        !user.checkUserRole(UserRoleType.Doctor, UserRoleActionType.List) ||
        (!user.checkUserRole(UserRoleType.Doctor, UserRoleActionType.AllCountry) && user.c !== country.value)
      ) {
        setRelationResponse({ status: false });
        return;
      }

      let relationId = searchParams.get('r');
      if (relationId) {
        await userDetail({ id: relationId, includeFields: true, country: country.value, userRole: UserRoleType.Doctor }, (status, res) => {
          if (status) {
            setRelationResponse({ ...res, status });
          }
        });
      }
    }
  };

  // Events
  const onUserFormFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let request = isCreate ? userCreate : userUpdate;

    if (model.fields) {
      model.fields = setFieldData(model.fields);
    }

    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(isCreate ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setFilter((x) => ({ ...x, id: res.data }));

        let url = `/user/update/${userRole.value}/${country.value}/${res.data}`;
        if (searchParams) {
          url += `?${searchParams?.toString()}`;
        }

        setTimeout(() => navigate(url), 500);
      }
      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Components
  const getRoleIcon = (role) => {
    switch (role) {
      case UserRoleType.Owner:
      case UserRoleType.Administrator:
      case UserRoleType.Manager:
        return <RiAdminFill />;
      case UserRoleType.Editor:
        return <FaUserEdit />;
      case UserRoleType.PSP:
        return <FaUserTie />;
      case UserRoleType.Doctor:
        return <FaUserMd />;
      case UserRoleType.Nurse:
        return <FaUserNurse />;
      case UserRoleType.Pharmacist:
        return <FaUserTag />;
      case UserRoleType.Warehouse:
      case UserRoleType.IMS:
        return <FaUserShield />;
      case UserRoleType.Patient:
        return <RiUserHeartFill />;

      default:
        return <FaUserAlt />;
    }
  };

  // Render
  return (
    <Page
      title={t(isCreate ? 'AddNew' : 'Update')}
      icon={isCreate ? <FaPlus /> : <RiEdit2Fill />}
      routes={[
        {
          path: `/user/list/${userRole.value}`,
          name: t(`Menu${userRole.displayName}`),
          breadcrumbName: 'UserCreateUpdate',
          icon: getRoleIcon(userRole.value),
        },
        {
          name: t(isCreate ? 'AddNew' : 'Update'),
          breadcrumbName: 'CreateUpdate',
          icon: isCreate ? <FaPlus /> : <RiEdit2Fill />,
        },
      ]}
      extra={[
        <Button key="confirm" onClick={() => formRef.submit()} templates={['colored']}>
          {t('Confirm')}
        </Button>,
      ]}
    >
      <Card ready={pageState.form} templates={['page']}>
        {relationResponse && (
          <RelationMessage>
            {relationResponse.status
              ? format(t('UserParentAction'), <strong>{relationResponse.data.fullName}</strong>, <strong>{t('RoleDoctor')}</strong>)
              : t('RelationRoleNotFound')}
          </RelationMessage>
        )}

        <ContentTabs type={isCreate || filter.userRole !== UserRoleType.Patient ? 'create' : 'update'}>
          <TabPane tab={t('Detail')} key="detail">
            <UserField searchParams={searchParams} userRoleType={userRole.value} countryType={country.value} formRef={formRef} onFinish={onUserFormFinish} />
          </TabPane>
          <TabPane tab={t('UserParents')} key="parents">
            {response?.data && <UserParent currentUser={response.data} />}
          </TabPane>
        </ContentTabs>
      </Card>
    </Page>
  );
}

const ContentTabs = styled(Tabs)`
  ${({ type }) =>
    type === 'create' &&
    css`
      .ant-tabs-nav {
        display: none;
      }
    `}

  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        color: ${(x) => x.theme.colors.darkTurquoise};
        &:hover {
          color: ${(x) => x.theme.colors.darkTurquoise};
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${(x) => x.theme.colors.mediumSpringGreen};
          }
        }
      }

      .ant-tabs-ink-bar {
        background: ${(x) => x.theme.colors.mediumSpringGreen};
      }
    }
  }
`;

const RelationMessage = styled.div`
  margin-bottom: 12px;
`;
