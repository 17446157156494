import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export default function DefaultDetailInputs() {
  const { t } = useTranslation();

  return [
    {
      type: 'number',
      name: 'totalBottle',
      label: t('TotalBottle'),
      placeholder: t('TotalBottleEmpty'),
      rules: [{ required: true, message: t('TotalBottleEmpty') }],
    },
    {
      type: 'input',
      name: 'note',
      label: t('Note'),
      placeholder: t('NoteOptional'),
      rules: [{ min: 3, max: 500, message: format(t('LengthValid'), 1, 500) }],
    },
    {
      type: 'input',
      name: 'targetUserAddress',
      label: t('Address'),
      placeholder: t('AddressEmpty'),
      rules: [
        { required: true, message: t('AddressEmpty') },
        { min: 3, max: 500, message: format(t('LengthValid'), 1, 500) },
      ],
    },
  ];
}
