import { Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiMedicines } from 'react-icons/gi';
import styled, { css } from 'styled-components';
import { enspryngCalculator } from '../../../calculators/enspryngCalculator';
import { CalculatorEnspryngInputs, TreatmentLastStatusTypes, TreatmentStatusType } from '../../../constants';
import { Button, Element } from '../../globals';

export default function EnspryngCalculator() {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState(null);
  const [stage, setStage] = useState(null);
  const [lastStatuses, setLastStatuses] = useState(null);

  const [pageState, setPageState] = useState({ form: true });

  // Events
  const onStatusChange = ({ value }) => {
    setStatus(value);
  };

  const onStageChange = ({ value }) => {
    let result = TreatmentLastStatusTypes.filter((x) => x.data.stage === value);

    setStage(value);
    setLastStatuses(result);
  };

  const onCalculateFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    let result = enspryngCalculator(model.status, model.startDate, model.duration, model.stage, model.lastCount, model.lastStatus);

    setResponse(result);
    setPageState((x) => ({ ...x, form: true }));
  };

  // Render
  return (
    <Container>
      <FormContainer visible={response === null}>
        <Element
          key="dose-calculator-form"
          onFinish={onCalculateFinish}
          ready={pageState.form}
          formRef={formRef}
          inputs={CalculatorEnspryngInputs({ status, onStatusChange, stage, onStageChange, lastStatuses })}
          columnSize={status === TreatmentStatusType.Continue ? 12 : 24}
        />

        <Button onClick={() => formRef.submit()} templates={['colored']} block={true}>
          {t('Calculate')}
        </Button>
      </FormContainer>

      {response && (
        <ResultContainer>
          <GiMedicines />
          <Title>{t('TotalBottleAmount')}</Title>
          <AmountResult>
            <Amount>
              {response.bottle}
              <span>{t('Injections')}</span>
            </Amount>
            <Divider></Divider>
            <Amount>
              {response.miligram}
              <span>mg</span>
            </Amount>
          </AmountResult>
          <Title>{t('InjectionList')}</Title>
          <Injections length={response.usages.length}>
            {response.usages.map((x, i) => (
              <Injection key={`injection-${i}`}>
                <Row>
                  {i + 1}. {t('Injection')}
                </Row>
                <Date>{x.format('ll')}</Date>
              </Injection>
            ))}
          </Injections>
          <Button onClick={() => setResponse(null)} templates={['colored', 'full-width', 'absolute-bottom']}>
            {t('CalculateAgain')}
          </Button>
        </ResultContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;

const FormContainer = styled.div`
  height: 100%;

  ${({ visible }) =>
    visible
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  flex-direction: column;
  justify-content: space-between;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  padding-bottom: 50px;

  svg {
    height: 20%;
    margin-top: 12px;
    font-size: 40px;
    color: ${(x) => x.theme.colors.mediumSpringGreen};
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin: 12px 0px;
`;

const AmountResult = styled.div`
  display: flex;
  align-items: center;
`;

const Amount = styled.div`
  font-size: 30px;
  font-weight: bold;
  span {
    font-size: initial;
    font-weight: initial;
  }
`;

const Divider = styled.div`
  margin: 0px 8px;
  width: 24px;
  height: 1px;
  background-color: #000;
  transform: rotate(110deg);
  margin-top: 10px;
`;

const Injections = styled.div`
  min-height: 136px;
  max-height: 230px;
  width: 100%;
  margin-bottom: 12px;
  padding-right: 8px;

  ${({ length }) =>
    length > 3 &&
    css`
      overflow: hidden;
      overflow-y: scroll;
    `}
`;

const Injection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid ${(x) => x.theme.colors.lavenderGray};
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 50px;
`;

const Row = styled.div``;
const Date = styled.div``;
