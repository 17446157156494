import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryTypes, NoticeContactType, NoticeContactTypes, NoticeGroupTypes } from '../../constants';

export default function NoticeContactInputs({ country, isAllCountry, isUpdate, onTypeChange, type }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    isAllCountry && {
      type: 'select',
      name: 'country',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: country && parseInt(country),
      disabled: isUpdate,
    },
    {
      type: 'select',
      name: 'group',
      label: t('NoticeGroupType'),
      placeholder: t('SelectNoticeGroup'),
      rules: [{ required: true, message: t('NoticeGroupTypeEmpty') }],
      data: NoticeGroupTypes.map((x) => ({ ...x, text: t(x.displayName) })),
    },
    {
      type: 'select',
      name: 'type',
      label: t('NoticeContactType'),
      placeholder: t('SelectNoticeContactType'),
      rules: [{ required: true, message: t('NoticeContactTypeEmpty') }],
      data: NoticeContactTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onTypeChange,
    },
    {
      type: type === NoticeContactType.Phone ? 'phone' : 'input',
      name: 'value',
      label: t('NoticeContactValue'),
      placeholder: t('NoticeContactValueEmpty'),
      rules: [
        { required: true, message: t('NoticeContactValueEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 1, 50) },
      ],
    },
    {
      type: 'select',
      name: 'isActive',
      label: t('Activity'),
      placeholder: t('SelectActivity'),
      rules: [{ required: true, message: t('IsActiveEmpty') }],
      data: [
        { value: true, text: t('Active') },
        { value: false, text: t('Passive') },
      ],
      initialValue: true,
    },
  ];
}
