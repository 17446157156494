const colors = {
  white: '#FFF',
  antiFlashWhite: '#F1F6F8',
  antiFlashWhiteDark: '#EDF3F5',
  deepKaomaru: '#29235C',
  mediumSpringGreen: '#28CDAD',
  darkTurquoise: '#299FFF',
  darkTurquoiseHeader: '#299FFF',
  darkTurquoiseOpacity50: 'rgba(41, 159, 255, 50%)',
  darkTurquoiseOpacity20: 'rgba(41, 159, 255, 20%)',
  lavenderGray: '#C7C8D7',
  rustyRed: '#D61E46',
  lightBlue: '#7dd3fc',
  naplesYellow: '#FAD860',
  score0: '#D61E46',
  score1: '#F5515F',
  score2: '#FA735E',
  score3: '#FF955C',
  score4: '#FAD860',
  score5: '#BEDD4E',
  score6: '#8CD944',
  score7: '#4ED944',
  score8: '#00DAA6',
  score9: '#3AF7CB',
};

export default colors;
