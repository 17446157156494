const TemplateTypes = [
  {
    value: 0,
    name: 'CreatePassword',
    displayName: 'CreatePasswordTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'ForgotPassword',
    displayName: 'ForgotPasswordTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'AskForHelp',
    displayName: 'AskForHelpTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'AdverseEvent',
    displayName: 'AdverseEventTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'MissedDose',
    displayName: 'MissedDoseTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  },
  {
    value: 5,
    name: 'DoseRemind',
    displayName: 'DoseRemindTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {}
  },
  {
    value: 6,
    name: 'UserInvite',
    displayName: 'UserInviteTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {}
  },
  {
    value: 7,
    name: 'DeliveryStep',
    displayName: 'DeliveryStepTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {}
  },
  {
    value: 8,
    name: 'DeliveryReject',
    displayName: 'DeliveryRejectTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 900,
    data: {}
  },
  {
    value: 9,
    name: 'LoginTemplate',
    displayName: 'LoginTemplate',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 1000,
    data: {}
  }
];

export default TemplateTypes;