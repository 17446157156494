const RoleFieldDiseaseTypes = [
  {
    value: 0,
    name: 'SMAType1',
    displayName: 'SMAType1',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {
      drugs: [
        0
      ],
      therapeuticAreas: [
        0
      ]
    }
  },
  {
    value: 1,
    name: 'SMAType2',
    displayName: 'SMAType2',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {
      drugs: [
        0
      ],
      therapeuticAreas: [
        0
      ]
    }
  },
  {
    value: 2,
    name: 'SMAType3',
    displayName: 'SMAType3',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {
      drugs: [
        0
      ],
      therapeuticAreas: [
        0
      ]
    }
  },
  {
    value: 3,
    name: 'SMAType4',
    displayName: 'SMAType4',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {
      drugs: [
        0
      ],
      therapeuticAreas: [
        0
      ]
    }
  },
  {
    value: 4,
    name: 'NeuromyelitisOptica',
    displayName: 'NeuromyelitisOptica',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {
      drugs: [
        1
      ],
      therapeuticAreas: [
        0
      ]
    }
  },
  {
    value: 5,
    name: 'LungCancer',
    displayName: 'LungCancer',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {
      drugs: [
        2
      ],
      therapeuticAreas: [
        1
      ]
    }
  },
  {
    value: 6,
    name: 'ThyroidCancer',
    displayName: 'ThyroidCancer',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {
      drugs: [
        2
      ],
      therapeuticAreas: [
        1
      ]
    }
  },
  {
    value: 7,
    name: 'Agnostic',
    displayName: 'Agnostic',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {
      drugs: [
        2
      ],
      therapeuticAreas: [
        1
      ]
    }
  },
  {
    value: 8,
    name: 'HemophiliaA',
    displayName: 'HemophiliaA',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 900,
    data: {
      drugs: [
        3
      ],
      therapeuticAreas: [
        2
      ]
    }
  }
];

export default RoleFieldDiseaseTypes;