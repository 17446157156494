import { Tooltip } from 'antd';
import ColumnBase from './_ColumnBase';

export default function ContentUserColumns() {
  let columns = [
    {
      locale: 'ContentName',
      name: 'parentContent.title',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={row.parentContent.title}>
          {row.parentContent.title}
        </Tooltip>
      ),
    },
    {
      locale: 'TotalPoint',
      name: 'totalPoint',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'Period',
      name: 'period',
      type: 'number',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
  ];

  return ColumnBase({ columns });
}
