import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { Button, Drawer, Element, Extra, Section, Table } from '../../components/globals';
import {
  DeliveryFlowStepNotificationColumns,
  DeliveryFlowStepNotificationInputs,
  DeliveryFlowStepNotificationStatusType,
  TextlocalSmsTemplateTypes,
} from '../../constants';
import {
  deliveryFlowStepNotificationCreate,
  deliveryFlowStepNotificationList,
  deliveryFlowStepNotificationMultipleDeleteRestore,
  deliveryFlowStepNotificationUpdate,
  deliveryFlowStepNotificationUpdateActivity,
} from '../../services/api';
import { notifySuccess } from '../../utilies/notification';
import { updateResponse } from '../../utilies/stateManagement';

export default function DeliveryFlowStepNotification({ deliveryFlowStep, country }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [response, setResponse] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [notificationGroup, setNotificationGroup] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [notificationType, setNotificationType] = useState(null);
  const [smsTemplate, setSmsTemplate] = useState(null);

  const [pageState, setPageState] = useState({ list: true, form: true, delete: true });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    isDeleted: false,
    isActive: undefined,
    orderBy: ['row|asc'],
    country: country,
    deliveryFlowStepId: deliveryFlowStep.id,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    await deliveryFlowStepNotificationList(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }
    });
  };

  // Events
  const onGroupChange = ({ value }) => {
    setNotificationGroup(value);
  };

  const onStatusChange = ({ value }) => {
    setNotificationStatus(value);
  };

  const onTypeChange = ({ value }) => {
    setNotificationType(value);

    if (notificationStatus === DeliveryFlowStepNotificationStatusType.Special) {
      formRef.setFieldsValue({ value: null });
    }
  };

  const onSmsTemplateChange = ({ value }) => {
    setSmsTemplate(TextlocalSmsTemplateTypes.find((x) => x.value === value));
  };

  const onCreateClick = () => {
    formRef.resetFields();
    setPanelVisibility(true);
    setNotificationStatus(null);
    setNotificationType(null);
    setNotificationGroup(null);
    setSmsTemplate(null);
  };

  const onUpdateClick = (row) => {
    formRef.setFieldsValue(row);
    setPanelVisibility(true);
    setNotificationStatus(row.status);
    setNotificationType(row.type);
    setNotificationGroup(row.group);
    setSmsTemplate(row.smsTemplateId);
  };

  const onActivityChange = async (val, row) => {
    setResponse(updateResponse(response, 'isActive', val, true, row));

    await deliveryFlowStepNotificationUpdateActivity({ id: row.id, isActive: val, country, deliveryFlowStepId: deliveryFlowStep.id }, (status, res) => {
      if (!status) val = !val;

      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
      }
      setResponse(updateResponse(response, 'isActive', val, false, row));
    });
  };

  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, country, deliveryFlowStepId: deliveryFlowStep.id };

    let request = model.id === undefined ? deliveryFlowStepNotificationCreate : deliveryFlowStepNotificationUpdate;
    await request(model, (status, res) => {
      if (status) {
        notifySuccess(t(model.id === undefined ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
        setPanelVisibility(false);
        list();
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  const onDeleteClick = async () => {
    setPageState((x) => ({ ...x, list: false }));

    await deliveryFlowStepNotificationMultipleDeleteRestore({ ids: selectedRowKeys, country, deliveryFlowStepId: deliveryFlowStep.id }, (status, res) => {
      if (status) {
        list();
      }
      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Render
  return (
    <Container>
      <Description>{t('DeliveryFlowStepNotificationDescription')}</Description>

      <Section
        headerLeft={[
          <Button key="add" ready={pageState.form} onClick={onCreateClick} icon={<FaPlus />} templates={['sub-text-title', 'with-icon']}>
            {t('AddNew')}
          </Button>,
        ]}
        headerRight={[
          <Extra
            pageState={pageState}
            filter={filter}
            setFilter={setFilter}
            key="filter"
            activityFilter={true}
            selectedRowKeys={selectedRowKeys}
            onFilterChanged={() => setSelectedRowKeys([])}
            onDeleteClick={onDeleteClick}
          />,
        ]}
        templates={['role-fields']}
      >
        <Table
          ready={pageState.list}
          response={response}
          filter={filter}
          setFilter={setFilter}
          selectedRowKeys={selectedRowKeys}
          onRowSelectChange={(values) => setSelectedRowKeys(values)}
          columns={DeliveryFlowStepNotificationColumns({ country, onUpdateClick, onActivityChange })}
        />
        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)} templates={['fields']}>
          <Element
            ready={pageState.form}
            formRef={formRef}
            onFinish={onFinish}
            inputs={DeliveryFlowStepNotificationInputs({
              country,
              onGroupChange,
              group: notificationGroup,
              onStatusChange,
              status: notificationStatus,
              onTypeChange,
              type: notificationType,
              onSmsTemplateChange,
            })}
            columnSize={24}
            submit={t('Confirm')}
          />
        </Drawer>
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const Description = styled.div`
  margin-bottom: 24px;
`;
