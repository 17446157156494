import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DrugTypes, TreatmentRemindStatusTypes } from '../../../../constants';

export default function DefaultInputs({ drugs, onDrugChange, onDurationChange }) {
  const { t } = useTranslation();

  return [
    {
      type: 'select',
      name: 'drug',
      label: t('Drug'),
      placeholder: t('SelectDrug'),
      rules: [{ required: true, message: t('DrugEmpty') }],
      data: (drugs ?? DrugTypes).map((x) => ({ ...x, text: t(x.displayName) })),
      onChange: onDrugChange,
    },
    {
      type: 'select',
      name: 'remindStatus',
      label: t('RemindStatus'),
      placeholder: t('SelectRemindStatus'),
      rules: [{ required: true, message: t('RemindStatusEmpty') }],
      data: TreatmentRemindStatusTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: 0,
    },
    {
      type: 'date',
      name: 'startDate',
      label: t('TreatmentStartDate'),
      placeholder: t('SelectTreatmentStartDate'),
      rules: [{ required: true, message: t('TreatmentStartDateEmpty') }],
      disabledDate: (current) => current && current < moment().add(-1, 'days').endOf('day'),
    },
    {
      type: 'number',
      name: 'duration',
      label: t('TreatmentDurationInMonths'),
      placeholder: t('TreatmentDurationInMonthsEmpty'),
      rules: [{ required: true, message: t('TreatmentDurationInMonthsEmpty') }],
      onChange: onDurationChange,
    },
  ];
}
