import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill } from 'react-icons/ri';
import { Button } from '../../components/globals';
import { LanguageTypes, LocalizationType, LocalizationTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function LocalizationColumns({ isAllCountry, onUpdateClick }) {
  const { t } = useTranslation();

  let columns = [
    {
      locale: 'LocalizationKey',
      name: 'key',
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      locale: 'LocalizationType',
      name: 'type',
      type: 'string',
      render: (value, row) => {
        let type = LocalizationTypes.find((x) => x.value === value);

        return type ? (
          <Tooltip placement="topLeft" title={t(type.displayName)}>
            {t(type.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    ...LanguageTypes.map((x) => ({
      locale: t(x.displayName),
      name: `lang-${x.value}`,
      type: 'string',
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    })),
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onUpdateClick && {
      locale: 'Update',
      align: 'center',
      width: 120,
      render: (v, row) =>
        (isAllCountry || row.type === LocalizationType.Country) && (
          <Button key="update" onClick={() => onUpdateClick(row)} templates={['table']}>
            <RiEdit2Fill />
          </Button>
        ),
    },
  ];

  return ColumnBase({ columns });
}
