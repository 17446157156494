import { Steps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPaperPlane } from 'react-icons/fa';
import { RiEdit2Fill, RiMedicineBottleFill } from 'react-icons/ri';
import { TiFlowChildren } from 'react-icons/ti';
import styled from 'styled-components';
import DeliveryDetail from './DeliveryDetail';
import DeliveryDrug from './DeliveryDrug';
import DeliveryFlow from './DeliveryFlow';
import DeliveryRedirect from './DeliveryRedirect';
const { Step } = Steps;

export default function DeliveryCreateProcess({ response, current, onStepClick, onDetailFinish, onSelectDrug, onSelectFlow, onSelectRedirect }) {
  // Definitions
  const { t } = useTranslation();

  // Components
  const GetCurrentSection = () => {
    switch (current) {
      case 0: // Drug
        return <DeliveryDrug drugs={response?.drugs} onSelect={onSelectDrug} />;
      case 1: // Flow
        return <DeliveryFlow flows={response?.flows} onSelect={onSelectFlow} />;
      case 2: // Detail
        return <DeliveryDetail response={response} onFinish={onDetailFinish} />;
      case 3: // Redirect
        let redirects = response.flow.steps.filter((x) => x.parentId === null);
        return <DeliveryRedirect redirects={redirects} onSelect={onSelectRedirect} />;
      default: // Nothing
        return <>{t('NotImplemented')}</>;
    }
  };

  // Render
  return (
    <Container>
      <StepList current={current}>
        <StepItem
          key="drug"
          title={response?.drug ? t(response.drug.displayName) : t('DeliveryStepDrug')}
          icon={<RiMedicineBottleFill />}
          onClick={() => onStepClick(0)}
        />
        <StepItem key="flow" title={response?.flow ? t(response.flow.nameKey) : t('DeliveryStepFlow')} icon={<TiFlowChildren />} onClick={() => onStepClick(1)} />
        <StepItem key="detail" title={t('DeliveryStepDetail')} icon={<RiEdit2Fill />} onClick={() => onStepClick(2)} />
        <StepItem key="redirect" title={t('DeliveryStepRedirect')} icon={<FaPaperPlane />} onClick={() => onStepClick(3)} />
      </StepList>

      <GetCurrentSection />
    </Container>
  );
}

const Container = styled.div`
  .ant-steps {
    .ant-steps-item {
      cursor: wait;
      &:not(.ant-steps-item-wait) {
        cursor: pointer;
        .ant-steps-icon {
          color: ${(x) => x.theme.colors.mediumSpringGreen};
        }
      }
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
      background-color: ${(x) => x.theme.colors.mediumSpringGreen};
    }
  }
`;

const StepList = styled(Steps)`
  margin-bottom: 24px;
`;

const StepItem = styled(Step)`
  .ant-steps-icon {
    font-size: 20px !important;
  }
  .ant-steps-item-title {
    font-size: 14px !important;
  }
`;
