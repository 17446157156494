const DrugTypes = [
  {
    value: 0,
    name: 'Evrysdi',
    displayName: 'Evrysdi',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Enspryng',
    displayName: 'Enspryng',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'Pralsetinib',
    displayName: 'Pralsetinib',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'Hemlibra',
    displayName: 'Hemlibra',
    group: null,
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  }
];

export default DrugTypes;