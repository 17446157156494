import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';
import { CountryTypes, LanguageType, LanguageTypes, UserRoleTypes } from '../../constants';

export default function UserInputs({ userRole, country }) {
  const { t } = useTranslation();

  return [
    {
      type: 'hidden',
      name: 'id',
    },
    {
      type: 'select',
      name: 'country',
      label: t('Country'),
      placeholder: t('SelectCountry'),
      rules: [{ required: true, message: t('CountryEmpty') }],
      data: CountryTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: parseInt(country),
      disabled: true,
    },
    {
      type: 'select',
      name: 'userRole',
      label: t('UserRole'),
      placeholder: t('SelectUserRole'),
      rules: [{ required: true, message: t('RoleEmpty') }],
      data: UserRoleTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: parseInt(userRole),
      disabled: true,
    },
    {
      type: 'select',
      name: 'cityId',
      label: t('Cities'),
      placeholder: t('SelectCity'),
      rules: [{ required: true, message: t('CityEmpty') }],
      data: [],
    },
    {
      type: 'select',
      name: 'language',
      label: t('LanguageType'),
      placeholder: t('SelectLanguageType'),
      rules: [{ required: true, message: t('LanguageTypeEmpty') }],
      data: LanguageTypes.map((x) => ({ ...x, text: t(x.displayName) })),
      initialValue: LanguageType.English,
    },
    {
      type: 'input',
      name: 'firstName',
      label: t('FirstName'),
      placeholder: t('FirstNameEmpty'),
      rules: [
        { required: true, message: t('FirstNameEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    {
      type: 'input',
      name: 'middleName',
      label: t('MiddleName'),
      placeholder: t('MiddleNameEmpty'),
      rules: [
        { required: false, message: t('MiddleNameEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    {
      type: 'input',
      name: 'lastName',
      label: t('LastName'),
      placeholder: t('LastNameEmpty'),
      rules: [
        { required: true, message: t('LastNameEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
    {
      type: 'input',
      name: 'email',
      label: t('Email'),
      placeholder: t('EmailEmpty'),
      rules: [
        { required: true, message: t('EmailEmpty') },
        {
          type: 'email',
          min: 3,
          max: 500,
          message: t('EmailInvalid'),
        },
      ],
    },
    {
      type: 'phone',
      name: 'phone',
      label: t('Phone'),
      placeholder: t('PhoneEmpty'),
      rules: [
        { required: true, message: t('PhoneEmpty') },
        { min: 3, max: 50, message: format(t('LengthValid'), 3, 50) },
      ],
    },
  ];
}
