import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaCheckDouble, FaUserTimes } from 'react-icons/fa';
import { ImList2 } from 'react-icons/im';
import { RiMedicineBottleFill } from 'react-icons/ri';
import { Button, PopupConfirm, Switch } from '../../components/globals';
import { CountryTypes, UserRoleTypes } from '../../constants';
import ColumnBase from './_ColumnBase';

export default function RoleColumns({
  isAllCountry,
  onActivityChange,
  onDeliveryDrugUpdateClick,
  onDrugUpdateClick,
  onFieldUpdateClick,
  onActionUpdateClick,
  onLogoutUserClick,
}) {
  const { t } = useTranslation();

  let columns = [
    isAllCountry && {
      locale: 'Country',
      name: 'country',
      type: 'string',
      render: (value, row) => {
        let country = CountryTypes.find((x) => x.value === value);

        return country ? (
          <Tooltip placement="topLeft" title={t(country.displayName)}>
            {t(country.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    {
      locale: 'UserRole',
      name: 'userRole',
      type: 'string',
      render: (value, row) => {
        let userRole = UserRoleTypes.find((x) => x.value === value);

        return userRole ? (
          <Tooltip placement="topLeft" title={t(userRole.displayName)}>
            {t(userRole.displayName)}
          </Tooltip>
        ) : (
          'Unknown'
        );
      },
    },
    onActivityChange && {
      locale: 'Activity',
      name: 'isActive',
      render: (value, row) => <Switch key="isActive" checked={value} loading={row.loading} onChange={(val) => onActivityChange(val, row)} />,
    },
    {
      locale: 'ModificationTime',
      name: 'modificationTime',
      type: 'datetime',
      sortable: true,
      render: (value, row) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    onDrugUpdateClick && {
      locale: 'Drugs',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="drug-update" onClick={() => onDrugUpdateClick(row)} templates={['table']}>
          <RiMedicineBottleFill />
        </Button>
      ),
    },
    onDeliveryDrugUpdateClick && {
      locale: 'DeliveryDrugs',
      align: 'center',
      width: 150,
      render: (v, row) => (
        <Button key="drug-update" onClick={() => onDeliveryDrugUpdateClick(row)} templates={['table']}>
          <RiMedicineBottleFill />
        </Button>
      ),
    },
    onFieldUpdateClick && {
      locale: 'Fields',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="field-update" onClick={() => onFieldUpdateClick(row)} templates={['table']}>
          <ImList2 />
        </Button>
      ),
    },
    onActionUpdateClick && {
      locale: 'Actions',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <Button key="drug-update" onClick={() => onActionUpdateClick(row)} templates={['table']}>
          <FaCheckDouble />
        </Button>
      ),
    },
    onLogoutUserClick && {
      locale: 'Logout',
      align: 'center',
      width: 120,
      render: (v, row) => (
        <PopupConfirm
          key="logout"
          title={t('RoleUsersLoginAgain')}
          onConfirm={() => onLogoutUserClick(row)}
          okText={t('Confirm')}
          cancelText={t('Cancel')}
          placement="bottomRight"
        >
          <Button key="logout" templates={['table']}>
            <FaUserTimes />
          </Button>
        </PopupConfirm>
      ),
    },
  ];

  return ColumnBase({ columns });
}
